/* ==========================================================================
#
# ////////////////////////////// FORMS /////////////////////////////////
#
========================================================================== */

// GLOBAL
$form-spacing: rem-calc(16) !default;


/* ==========================================================================
# //////////////////////////////// TEXT /////////////////////////////////
========================================================================== */
// VARIABLES
$input-color: $black !default;
$input-placeholder-color: $gainsboro !default;
$input-font-family: inherit !default;
$input-font-size: rem-calc(18) !default;
$input-background: $white !default;
$input-background-focus: $white !default;
$input-background-disabled: $vapor !default;
$input-border: 1px solid $silver !default;
$input-border-focus: 1px solid $secondary-color !default;
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;
$input-shadow-focus: 0 0 5px $gainsboro !default;

$input-cursor-disabled: not-allowed !default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out !default;
$input-number-spinners: true !default;
$input-radius: $global-radius !default;

@mixin form-element {
  $height: ($input-font-size + ($form-spacing * 2) - rem-calc(1));

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: $height;
  padding: $form-spacing / 2;
  border: $input-border;
  margin: 0 0 $form-spacing;

  font-family: $input-font-family;
  font-size: $input-font-size;
  color: $input-color;
  background-color: $input-background;
  box-shadow: $input-shadow;
  border-radius: $input-radius;

  @if has-value($input-transition) {
    transition: $input-transition;
  }

  // Focus state
  &:focus {
    border: $input-border-focus;
    background-color: $input-background-focus;
    outline: none;
    box-shadow: $input-shadow-focus;

    @if has-value($input-transition) {
      transition: $input-transition;
    }
  }
}


// Text inputs
#{text-inputs()},
textarea {
@include form-element;
	-webkit-appearance: none;
	-moz-appearance: none;
}

// Text areas
textarea {
	max-width: 100%;

	&[rows] {
		height: auto;
	}
}

input,
textarea {
// Placeholder text
	&::placeholder {
	  color: $input-placeholder-color;
	}

	// Disabled/readonly state
	&:disabled,
	&[readonly] {
	  background-color: $input-background-disabled;
	  cursor: $input-cursor-disabled;
	}
}

// Reset styles on button-like inputs
[type='submit'],
[type='button'] {
	border-radius: $button-radius;
	-webkit-appearance: none;
	-moz-appearance: none;
}

// Reset Normalize setting content-box to search elements
// scss-lint:disable QualifyingElement
input[type='search'] {
	box-sizing: border-box;
}

// Number input styles
[type='number'] {
	@if not $input-number-spinners {
	  -moz-appearance: textfield;

	  	[type='number']::-webkit-inner-spin-button,
	  	[type='number']::-webkit-outer-spin-button {
	    	-webkit-appearance: none;
	    	margin: 0;
	  	}
	}
}


/* ==========================================================================
# //////////////////////////////// CHECKBOX /////////////////////////////////
========================================================================== */
[type='file'],
[type='checkbox'],
[type='radio'] {
	margin: 0 0 $form-spacing;
}

// Styles for input/label siblings
[type='checkbox'] + label,
[type='radio'] + label {
    display: inline-block;
    margin-left: $form-spacing * 0.5;
    margin-right: $form-spacing;
    margin-bottom: 0;
    vertical-align: baseline;

    &[for] {
      	cursor: pointer;
    }
}

// Styles for inputs inside labels
label > [type='checkbox'],
label > [type='radio'] {
    margin-right: $form-spacing * 0.5;
}

// Normalize file input width
[type='file'] {
    width: 100%;
}


/* ==========================================================================
# //////////////////////////////// LABEL  /////////////////////////////////
========================================================================== */
// VARIABLES
$form-label-color: $tuatara !default;
$form-label-font-size: rem-calc(12) !default;
$form-label-font-weight: 700 !default;
$form-label-line-height: 1.8 !default;

@mixin form-label {
  display: block;
  margin: 0;
  font-size: $form-label-font-size;
  font-weight: $form-label-font-weight;
  line-height: $form-label-line-height;
  color: $form-label-color;
}

@mixin form-label-middle {
  $input-border-width: get-border-value($input-border, width);
  margin: 0 0 $form-spacing;
  padding: ($form-spacing / 2 + rem-calc($input-border-width)) 0;
}

label {
	@include form-label;

	&.middle {
	  @include form-label-middle;
	}
}


/* ==========================================================================
# //////////////////////////////// HELP TEXT  /////////////////////////////////
========================================================================== */
// VARIABLES
$helptext-color: $black !default;
$helptext-font-size: rem-calc(13) !default;
$helptext-font-style: italic !default;


.help-text {
	$margin-top: ($form-spacing * 0.5) * -1;
	margin-top: $margin-top;
	font-size: $helptext-font-size;
	font-style: $helptext-font-style;
	color: $helptext-color;
}


/* ==========================================================================
# //////////////////////////////// INPUT GROUP  /////////////////////////////////
========================================================================== */
// VARIABLES
$input-prefix-color: $black !default;
$input-prefix-background: $vapor !default;
$input-prefix-border: 1px solid $gainsboro !default;
$input-prefix-padding: 1rem !default;


$height: ($input-font-size + $form-spacing * 1.5);

.input-group {
    display: table;
    width: 100%;
    margin-bottom: $form-spacing;

    > :first-child {
      border-radius: $global-radius 0 0 $global-radius;
    }

    > :last-child {
      > * {
        border-radius: 0 $global-radius $global-radius 0;
      }
    }
}

%input-group-child {
    margin: 0;
    white-space: nowrap;
    display: table-cell;
    vertical-align: middle;
}	

.input-group-label {
    @extend %input-group-child;
    text-align: center;
    padding: 0 $input-prefix-padding;
    background: $input-prefix-background;
    color: $input-prefix-color;
    border: $input-prefix-border;
    white-space: nowrap;
    width: 1%;
    height: 100%;
  

    @if has-value($input-prefix-border) {
      	&:first-child {
        	border-right: 0;
      	}

      	&:last-child {
        	border-left: 0;
      	}
    }
}

.input-group-field {
    @extend %input-group-child;
    border-radius: 0;
    height: $height;
}

.input-group-button {
    @extend %input-group-child;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  	height: 100%;
  	width: 1%;

    a,
    input,
    button {
      	margin: 0;
    }
}

// Specificity bump needed to prevent override by buttons
// scss-lint:disable QualifyingSelector
.input-group .input-group-button {
    display: table-cell;
}



/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
// VARIABLES
$fieldset-border: 1px solid $gainsboro !default;
$fieldset-padding: rem-calc(20) !default;
$fieldset-margin: rem-calc(18 0) !default;
$legend-padding: rem-calc(0 3) !default;

@mixin fieldset {
  border: $fieldset-border;
  padding: $fieldset-padding;
  margin: $fieldset-margin;

  legend {
    // Covers up the fieldset's border to create artificial padding
    background: $body-background;
    padding: $legend-padding;
    margin: 0;
    margin-left: rem-calc(-3);
  }
}


fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

legend {
    margin-bottom: $form-spacing * 0.5;
    max-width: 100%;
}

.fieldset {
    @include fieldset;
}


/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
// VARIABLES
$select-background: $white !default;
$select-triangle-color: $iron !default;
$select-radius: $global-radius !default;

@mixin form-select {
	$height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));

	height: $height;
	padding: ($form-spacing / 2);
	border: $input-border;
	margin: 0 0 $form-spacing;
	font-size: $input-font-size;
	font-family: $input-font-family;
	line-height: normal;
	color: $input-color;
	background-color: $select-background;
	border-radius: $select-radius;
	-webkit-appearance: none;
	-moz-appearance: none;

  	@if $select-triangle-color != transparent {
	    @include background-triangle($select-triangle-color);
	    background-size: 9px 6px;
	    background-position: right (-$form-spacing) center;
	    background-origin: content-box;
	    background-repeat: no-repeat;
	    padding-right: ($form-spacing * 1.5);
  	}

  	// Disabled state
  	&:disabled {
    	background-color: $input-background-disabled;
	    cursor: $input-cursor-disabled;
  	}

  	// Hide the dropdown arrow shown in newer IE versions
  	&::-ms-expand {
    	display: none;
  	}

  	&[multiple] {
    	height: auto;
    	background-image: none;
  	}
}


select {
	@include form-select;
}



/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
// VARIABLES
$slider-height: 0.5rem !default;
$slider-background: $vapor !default;
$slider-fill-background: $gainsboro !default;
$slider-handle-height: 1.4rem !default;
$slider-handle-width: 1.4rem !default;
$slider-handle-background: $primary-color !default;
$slider-opacity-disabled: 0.25 !default;
$slider-radius: $global-radius !default;


// scss-lint:disable QualifyingElement
input[type="range"] {
    $margin: ($slider-handle-height - $slider-height) / 2;

    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;
    margin-top: $margin;
    margin-bottom: $margin;
    border: 0;
    line-height: 1;

    @if has-value($slider-radius) {
      	border-radius: $slider-radius;
    }

    &:focus {
      	outline: 0;
    }

    &[disabled] {
      	opacity: $slider-opacity-disabled;
    }

    // Chrome/Safari
    &::-webkit-slider-runnable-track {
      	height: $slider-height;
      	background: $slider-background;
    }

    &::-webkit-slider-handle {
      	-webkit-appearance: none;
      	background: $slider-handle-background;
      	width: $slider-handle-width;
  		height: $slider-handle-height;
      	margin-top: -$margin;

      	@if has-value($slider-radius) {
        	border-radius: $slider-radius;
      	}
    }

    // Firefox
    &::-moz-range-track {
		-moz-appearance: none;
		height: $slider-height;
		background: $slider-background;
    }

    &::-moz-range-thumb {
		-moz-appearance: none;
		background: $slider-handle-background;
		width: $slider-handle-width;
		height: $slider-handle-height;
		margin-top: -$margin;

      	@if has-value($slider-radius) {
        	border-radius: $slider-radius;
      	}
    }

    // Internet Explorer
    &::-ms-track {
		height: $slider-height;
		background: $slider-background;
		color: transparent;
		border: 0;
		overflow: visible;
		border-top: $margin solid $body-background;
		border-bottom: $margin solid $body-background;
    }

    &::-ms-thumb {
		background: $slider-handle-background;
		width: $slider-handle-width;
		height: $slider-handle-height;
		border: 0;

      	@if has-value($slider-radius) {
        	border-radius: $slider-radius;
      	}
    }

    &::-ms-fill-lower {
      	background: $slider-fill-background;
    }

    &::-ms-fill-upper {
      	background: $slider-background;
    }

    @at-root {
      	output {
        	line-height: $slider-handle-height;
        	vertical-align: middle;
        	margin-left: 0.5em;
      	}
    }
}

/* ==========================================================================
# //////////////////////////////// PROGRESS  /////////////////////////////////
========================================================================== */
// VARIABLES
$progress-height: 1rem !default;
$progress-background: $gainsboro !default;
$progress-margin-bottom: $global-margin !default;
$progress-meter-background: $primary-color !default;
$progress-radius: $global-radius !default;


progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    height: $progress-height;
    margin-bottom: $progress-margin-bottom;

    @if hasvalue($progress-radius) {
      	border-radius: $progress-radius;
    }

    // For Firefox
    background: $progress-background;
    border: 0;

    &::-webkit-progress-bar {
      	background: $progress-background;

      	@if hasvalue($progress-radius) {
        	border-radius: $progress-radius;
      	}
    }

    &::-webkit-progress-value {
      	background: $progress-meter-background;

      	@if hasvalue($progress-radius) {
        	border-radius: $progress-radius;
      	}
    }

    &::-moz-progress-bar {
      	background: $progress-meter-background;

      	@if hasvalue($progress-radius) {
        	border-radius: $progress-radius;
      	}
    }

    @each $name, $color in $foundation-palette {
      	&.#{$name} {
	        // Internet Explorer sets the fill with color
	        color: $color;

	        &::-webkit-progress-value {
	          	background: $color;
	        }

        	&::-moz-progress-bar {
          		background: $color;
        	}
      	}
    }
}


/* ==========================================================================
# //////////////////////////////// METER  /////////////////////////////////
========================================================================== */
// VARIABLES
$meter-height: 1rem !default;
$meter-radius: $global-radius !default;
$meter-background: $gainsboro !default;
$meter-fill-good: $success-color !default;
$meter-fill-medium: $warning-color !default;
$meter-fill-bad: $alert-color !default;


meter {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    width: 100%;
    height: $meter-height;
    margin-bottom: 1rem;

    @if has-value($meter-radius) {
      	border-radius: $meter-radius;
    }

    // For Firefox
    background: $meter-background;
    border: 0;

    // Chrome/Safari
    &::-webkit-meter-bar {
      	background: $meter-background;

      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &::-webkit-meter-inner-element {
      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &::-webkit-meter-optimum-value {
      	background: $meter-fill-good;

      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &::-webkit-meter-suboptimum-value {
      	background: $meter-fill-medium;

      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &::-webkit-meter-even-less-good-value {
      	background: $meter-fill-bad;

      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &::-moz-meter-bar {
      	background: $primary-color;

      	@if has-value($meter-radius) {
        	border-radius: $meter-radius;
      	}
    }

    &:-moz-meter-optimum::-moz-meter-bar {
      	background: $meter-fill-good;
    }

    &:-moz-meter-sub-optimum::-moz-meter-bar {
      	background: $meter-fill-medium;
    }

    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
      	background: $meter-fill-bad;
    }
}


/* ==========================================================================
# //////////////////////////////// ERROR /////////////////////////////////
========================================================================== */
// VARIABLES
$abide-inputs: true !default;
$abide-labels: true !default;
$input-background-invalid: map-get($foundation-palette, alert) !default;
$form-label-color-invalid: map-get($foundation-palette, alert) !default;
$input-error-color: map-get($foundation-palette, alert) !default;
$input-error-font-size: rem-calc(12) !default;
$input-error-font-weight: $global-weight-bold !default;

@mixin form-input-error(
  	$background: $input-background-invalid,
  	$background-alpha: 0.1
) {
  	&:not(:focus) {
    	background-color: rgba($background, $background-alpha);
    	border-color: $background;
  	}
}

/// Adds error styles to a form element, using the values in the settings file.
@mixin form-error {
	display: none;
	margin-top: $form-spacing * -0.5;
	margin-bottom: $form-spacing;
	font-size: $input-error-font-size;
	font-weight: $input-error-font-weight;
	color: $input-error-color;
}

@if $abide-inputs {
    // Error class for invalid inputs
    .is-invalid-input {
      	@include form-input-error;
    }
}

@if $abide-labels {
    // Error class for labels of invalid outputs
    .is-invalid-label {
      	color: $form-label-color-invalid;
    }
}

// Form error element
.form-error {
    @include form-error;

    &.is-visible {
      	display: block;
    }
} 