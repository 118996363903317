// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group dropdown-menu
////

$dropdownmenu-arrows: true !default;
$dropdownmenu-arrow-size: 6px !default;
$dropdownmenu-arrow-color: $link-color !default;
$dropdownmenu-min-width: 200px !default;
$dropdownmenu-background: $white !default;
$dropdownmenu-border: 1px solid $global-border-color !default;
$dropdownmenu-border-width: nth($dropdownmenu-border, 1);


@mixin left-right-arrows {
	> a::after {
		right: 14px;
		margin-top: -3px;
	}

	&.opens-left > a::after {
		right: auto;
		left: 5px;
		@include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, left);
	}

	&.opens-right > a::after {
		@include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, right);
	}
}

@mixin dropdown-menu-direction($dir: horizontal) {
	@if $dir == horizontal {
		> li.opens-left {
			> .is-dropdown-submenu {
				top: 100%;
				right: 0;
				left: auto;
			}
		}

		> li.opens-right {
			> .is-dropdown-submenu {
				top: 100%;
				right: auto;
				left: 0;
			}
		}

		@if $dropdownmenu-arrows {
			> li.is-dropdown-submenu-parent > a {
				position: relative;
				padding-right: 1.5rem;
			}

			> li.is-dropdown-submenu-parent > a::after {
				@include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, down);
				right: 5px;
				margin-top: -1 * ($dropdownmenu-arrow-size / 2);
			}
		}
	}
	@else if $dir == vertical {
		> li {
			.is-dropdown-submenu {
				top: 0;
			}

			&.opens-left {
				> .is-dropdown-submenu {
					right: 100%;
					left: auto;
					top: 0;
				}
			}

			&.opens-right {
				> .is-dropdown-submenu {
					right: auto;
					left: 100%;
				}
			}

			@if $dropdownmenu-arrows {
				@include left-right-arrows;
			}
		}
	}
	@else {
		@warn 'The direction used for dropdown-menu-direction() must be horizontal or vertical.';
	}
}


	.dropdown.menu {
		@include dropdown-menu-direction(horizontal);

		a {
			@include disable-mouse-outline;
			padding: $global-menu-padding;
		}

		// Active state
		.is-active > a {
			background: transparent;
			color: $primary-color;
		}

		.no-js & ul {
			display: none;
		}

		&.vertical {
			@include dropdown-menu-direction(vertical);
		}

		@each $size in $breakpoint-classes {
			@if $size != $-zf-zero-breakpoint {
				@include breakpoint($size) {
					&.#{$size}-horizontal {
						@include dropdown-menu-direction(horizontal);
					}

					&.#{$size}-vertical {
						@include dropdown-menu-direction(vertical);
					}
				}
			}
		}

		&.align-right {
			.is-dropdown-submenu.first-sub {
				top: 100%;
				right: 0;
				left: auto;
			}
		}
	}

	.is-dropdown-menu.vertical {
		width: 100px;

		&.align-right {
			float: right;
		}
	}

	.is-dropdown-submenu-parent {
		position: relative;

		a::after {
			position: absolute;
			top: 50%;
			right: 5px;
			margin-top:  -1 * $dropdownmenu-arrow-size;
		}

		&.opens-inner > .is-dropdown-submenu {
			top: 100%;
			left: auto;
		}

		&.opens-left > .is-dropdown-submenu {
			right: 100%;
			left: auto;
		}
	}

	.is-dropdown-submenu {
		position: absolute;
		top: 0;
		left: 100%;
		z-index: 1;
		display: none;
		min-width: $dropdownmenu-min-width;
		background: $dropdownmenu-background;
		border: $dropdownmenu-border;

		.dropdown & a {
			padding: $global-menu-padding;
		}

		.is-dropdown-submenu-parent {
			@if $dropdownmenu-arrows {
				@include left-right-arrows;
			}
		}

		@if (type-of($dropdownmenu-border-width) == 'number') {
			.is-dropdown-submenu {
				margin-top: (-$dropdownmenu-border-width);
			}
		}

		> li {
			width: 100%;
		}

		// [TODO] Cut back specificity
		// scss-lint:disable SelectorDepth
		//&:not(.js-dropdown-nohover) > .is-dropdown-submenu-parent:hover > &, // why is this line needed? Opening is handled by JS and this causes some ugly flickering when the sub is re-positioned automatically...
		&.js-dropdown-active {
			display: block;
		}
	}

