/* ==========================================================================
#
# //////////////////////////////// INLINE LIST //////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  INLINE LIST
	========================================================================== */

// We use this mixin to create inline lists
@mixin inline-list {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;

	> li {
		display: block;
		float: left;
		list-style: none;
		margin: 0 $global-margin 0 0;
		> * { display: block; }
	}
}

.inline-list {
	@include inline-list();
}
	
