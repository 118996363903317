/* ==========================================================================
#
# ////////////////////////// TYPOGRAPHY HELPERS /////////////////////////////
#
========================================================================== */

/*-------------------------------------------
		Table of Contents:

		1. Foundation Typography Classes
		2. Foundation Text Alignment
		3. Font Style Helpers
		4. Link Style Helpers

-------------------------------------------*/

/* ==========================================================================
	1.  FOUNDATION TYPOGRAPHY CLASSES
	========================================================================== */	

	/// Default font size for lead paragraphs.
	$lead-font-size: $global-font-size * 1.25 !default;
	$lead-lineheight: 1.6 !default;

	$subheader-lineheight: 1.4 !default;
	$subheader-color: $tuatara !default;
	$subheader-font-weight: $global-weight-normal !default;
	$subheader-margin-top: 0.2rem !default;
	$subheader-margin-bottom: 0.5rem !default;

	$stat-font-size: 2.5rem !default;


	.subheader {
		margin-top: $subheader-margin-top;
		margin-bottom: $subheader-margin-bottom;
		font-weight: $subheader-font-weight;
		line-height: $subheader-lineheight;
		color: $subheader-color;
	}

	.lead {
		font-size: $lead-font-size;
		line-height: $lead-lineheight;
	}

	.stat {
		font-size: $stat-font-size;
		line-height: 1;

		p + & {
			margin-top: -1rem;
		}
	}

	.no-bullet {
		margin-left: 0;
		list-style: none;
	}

/* ==========================================================================
	2.  FOUNDATION TEXT ALIGNMENT
	========================================================================== */	

	@each $size in $breakpoint-classes {
		@include breakpoint($size) {
			@each $align in (left, right, center, justify) {
				@if $size != $-zf-zero-breakpoint {
					.#{$size}-text-#{$align} {
						text-align: $align;
					}
				}
				@else {
					.text-#{$align} {
						text-align: $align;
					}
				}
			}
		}
	}

/* ==========================================================================
	3.  FONT STYLE HELPERS
	========================================================================== */	

	// Styles 
	.bold { font-weight: bold; }
	.italic { font-style: italic !important; }
	.no-italic { font-style: normal !important; }
	.underline { text-decoration:  underline !important; }

	// Font Size
	.text-xxsmall { font-size: 0.8em !important; }
	.text-xsmall { font-size: 0.8em !important; }
	.text-small { font-size: 0.9em !important; }
	.text-regular { font-size: 1em !important; }
	.text-large { font-size: 1.15em !important; }
	.text-xlarge { font-size: 1.25em !important; }
	.text-xxlarge { font-size: 2em !important; }

	// Font Weights - Warning: if font doesn't have specific weight value, it will round to closest value
	.font-normal { font-weight: $global-weight-normal; }
	.font-bold { font-weight: $global-weight-bold; }
	.font-300 { font-weight: 300; }
	.font-400 { font-weight: 400; }
	.font-500 { font-weight: 500; }
	.font-600 { font-weight: 600; }
	.font-700 { font-weight: 700; }
	.font-800 { font-weight: 800; }
	.font-900 { font-weight: 900; }

	// Font Type
	.font-sans-serif { font-family: $sans-serif; }
	.font-sans-serif-condensed { font-family: $sans-serif-condensed; }
	.font-serif { font-family: $serif; }

	// Font Transform
	.font-uppercase,
	.uppercase { 
		text-transform: uppercase;
	}
	.font-standard,
	.sentence-case { 
		text-transform: normal; 
	}
	
	// Line Height
	.line-height-xsm, .line-height-xsmall { line-height: 1; }
	.line-height-sm, .line-height-small { line-height: 1.2; }
	.line-height-med, .line-height-medium { line-height: 1.4; }
	.line-height-lrg, .line-height-large { line-height: 1.6; }

	//vertical align inline icons
	.icon { 
		display: inline-block;
		vertical-align: middle;
		line-height: 1;
	}

/* ==========================================================================
	4.  LINK STYLE HELPERS
	========================================================================== */	
	
	.underline {
		text-decoration: underline;
	}

	.underline-hover {
		&:hover, &:focus {
			text-decoration: underline;
		}
	}

