// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group close-button
////

$closebutton-position: right top !default;
$closebutton-offset-horizontal: 1rem !default;
$closebutton-offset-vertical: 0.5rem !default;
$closebutton-size: 2em !default;
$closebutton-lineheight: 1 !default;
$closebutton-color: $dark-gray !default;
$closebutton-color-hover: $black !default;

/// Adds styles for a close button, using the styles in the settings variables.
@mixin close-button {
	$x: nth($closebutton-position, 1);
	$y: nth($closebutton-position, 2);

	@include disable-mouse-outline;
	position: absolute;
	color: $closebutton-color;
	#{$x}: $closebutton-offset-horizontal;
	#{$y}: $closebutton-offset-vertical;
	font-size: $closebutton-size;
	line-height: $closebutton-lineheight;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $closebutton-color-hover;
	}
}


.close-button {
	@include close-button;
}

