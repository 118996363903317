/* ==========================================================================
#
# ///////////////////////////// MENU HAMBURGER //////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  VARIABLES & MIXINS
	========================================================================== */
	$button-size: 2rem;
	$transition: 1s; // increase this to see the transformations in slow-motion

	@mixin line {
		display: inline-block;
		width: $button-size;
		height: $button-size / 7;
		background: $white;
		border-radius: $button-size / 14;
		transition: $transition;
	}

/* ==========================================================================
	!  BASE STYLES
	========================================================================== */
	.lines-button {
		display: block;
		padding: 0 0.75rem;
		margin-top: -0.25rem;
		transition: 0.3s;
		cursor: pointer;
		user-select: none;
		border-radius: $button-size / 7;

		&:hover {
			opacity: 1;
		}

		&:active {
			transition: 0;
			background: rgba(0, 0, 0, 0.1);
		}
	}

	.lines {
		@include line;
		position: relative;

		&:before, &:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: ($button-size / 14) center;
		}

		&:before {
			top: $button-size / 4;
		}

		&:after {
			top: -$button-size / 4;
		}
	}

	.lines-button:hover {
		opacity: 1;

		.lines {
			&:before {
				top: $button-size / 3.5;
			}

			&:after {
				top: -$button-size / 3.5;
			}
		}
	}

	.lines-button .lines {
		transition: background 0.3s 0.6s ease;

		&:before, &:after {
			transform-origin: 50% 50%;
			transition: top 0.3s 0.6s ease, transform 0.3s ease;
		}
	}

	.lines-button.close .lines {
		transition: background 0.3s 0s ease;
		background: transparent;

		&:before, &:after {
			transition: top 0.3s ease, transform 0.3s 0.5s ease;
			top: 0;
			width: $button-size;
			background: $light-gray;
		}

		&:before {
			transform: rotate3d(0, 0, 1, 45deg);
		}

		&:after {
			transform: rotate3d(0, 0, 1, -45deg);
		}
	}