/* ==========================================================================
#
# //////////////////////////// LAYOUT HELPERS ///////////////////////////////
#
========================================================================== */

/*-------------------------------------------
		Table of Contents:

		1. HTML Fade
		2. Floats and Clearfix
		3. Visibility
		4. Display Class Helpers
		5. Spacers, Margins, and Padding
		6. Text Columns
		7. Foundation Grid Helpers
		8. Media

-------------------------------------------*/


/* ==========================================================================
	1.  HTML FADE
	========================================================================== */
	// Flash of Unstyled Content FIX!!!
	html.js .body-content-wrapper{visibility:hidden;opacity:0;transition: opacity 250ms ease-in-out;
		&.initialized{visibility:visible;opacity:1;transition: opacity 250ms ease-in-out;}
	}

	::selection {background:$primary-color;color:$white;}


/* ==========================================================================
	1.  FLOATS & CLEARFIX
	========================================================================== */

	.float-left, .left {
		float: left !important;
	}

	.float-right, .right {
		float: right !important;
	}

	.float-center, .center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	
	//requires parent container to have position relative
	.vertical-center {
		@include vertical-center;
	}

	.clearfix {
		@include clearfix;
	}



/* ==========================================================================
	3.  VISIBILITY
	========================================================================== */
	// Additional helpers in base/_visibility

	// Basic hiding classes
	.hide, .hidden, .display-none {
		display: none !important;
	}

	.invisible {
		visibility: hidden;
	}


/* ==========================================================================
	4.  DISPLAY CLASS HELPERS
	========================================================================== */	

	.display-inline-block, .inline-block {
		display: inline-block;
	}

	.display-block, .block {
		display: block;
	}

	.display-inline, .inline {
		display: inline;
	}

	.overflow-hidden {
		overflow: hidden;
	}

	.position-relative, .relative {
		position: relative;
	}

/* ==========================================================================
	5.  SPACERS | MARGINS | 
	========================================================================== */	
	/* -----------------------------------------------------------------------------

		Add padding or margins to elements. Can use media queries in class names (but no -only classes like medium-only).
			
		SPACINGTYPE-DIRECTIONCLASS-SPACINGSIZE
		BREAKPOINTCLASS-SPACINGTYPE-DIRECTIONCLASS-SPACINGSIZE

		BREAKPOINTCLASS: 	medium, large (to add xlarge, you must change array in $breakpoint-classes in foundatin/util/_breakpoint.scss)
		SPACINGTYPE: 			padding, margin
		DIRECTIONCLASS:		(blank), top, bottom, left, right, h, v
		SPACINGSIZE: 			none, tiny, small, medium, large, xlarge
		
		Example:
			padding-left-medium: 			adds padding-left:2.5rem;
			large-padding-left-medium	adds padding-left:2.5rem; on large screens and above only

		Special shorthand:
			margin-small: 					adds margin:1.25rem; to ALL sides
			margin-h-small:				adds margin:1.25rem; to TOP and BOTTOM
			margin-v-small: 				adds margin:1.25rem; to LEFT and RIGHT
	------------------------------------------------------------------------------ */
	
	//adjust values to change gutter spacing for each size (tiny to xlarge)
	$spacing: (
		none: 0,
		tiny: $global-margin/2,
		small: $global-margin*1.25,
		medium: $global-margin*2.5,
		large: $global-margin*3.75,
		xlarge: $global-margin*5
	);

	//
	$direction-classes: (
		all,
		top,
		bottom,
		left,
		right,
		h,
		v
	);

	$spacing-type: padding margin;

	@mixin spacing(
		$space, 
		$direction, 
		$size-name, 
		$size-value, 
		$breakpoint-size: null) {
		
		//shorthand. example: padding-medium adds medium spacing to top, bottom, left, and right
		@if ($direction == all) {
				.#{$breakpoint-size}#{$space}-#{$size-name}{
					#{$space}: $size-value !important;
				}
		}
		
		//shorthand. example: padding-h-medium adds medium spacing on left and right
		@else if ($direction == h) {
			.#{$breakpoint-size}#{$space}-h-#{$size-name}{
				#{$space}-left: $size-value !important;
				#{$space}-right: $size-value !important;
			}
		}
		
		//shorthand. example: padding-v-medium adds medium spacing on top and bottom
		@else if ($direction == v) {
			.#{$breakpoint-size}#{$space}-v-#{$size-name}{
				#{$space}-top: $size-value !important;
				#{$space}-bottom: $size-value !important;
			}
		}
		
		@else {
			.#{$breakpoint-size}#{$space}-#{$direction}-#{$size-name} {
				#{$space}-#{$direction}: $size-value !important;
			}
		}	
	}

	//create class names
	@each $space in $spacing-type {
		@each $breakpoint in $breakpoint-classes {
			@include breakpoint($breakpoint) {
				@each $direction in $direction-classes {
					@if $breakpoint != $-zf-zero-breakpoint {
						@each $size, $value in $spacing {
							@include spacing($space, $direction, $size, $value, $breakpoint-size: $breakpoint + "-");
						}
					}
					@else {
						@each $size, $value in $spacing {
							@include spacing($space, $direction, $size, $value);
						}
					}
				}
			}
		}
	}
	
	//create spacer classnames (repeats margin-v-SIZE)
	@each $size, $value in $spacing {
		.spacer-#{$size} {
			@extend .margin-v-#{$size};
			@include clearfix;
			display: block;
			width: 100%;
		}
	}

	//additional helper name classes
	.no-margin, .margin-collapse {
		margin: 0 !important;
	}

	.no-padding, .padding-collapse {
		padding: 0 !important;
	}

/* ==========================================================================
	7.  TEXT COLUMNS
	========================================================================== */	
	/* -----------------------------------------------------------------------------

		Examples
			.text-columns-2				creates 2 text columns on all breakpoints
			.medium-text-columns-2		creates 2 text columns only on medium and up breakpoints

	------------------------------------------------------------------------------- */
	
	//increase number if you need smaller/larger text column classes
	$max-text-column-count: 4;

	@each $size in $breakpoint-classes {
		@include breakpoint($size) {
			@for $i from 1 through $max-text-column-count {
				@if $size != $-zf-zero-breakpoint {
					.#{$size}-text-column-#{$i}, .#{$size}-text-columns-#{$i} {
						column-count: $i;
						column-gap: $global-margin;
					}
				} 
				@else {
					.text-column-#{$i}, .text-columns-#{$i} {
						column-count: $i;
						column-gap: $global-margin;
					}
				}
			}
		}
	}

/* ==========================================================================
	8.  HIDING BRs
	========================================================================== */	
	/* -----------------------------------------------------------------------------

		Hide/show line breaks in text using media queries. Useful for control over wrapping titles.
		To use, add classes to parent container.
		
		Example: single line header on small and medium screens, add breakpoint on large and above screens

			<div class="small-br-hide large-br-show">
				<h1>This is a headline <br>with a line break</h1>
			</div>

	------------------------------------------------------------------------------- */

	@each $breakpoint in $breakpoint-classes {
		@include breakpoint($breakpoint) {
			.#{$breakpoint}-br-hide {
				br {
					display: none;
				}
			}
			.#{$breakpoint}-br-show {
				br {
					display: block;
				}
			}
		}
	}


/* ==========================================================================
	9.  FOUNDATION GRID HELPERS
	========================================================================== */
	//Additional helpers in base/_grid

	//increase max-width of row to extend grid
	.row {
		//&.width75 { max-width: ($row-width * 0.75); }
		//&.width80 { max-width: ($row-width * 0.8); }
		//&.width90 { max-width: ($row-width* 0.9); }
		&.width110 { max-width: ($grid-row-width * 1.1); }
		&.full { max-width: 100%; }
	}

	// if columns is empty, it collapses. add this hack to preserve layout
	.no-collapse-columns {
		.column, .columns {
			min-height: 1px;
		}
	}

/* ==========================================================================
	10.  MEDIA
	========================================================================== */	

	//make element a circle (works if element is exact square)
	.circle { 
		border-radius: 100% !important;
		img { 
			border-radius: 100% !important;
		}
	}

	//rounded
	.rounded {
		@if $global-radius != 0{
			border-radius: $global-radius;
			img {
				border-radius: $global-radius;
			}
		}
		@else {
			border-radius: 8px;
			img {
				border-radius: 8px;
			}
		}
	}

	// add border
	.bordered {
		border: 1px solid $global-border-color;
	}


/* ==========================================================================
	11.  Panels
	========================================================================== */	

	.panel{padding:1rem 2rem;background:$white;border:solid 1px $global-border-color;margin-bottom:2rem;box-shadow:1px 1px 5px rgba($black, 0.25);border-radius:$global-radius;}