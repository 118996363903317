/* ==========================================================================
#
# /////////////////////////////// HEADER ///////////////////////////////////
#
========================================================================== */

/*-------------------------------------------
		Table of Contents:

		1. 

-------------------------------------------*/

/* ==========================================================================
	1.  Header Main
	========================================================================== */
	.header-main{background:#031d56;height:100px;padding: 0.5rem 1rem;
		img{width:150px;margin:0 auto;display:table;}
		
	}
