/* ==========================================================================
#
# ////////////////////////////// TABDROPS /////////////////////////////////
#
========================================================================== */

/*!
 * Tabdrops --- Using Bootstrap v2.3.0
 * 
 * Modified by DH 05162016
 * Copyright 2012 Stefan Petre
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */


/* ==========================================================================
	!  TAB MENU && TAB CONTENT
	========================================================================== */

.tab-menu {position:relative;margin-bottom:20px;margin-left:0;list-style:none;*zoom: 1;border-bottom: 1px solid #ddd;

  	> li > a {display:block;padding:8px 12px 8px 12px;margin-right:2px;line-height:20px;color:$tuatara;
      	&:hover, &:focus {text-decoration: none;border-bottom:solid 4px $base;}
    }

    > li {float:left;margin-bottom:-1px;
		&.active > a {cursor:default;border-bottom:solid 4px $primary-color;font-weight:bold;
	      	&:hover, &:focus {cursor:default;border-bottom:solid 4px $primary-color;}
	    }
    }

	&:before {display:table;line-height:0;content:"";}
	&:after {display: table;line-height:0;content: "";clear:both;}
}


.tab-content {overflow:auto;padding:1em;
  > {
    .tab-pane {display:none;}
    .active {display:block;}
  }
}


/* ==========================================================================
	!  TAB BASE && DROPDOWN 
	========================================================================== */

// Dropdown menu is using Foundation 5.5.2 dropdown JS....

.tabdrops {*zoom: 1;

    &:before {display:table;line-height:0;content:"";}
    &:after {display: table;line-height: 0;content: "";clear:both;}

  
    // Dropdown Position
    .dropdown {position:relative;float:right !important;}


    // Dropdown Toggle
    .tabdrop-toggle {*margin-bottom: -3px;
        &:active {outline: 0;}
 
    }
    
  
    // Controls the Icon Used for dropdown menu.
    .tabdrop-icon{display:block;width:20px;height:20px;border-radius:100%;background:$secondary-color;text-align:center;font-style:normal;line-height:1.2;color:$white;cursor:pointer;
        &:before{font-family:$icon;content: "\e642";position:relative;top:2px;}
    }


    .open .tabdrop-toggle {outline: 0;}


    // Dropdown Menu
    .tabdrop-menu {min-width: 160px;right: 0 !important;left: auto !important;display:none;position:absolute;background:$white;border:solid 1px $gainsboro;list-style:none;padding:0.5em;
        &.open{display:block;}
        li.active > a {background:$smoke;}
    }
}

