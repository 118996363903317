/* ==========================================================================
#
# //////////////////////////// SOCIAL ICONS /////////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  INLINE ICON LIST
	========================================================================== */
	//social media icons (with background color). Uses font-awesome 
	$icon-small: 1.5rem;
	$icon-default: 2rem;
	$icon-large: 2.5rem; //matches button height
	$icon-xlarge: 3.5rem;

	@mixin icon-style() {
		a {
			display: block;
			padding: 0rem !important;
			margin-right: 0.75rem;
			border-radius: $global-radius;
			color: $white !important;
			background: $dark-gray;
			position: relative;
			text-align: center;
			outline: none;
			i {
				width: 100%;
			}
		}
	}

	@mixin icon-size($size:$icon-default) {
		a {
			width: $size;
			height: $size;
			font-size: $size * 0.6125;
			i {
				line-height: $size * 1.2;
			}
		}
	}

	@mixin social-title-style($height:$icon-default) {
		font-size: 0.9em;
		color: $dark-gray;
		padding-right: 1rem;
		line-height: $height;
		display: inline-block;
		vertical-align: bottom;
		@include breakpoint(medium down) {
			display: block; //full width on small
			width: 100%;
		}
	}

	.social-list {
		@include icon-style;
		@include icon-size($size:$icon-default);
		.social-title {
			@include social-title-style($height:$icon-default);
		}

		&.small {
			@include icon-size($size:$icon-small);
			.social-title {
				@include social-title-style($height:$icon-small);
			}
		}

		&.large {
			@include icon-size($size:$icon-large);
			.social-title {
				@include social-title-style($height:$icon-large);
			}
		}

		&.xlarge {
			@include icon-size($size:$icon-xlarge);
			.social-title {
				@include social-title-style($height:$icon-xlarge);
			}
		}

		//remove has-tip styles for tooltip items
		.has-tip {
			border-bottom: none;
			cursor: pointer;
		}
		
	}
