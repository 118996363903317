/* ==========================================================================
#
# //////////////////////////// COLOR HELPERS /////////////////////////////////
#
========================================================================== */


/*-------------------------------------------
		Table of Contents:

		1. Text Colors
		2. Background Colors
		3. Misc
-------------------------------------------*/


/* ==========================================================================
	1.  TEXT COLORS
	========================================================================== */
	// Example: 	.white-color, .white-hover-color

	// Choose which colors to use to create class names
	////Note: Foundation, Gray UI, and Social Media palettes (_global.scss) are merged with Sass map below
	$text-color-palette: (
		//Examples
		//whitesmoke: $whitesmoke,
		//gainsboro: $gainsboro,
		//charcoal: $charcoal,
		link: $link-color,
	) !default;
	
	//Merge colors above with Foundation, Grays, and Social Media
	////Note: any keys in the text-color-palette with the same name as keys in the merged maps will use the value from text-color-palette
	////Example: if you have "white: #fefefe" in the text-color-palette, it will use #fefefe instead of the #fff value from the gray-palette Sass map
	$text-color-palette: map-collect($foundation-palette, $gray-palette, $social-palette, $text-color-palette); 

	//Create classes
	@each $name, $color in $text-color-palette {
		
		.#{$name}-color {
			color: $color !important;
			//if color class is added to a link
			&a:hover, &a:focus {
				color: scale-color($color, $lightness: -14%) !important;
			}
		}

		//if color class is added to a link	
		a.#{$name}-color {
			&:hover, &:focus {
				color: scale-color($color, $lightness: -14%) !important;
			}
		}
		
		//change text color on hover
		.#{$name}-hover-color {
			&:hover, &:focus {
				color: $color !important;
			}
		}
	}
/* ==========================================================================
	2.  BACKGROUND COLORS
	========================================================================== */
	// Example: .white-bg

	// Background Palette Map
	$background-color-palette: (
		//Examples
		//whitesmoke: $whitesmoke,
		//gainsboro: $gainsboro,
		//charcoal: $charcoal,
	) !default;

	//Merge colors above with Foundation, Grays, and Social Media
	$background-color-palette: map-collect($foundation-palette, $gray-palette, $social-palette, $background-color-palette); 
	
	//Create classes
	@each $name, $color in $background-color-palette {
		.#{$name}-bg {
			background-color: $color !important;
		}  
	}

/* ==========================================================================
	3.  MISC
	========================================================================== */
	
	//Create Instagram background CSS gradient
	.instagram-bg, 
	.instagram-bg-hover:hover,
	.instagram-bg-hover:focus {

		//on ios, multiple gradients coming up dark, so here's a simpler one
		background: radial-gradient(circle at 33% 100%, #FED373 0%, #F15245 40%, #D92E7F 60%, #9B36B7 75%, #515ECF) !important;

		//smoother gradient
	    @include breakpoint(large) {
			background:
				radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
				radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
				radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
				radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
				radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
				radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
				radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
				linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%) !important;
	    }
	}








