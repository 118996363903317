/* ==========================================================================
!  Base. These are styles applied to basic HTML tags, including:
	- Paragraphs <p>
	- Bold/italics <b> <strong> <i> <em>
	- Small text <small>
	- Headings <h1>—<h6>
	- Anchors <a>
	- Dividers <hr>
	- Lists <ul> <ol> <dl>
	- Blockquotes <blockquote>
	- Code blocks <code>
	- Abbreviations <abbr>
	- Citations <cite>
	- Keystrokes <kbd>
========================================================================== */

// Header fonts
$header-font-family: $sans-serif-condensed !default;
$header-font-weight: $global-weight-bold !default;
$header-font-style: normal !default;

/// Sizes of headings at various screen sizes. Each key is a breakpoint, and each value is a map of heading sizes.
$header-sizes: (
	small: (
		'h1': 24,
		'h2': 20,
		'h3': 19,
		'h4': 18,
		'h5': 17,
		'h6': 16,
	),
	medium: (
		'h1': 48,
		'h2': 40,
		'h3': 31,
		'h4': 25,
		'h5': 20,
		'h6': 16,
	),
) !default;

/// Color of headers
$header-color: inherit !default;
$header-lineheight: 1.4 !default;
$header-margin-bottom: 0.5rem !default;

// Color of `<small>` elements when placed inside headers
$small-font-size: 80% !default;
$header-small-font-color: $medium-gray !default;

// Paragraph styles
$paragraph-lineheight: 1.6 !default;
$paragraph-margin-bottom: 1rem !default;

// Link styles
$anchor-color: $link-color !default;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%) !default;
$anchor-text-decoration: none !default;
$anchor-text-decoration-hover: none !default;

// Horizontal rule styles <hr>
$hr-width: $global-width !default;
$hr-border: 1px solid $global-border-color !default;
$hr-margin: rem-calc(20) auto !default;

// UL and OL styles
$list-side-margin: 1.25rem !default;

// Blockquote styles
$blockquote-color: $dark-gray !default;
$blockquote-border: 1px solid $global-border-color !default;
$cite-color: $dark-gray !default;


// Typography resets
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

// Paragraphs
p {
	font-size: inherit;
	line-height: $paragraph-lineheight;
	margin-bottom: $paragraph-margin-bottom;
	text-rendering: optimizeLegibility;
}

em, i {
	font-style: italic;
	line-height: inherit;
}

strong, b {
	font-weight: $global-weight-bold;
	line-height: inherit;
}

small {
	font-size: $small-font-size;
	line-height: inherit;
}

// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $header-font-family;
	font-weight: $header-font-weight;
	font-style: $header-font-style;
	color: $header-color;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	margin-top: 0;
	margin-bottom: $header-margin-bottom;
	line-height: $header-lineheight;

	small {
		color: $header-small-font-color;
		line-height: 0;
	}
}

@each $size, $headers in $header-sizes {
	@include breakpoint($size) {
		@each $header, $font-size in $headers {
			#{$header} {
				font-size: rem-calc($font-size);
			}
		}
	}
}

@each $size, $headers in $header-sizes {
	@include breakpoint($size) {
		@each $header, $font-size in $headers {
			.#{$header} {
				font-size: rem-calc($font-size);
			}
		}
	}
}

a {
	color: $anchor-color;
	text-decoration: $anchor-text-decoration;
	line-height: inherit;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $anchor-color-hover;
		@if $anchor-text-decoration-hover != $anchor-text-decoration {
			text-decoration: $anchor-text-decoration-hover;
		}
	}

	img {
		border: 0;
	}
}

hr {
	clear: both;
	max-width: $hr-width;
	height: 0;
	margin: $hr-margin;
	border-right: 0;
	border-top: 0;
	border-bottom: $hr-border;
	border-left: 0;
}


ul, ol, dl {
	line-height: $paragraph-lineheight;
	margin-bottom: $paragraph-margin-bottom;
	list-style-position: outside;
}

li {
	font-size: inherit;
}

ul {
	margin-left: $list-side-margin;
	list-style-type: disc;
}

ol {
	margin-left: $list-side-margin;
}

ul, ol {
	& & {
		margin-left: $list-side-margin;
		margin-bottom: 0;
	}
}

dl {
	margin-bottom: $paragraph-margin-bottom;
	dt {
		margin-bottom: 0.3rem;
		font-weight: bold;
	}
}

blockquote {
	margin: 0 0 $paragraph-margin-bottom;
	padding: rem-calc(9 20 0 19);
	border-left: $blockquote-border;
	&, p {
		line-height: $paragraph-lineheight;
		color: $blockquote-color;
	}
}

cite {
	display: block;
	font-size: rem-calc(13);
	color: $cite-color;

	&:before {
		content: '\2014 \0020'; // — + &nbsp;
	}
}

abbr, abbr[title] {
	border-bottom: 1px dotted $black;
	text-decoration: none;
	cursor: help;
}

code {
	padding: rem-calc(2 5 1);
	border: 1px solid $global-border-color;
	background-color: $light-gray;
	font-family: $monospace;
	font-weight: normal;
	color: $black;	
}

kbd {
	margin: 0;
	padding: rem-calc(2 4 0);
	background-color: $light-gray;
	font-family: $monospace;
	color: $black;
	
	@if has-value($global-radius) {
		border-radius: $global-radius;
	}
}