////
/// @group accordion-menu
////


$accordionmenu-arrows: true !default;
$accordionmenu-arrow-color: $primary-color !default;


@if $accordionmenu-arrows {
  .is-accordion-submenu-parent > a {
    position: relative;

    &::after {
      @include css-triangle(6px, $accordionmenu-arrow-color, down);
      position: absolute;
      top: 50%;
      margin-top: -4px;
      right: 1rem;
    }
  }

  .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
    transform-origin: 50% 50%;
    transform: scaleY(-1);
  }
}

