/* ==========================================================================
#
# ///////////////////////////// CUSTOM FORMS ///////////////////////////////
#
========================================================================== */


/* ==========================================================================
	!  VARIABLES
	========================================================================== */
	$label-bg-color: $light-gray !default;
	$label-font-color: $primary-color !default;

/* ==========================================================================
	!  BASE
	========================================================================== */
	.custom-form {
		
		// RADIO
		input[type=radio], input[type=checkbox] {
			display: none;

			&:disabled + label {
				opacity: 0.4;
				cursor: default;
			}
		}

		label {
			cursor: pointer;
			position: relative;
			margin-right: 15px;
			padding-left: 30px;
			margin-left: 0;
			padding-left: 0;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				left: 0;
				top: 0px;
				background-color: none;
				border: none;
			}
		}

		input[type=radio] {
			& + label {
				margin-bottom: 15px;
				padding-left: 30px;

				&:before {
					border-radius: 100%;
					width: 22px;
					height: 22px;
					top: 1px;
					z-index: 9;
					background-color: $label-bg-color;
					border: 1px solid $global-border-color;
				}
			}

			&:checked + label:before {
				color: $white;
				font-size: 12px;
				font-weight: normal;
				text-align: center;
				line-height: 21px;
				background-color: $primary-color;
				z-index: 10;
			}
		}


		// CHECKBOX
		input[type=checkbox] {
			& + label {
				margin-bottom: 10px;
				padding-left: 30px;

				&:before {
					width: 20px;
					height: 20px;
					top: 2px;
					background-color: $label-bg-color;
					border: 1px solid $global-border-color;
				}
			}

			&:checked + label:before {
				font-family: $icon;
				content: '\e61e'; //@icon
				font-size: 18px;
				font-weight: normal;
				color: $secondary-color;
				text-align: center;
				line-height: 18px;
				background-color: $white;
			}
		}

		input[type=submit].postfix {
			border-radius: 0 3px 3px 0;
		}
	}