/* ==========================================================================
#
# //////////////////////////////// GRID /////////////////////////////////////
#
========================================================================== */

$grid-row-width: $global-width !default;
$grid-column-count: 12 !default;

/// The amount of space between columns at different screen sizes. To use just one size, set the variable to a number instead of a map.
$grid-column-gutter: (
	small: 20px,
	medium: 30px,
) !default;

/// If `true`, the last column in a row will align to the opposite edge of the row.
$grid-column-align-edge: false !default;

/// The highest number of `.x-up` classes available when using the block grid CSS.
$block-grid-max: 12 !default;

// Internal value to store the end column float direction
$-zf-end-float: if($grid-column-align-edge, right, left);

// The last piece to transition the responsive gutter feature
// Remove this in 6.3
$grid-column-responsive-gutter: null !default;
@if $grid-column-responsive-gutter {
	@warn 'Rename $grid-column-responsive-gutter to $grid-column-gutter to remove this warning.';
	$grid-column-gutter: $grid-column-responsive-gutter;
}

// If a single value is passed as a gutter, convert it to a map so the code knows what to do with it
@if type-of($grid-column-gutter) == 'number' {
	$grid-column-gutter: (
		small: $grid-column-gutter,
	);
}

/* ==========================================================================
	!  ROW
	========================================================================== */

	@mixin grid-context(
		$columns,
		$root: false
	) {
		// Store the current column count so it can be re-set later
		$old-grid-column-count: $grid-column-count;
		$grid-column-count: $columns !global;

		@if $root {
			@at-root { @content; }
		}
		@else {
			@content;
		}

		// Restore the old column count
		$grid-column-count: $old-grid-column-count;
	}

	/// Creates a grid row.
	@mixin grid-row(
		$columns: null,
		$behavior: null,
		$width: $grid-row-width,
		$cf: true,
		$gutter: $grid-column-gutter
	) {
		$margin: auto;

		@if index($behavior, nest) != null {
			@include grid-row-nest($gutter);

			@if index($behavior, collapse) != null {
				margin-left: 0;
				margin-right: 0;
			}
		}
		@else {
			max-width: $width;
			margin-left: auto;
			margin-right: auto;
		}

		@if $cf {
			@include clearfix;
		}

		@if $columns != null {
			@include grid-context($columns) {
				@content;
			}
		}
	}

	/// Inverts the margins of a row to nest it inside of a column.
	@mixin grid-row-nest($gutter: $grid-column-gutter) {
		@if type-of($gutter) == 'number' {
			$gutter: ($-zf-zero-breakpoint: $gutter);
		}
		max-width: none;

		@each $breakpoint, $value in $gutter {
			$margin: rem-calc($value) / 2 * -1;

			@include breakpoint($breakpoint) {
				margin-left: $margin;
				margin-right: $margin;
			}
		}
	}


/* ==========================================================================
	!  COLUMNS
========================================================================== */

	/// Calculates the width of a column based on a number of factors.
	@function grid-column($columns) {
		$width: 0%;

		// Parsing percents, decimals, and column counts
		@if type-of($columns) == 'number' {
			@if unit($columns) == '%' {
				$width: $columns;
			}
			@else if $columns < 1 {
				$width: percentage($columns);
			}
			@else {
				$width: percentage($columns / $grid-column-count);
			}
		}

		// Parsing "n of n" expressions
		@else if type-of($columns) == 'list' {
			@if length($columns) != 3 {
				@error 'Wrong syntax for grid-column(). Use the format "n of n".';
			}
			@else {
				$width: percentage(nth($columns, 1) / nth($columns, 3));
			}
		}

		// Anything else is incorrect
		@else {
			@error 'Wrong syntax for grid-column(). Use a number, decimal, percentage, or "n of n".';
		}

		@return $width;
	}

	/// Creates a grid column.
	@mixin grid-column(
		$columns: $grid-column-count,
		$gutter: $grid-column-gutter
	) {
		@include grid-column-size($columns);
		float: left;

		// Gutters
		@if type-of($gutter) == 'map' {
			@each $breakpoint, $value in $gutter {
				$padding: rem-calc($value) / 2;

				@include breakpoint($breakpoint) {
					padding-left: $padding;
					padding-right: $padding;
				}
			}
		}
		@else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
			$padding: rem-calc($gutter) / 2;
			padding-left: $padding;
			padding-right: $padding;
		}

		// Last column alignment
		@if $grid-column-align-edge {
			&:last-child:not(:first-child) {
				float: right;
			}
		}
	}

	/// Creates a grid column row. This is the equivalent of adding `.row` and `.column` to the same element.
	@mixin grid-column-row(
		$gutter: $grid-column-gutter
	) {
		@include grid-row;
		@include grid-column($gutter: $gutter);

		&,
		&:last-child {
			float: none;
		}
	}

	/// Shorthand for `grid-column()`.
	@function grid-col(
		$columns: $grid-column-count
	) {
		@return grid-column($columns);
	}

	/// Shorthand for `grid-column()`.
	@mixin grid-col(
		$columns: $grid-column-count,
		$gutter: $grid-column-gutter
	) {
		@include grid-column($columns, $gutter);
	}

	/// Shorthand for `grid-column-row()`.
	@mixin grid-col-row(
		$gutter: $grid-column-gutter
	) {
		@include grid-column-row($gutter);
	}


/* ==========================================================================
	!  SIZE
========================================================================== */

	/// Set the width of a grid column.
	@mixin grid-column-size(
		$columns: $grid-column-count
	) {
		width: grid-column($columns);
	}

	/// Shorthand for `grid-column-size()`.
	@mixin grid-col-size(
		$columns: $grid-column-count
	) {
		@include grid-column-size($columns);
	}



/* ==========================================================================
	!  POSITION
========================================================================== */

	/// Reposition a column.
	@mixin grid-column-position($position) {
		@if type-of($position) == 'number' {
			$offset: percentage($position / $grid-column-count);

			position: relative;
			left: $offset;
		}
		@else if $position == center {
			float: none;
			margin-left: auto;
			margin-right: auto;
		}
		@else {
			@warn 'Wrong syntax for grid-column-position(). Enter a positive or negative number, or center.';
		}
	}

	/// Reset a position definition.
	@mixin grid-column-unposition {
		position: static;
		margin-left: 0;
		margin-right: 0;
		float: left;
	}

	/// Offsets a column to the right by `$n` columns.
	@mixin grid-column-offset($n) {
		margin-left: grid-column($n);
	}

	/// Disable the default behavior of the last column in a row aligning to the opposite edge.
	@mixin grid-column-end {
		&:last-child:last-child { // This extra specificity is required for the property to be applied
			float: left;
		}
	}

	/// Shorthand for `grid-column-position()`.
	@mixin grid-col-pos($position) {
		@include grid-column-position($position);
	}

	/// Shorthand for `grid-column-unposition()`.
	@mixin grid-col-unpos {
		@include grid-column-unposition;
	}

	/// Shorthand for `grid-column-offset()`.
	@mixin grid-col-off($n) {
		@include grid-column-offset($n);
	}

	/// Shorthand for `grid-column-end()`.
	@mixin grid-col-end {
		@include grid-column-end;
	}


/* ==========================================================================
	!  GUTTER
========================================================================== */

	/// Collapse the gutters on a column by removing the padding. **Note:** only use this mixin within a breakpoint. To collapse a column's gutters 
	@mixin grid-column-collapse {
		padding-left: 0;
		padding-right: 0;
	}

	/// Un-collapse the gutters on a column by re-adding the padding.
	@mixin grid-column-uncollapse($gutter: $grid-column-gutter) {
		$gutter: rem-calc($gutter) / 2;
		padding-left: $gutter;
		padding-right: $gutter;
	}

	/// Shorthand for `grid-column-collapse()`.
	@mixin grid-col-collapse {
		@include grid-column-collapse;
	}

	/// Shorthand for `grid-column-uncollapse()`.
	@mixin grid-col-uncollapse($gutter: $grid-column-gutter) {
		@include grid-column-uncollapse($gutter);
	}


/* ==========================================================================
	!  CLASSES
========================================================================== */

	/// Outputs CSS classes for the grid.
	@mixin foundation-grid(
		$row: 'row',
		$column: 'column',
		$column-row: 'column-row',
		$push: 'push',
		$pull: 'pull',
		$center: 'centered',
		$uncenter: 'uncentered',
		$collapse: 'collapse',
		$uncollapse: 'uncollapse',
		$offset: 'offset',
		$end: 'end',
		$expanded: 'expanded'
	) {
		// Row
		.#{$row} {
			@include grid-row;

			// Collapsing
			&.#{$collapse} {
				> .#{$column} {
					@include grid-col-collapse;
				}
			}

			// Nesting
			& & {
				@include grid-row-nest($grid-column-gutter);

				&.#{$collapse} {
					margin-left: 0;
					margin-right: 0;
				}
			}

			// Expanded (full-width) row
			&.#{$expanded} {
				max-width: none;

				.#{$row} {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		// Column
		.#{$column} {
			@include grid-col;

			@if $grid-column-align-edge {
				&.#{$end} {
					@include grid-col-end;
				}
			}
		}

		// Column row
		// The double .row class is needed to bump up the specificity
		.#{$column}.#{$row}.#{$row} {
			float: none;

			// To properly nest a column row, padding and margin is removed
			.#{$row} & {
				padding-left: 0;
				padding-right: 0;
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include -zf-each-breakpoint {
			@for $i from 1 through $grid-column-count {
				// Column width
				.#{$-zf-size}-#{$i} {
					@include grid-col-size($i);
				}

				// Source ordering
				@if $i < $grid-column-count {
					.#{$-zf-size}-#{$push}-#{$i} {
						@include grid-col-pos($i);
					}

					.#{$-zf-size}-#{$pull}-#{$i} {
						@include grid-col-pos(-$i);
					}
				}

				// Offsets
				$o: $i - 1;

				.#{$-zf-size}-#{$offset}-#{$o} {
					@include grid-col-off($o);
				}
			}

			// Block grid
			@for $i from 1 through $block-grid-max {
				.#{$-zf-size}-up-#{$i} {
					@include grid-layout($i, ".#{$column}");
				}
			}

			// Responsive collapsing
			.#{$-zf-size}-#{$collapse} {
				> .#{$column} { @include grid-col-collapse; }

				.#{$row},
				.#{$expanded}.#{$row} &.#{$row} {
					margin-left: 0;
					margin-right: 0;
				}
			}

			.#{$-zf-size}-#{$uncollapse} {
				$gutter: -zf-get-bp-val($grid-column-gutter, $-zf-size);

				> .#{$column} { @include grid-col-uncollapse($gutter); }
			}

			// Positioning
			.#{$-zf-size}-#{$center} {
				@include grid-col-pos(center);
			}

			// Gutter adjustment
			.#{$-zf-size}-#{$uncenter},
			.#{$-zf-size}-#{$push}-0,
			.#{$-zf-size}-#{$pull}-0 {
				@include grid-col-unpos;
			}
		}

		@if $column == 'column' {
			.columns {
				@extend .column;
			}
		}
	}



/* ==========================================================================
	!  LAYOUT
========================================================================== */

/// Sizes child elements so that `$n` number of items appear on each row.
	@mixin grid-layout(
		$n,
		$selector: '.column'
	) {
		& > #{$selector} {
			width: percentage(1/$n);
			float: left;

			&:nth-of-type(1n) {
				clear: none;
			}

			&:nth-of-type(#{$n}n+1) {
				clear: both;
			}

			&:last-child {
				float: left;
			}
		}
	}

	/// Adds extra CSS to block grid children so the last items in the row center automatically. Apply this to the columns, not the row.
	@mixin grid-layout-center-last($n) {
		@for $i from 1 to $n {
			@if $i == 1 {
				&:nth-child(#{$n}n+1):last-child {
					margin-left: (100 - 100/$n * $i) / 2 * 1%;
				}
			}
			@else {
				&:nth-child(#{$n}n+1):nth-last-child(#{$i}) {
					margin-left: (100 - 100/$n * $i) / 2 * 1%;
				}
			}
		}
	}



/* ==========================================================================
	!  SHOW
========================================================================== */

@include foundation-grid;