/* ==========================================================================
#
# //////////////////////////// SLICK SLIDER /////////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  BASE STYLES
	========================================================================== */
.slick-slider {position:relative;display:block;-moz-box-sizing:border-box;box-sizing:border-box;user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent;}

.slick-list {position:relative;overflow:hidden;display:block;margin:0;padding:0;z-index:10;
    &:focus {outline: none;}
    &.dragging {cursor: pointer;cursor:hand;}
}

.slick-slider .slick-track,
.slick-slider .slick-list {transform:translate3d(0, 0, 0);}

.slick-track {position:relative;left:0;top:0;display:block;

    &:before,
    &:after {content: "";display: table;}
    &:after {clear:both;}

    .slick-loading & {visibility: hidden;}
}
.slick-slide {float:left;height:100%;min-height:1px;
    &:focus{outline:none;}
    [dir="rtl"] & {float: right;}
    img {display: block;}
    &.slick-loading img {display: none;}

    display: none;

    &.dragging img {pointer-events:none;}

    .slick-initialized & {display: block;}

    .slick-loading & {visibility: hidden;}

    .slick-vertical & {display:block;height:auto;border:1px solid transparent;}
}
.slick-arrow.slick-hidden {display: none;}


/* ==========================================================================
	!  GENERAL THEME STYLES
	========================================================================== */

.slick-list {
    .slick-loading & {background: $white url(/lib/img/loader.gif) center center no-repeat;}
}


// Slick Arrows
.slick-prev,
.slick-next {position:absolute;display:block;height:100%;width:25px;line-height:0px;font-size:0px;cursor:pointer;background:transparent;border:none;color:transparent;top:50%;transform:translate(0, -50%);padding:0;outline:none;box-shadow:none;text-shadow:none;z-index:12;
    &:focus{outline:none;background:transparent;}
    &:hover{outline:none;background:transparent;}
    &.slick-disabled{display:none !important;}
}

.slick-prev:before, 
.slick-next:before {font-family:$icon;font-size:24px;line-height:1.7;color:$white;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;position:relative;background:$secondary-color;width:40px;height:40px;border-radius:100%;display:block;}

.slick-prev {
    left: 0;
    &:before {content: "\f053";right:8px;}
}

.slick-next {
    right: 0;
    &:before {content: "\f054";left:-8px;}
}

.slick-slider {margin-bottom: 50px;}


// Slick Dots
.slick-dots {position:absolute;bottom:-60px;list-style: none;display:block;text-align:center;padding:0;width:100%;margin-left:0;
    li {position:relative;display:inline-block;height:20px;width:20px;margin:0 5px;padding:0;cursor:pointer;
        button {border:0;background:transparent;display:block;height:20px;width:20px;outline:none;line-height:0px;font-size:0px;color:transparent;padding:5px;box-shadow:none;cursor:pointer;
            &:hover, &:focus {outline:none;}
            &:before {position:absolute;top:0;left:0;content:"\e608";width:20px;height:20px;font-family:$icon;font-size:18px;line-height:20px;text-align:center;color:$snow;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
        }
        &.slick-active button:before {color: $link-color;text-shadow:2px 2px 0 $gainsboro;}
    }
}


/* ==========================================================================
	!  SPECIFIC THEME STYLES
    ========================================================================== */
    
// General Styling
.slick-slider{
    .container{padding:0 0.5em;}

    &.no-dots{
        .slick-dots{display:none !important;}
    }
    &.no-arrows{
        .slick-arrow{display:none !important;}
    }
}


.arrow-controls{position:relative;transform:translate3d(0, 0, 0);
    .append-arrows{position:relative;width:100px;height:35px;border-radius:15px;overflow:hidden;
        &.center{display:inherit;margin:0 auto;}
    }

    .slick-arrow{top:0;display:inline-block !important;width:48%;background:$link-color;
        &:hover{background:lighten($link-color, 5%);}
        &:before{color:$whitesmoke;font-size:16px;border:none;line-height:33px;
            &:hover{color:$white;}
        }
    }
    .slick-prev {left:0;border-radius:0;transform:skewX(-15deg) scale(1.1);
        &:before {right:-5px;transform:skewX(15deg) scale(0.9);}
    }

    .slick-next {right:0;border-radius:0;transform:skewX(-15deg) scale(1.1);border-left:solid 1px darken($link-color, 5%);
        &:before {left:2px;transform:skewX(15deg) scale(0.9);}
    }
}



// Collage
.collage{
    .img-collage.layout-1{padding-right:115px;padding-top:30px;
        li + li{;z-index:10;margin-left:50%;padding-right:5%;}
        li + li + li{margin-left:70%;top:0;z-index:5;padding-right:0;}
    }
    .img-collage.layout-2{padding-right:115px;
        li + li{;z-index:10;margin-left:60%;}
    }
    .img-collage.layout-3{padding-right:115px;padding-top:30px;
        li + li{margin-left:70%;top:0;z-index:5;padding-right:0;}
    }
}

// Carousel Collage
.carousel.collage{
    // Media Queries
    @media #{$small-only} {margin-bottom:10px;
        .img-collage{padding:0;margin-bottom:0;
            li{display:none;}
            li:first-child{display:block;}
        }
    }
}

// Full Width
.carousel.full-width{
    .container{padding:0;}
    .slick-list{overflow:visible;}
    .slick-slide{position: relative;}
    .slick-prev {left: 20px;}
    .slick-next {right:20px;}


    aside{width:100%;position:absolute;top:0;left:0;border: solid 2px lighten($tuatara, 5%);border-left:none;border-right:none;border-radius:0;height:90%;top:5%;}
    img{position:relative;opacity:0.25;transition:all 500ms ease-in-out;}
    h5{color:$gainsboro;font-style:italic;position:absolute;bottom:0;padding:20px;font-size:1.5em;line-height:1;font-weight:normal;visibility:hidden;}
    blockquote{color:$gainsboro;font-style:italic;position:absolute;bottom:0;padding:20px;font-size:1.5em;line-height:1;font-weight:normal;visibility:hidden;
        &:before{content:none;}
        p, cite{color:$gainsboro;}
    }
    .slick-center{
        aside{border: solid 2px $base;border-radius:7px;z-index:11;height:100%;top:0;transform:scale(0.95);transition:all 500ms ease-in-out;
            &:after{content:'';position:absolute;top:0;left:0;width:100%;height:100%;background-color:transparent;background-image:linear-gradient(to top, rgba($black, 0.5) 0, rgba($black, 0.4) 25%, rgba($black, 0) 50%, rgba($black, 0) 75%);}
            h5{z-index:10;visibility:visible; transition:all 500ms ease-in-out;}
            blockquote{z-index:10;visibility:visible; transition:all 500ms ease-in-out;}
        }
        img{opacity:1;z-index:10;transform:scale(1.05);border-radius:3px;transition:all 500ms ease-in-out;}
    }

    // Media Queries
    @media #{$small-only} {
        .slick-list{overflow:hidden;}
        aside{border:none;}
        .slick-center{
            aside{transform:scale(0.9);}
            img{display:table;margin:0 auto;border-radius:0;transform:scale(1);}
        }
    }
}


// Image Slider w/ Thumbs
.imgslider-main{margin-bottom:1em;}


