// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group pagination
////

$pagination-font-size: rem-calc(14) !default;
$pagination-margin-bottom: $global-margin !default;
$pagination-item-color: $black !default;
$pagination-item-padding: rem-calc(3 10) !default;
$pagination-item-spacing: rem-calc(1) !default;
$pagination-radius: $global-radius !default;
$pagination-item-background-hover: $vapor !default;
$pagination-item-background-current: $primary-color !default;
$pagination-item-color-current: foreground($pagination-item-background-current) !default;
$pagination-item-color-disabled: $gainsboro !default;
$pagination-ellipsis-color: $black !default;
$pagination-mobile-items: false !default;
$pagination-arrows: true !default;

/// Adds styles for a pagination container. Apply this to a `<ul>`.
@mixin pagination-container {
  @include clearfix;
  margin-left: 0;
  margin-bottom: $pagination-margin-bottom;

  // List item
  li {
    font-size: $pagination-font-size;
    margin-right: $pagination-item-spacing;
    border-radius: $pagination-radius;

    @if $pagination-mobile-items {
      display: inline-block;
    }
    @else {
      display: none;

      &:last-child,
      &:first-child {
        display: inline-block;
      }

      @include breakpoint(medium) {
        display: inline-block;
      }
    }
  }

  // Page links
  a,
  button {
    color: $pagination-item-color;
    display: block;
    padding: $pagination-item-padding;
    border-radius: $global-radius;

    &:hover {
      background: $pagination-item-background-hover;
    }
  }
}

/// Adds styles for the current pagination item. Apply this to an `<a>`.
@mixin pagination-item-current {
  padding: $pagination-item-padding;
  background: $pagination-item-background-current;
  color: $pagination-item-color-current;
  cursor: default;
}

/// Adds styles for a disabled pagination item. Apply this to an `<a>`.
@mixin pagination-item-disabled {
  padding: $pagination-item-padding;
  color: $pagination-item-color-disabled;
  cursor: not-allowed;

  &:hover {
    background: transparent;
  }
}

/// Adds styles for an ellipsis for use in a pagination list.
@mixin pagination-ellipsis {
  content: '\2026';
  padding: $pagination-item-padding;
  color: $pagination-ellipsis-color;
}


.pagination {
  @include pagination-container;

  .current {
    @include pagination-item-current;
  }

  .disabled {
    @include pagination-item-disabled;
  }

  .ellipsis::after {
    @include pagination-ellipsis;
  }
}

@if $pagination-arrows {
  .pagination-previous a::before,
  .pagination-previous.disabled::before {
    content: '\00ab';
    display: inline-block;
    margin-right: 0.5rem;
  }

  .pagination-next a::after,
  .pagination-next.disabled::after {
    content: '\00bb';
    display: inline-block;
    margin-left: 0.5rem;
  }
}
