/* ==========================================================================
#
# //////////////////////////////// ICON LIST ////////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  ICON LISTS
	========================================================================== */
	// By default, includes checklist using Font-Awesome. Build additional lists using mixins below

	@mixin icon-list($list-icon-size: 25px) {
		list-style-type: none;
		margin-left: 0;
		li {
			//text-indent: -#{$list-icon-size};
			padding: 0.25em 0 0.25em $list-icon-size;
			position: relative;
			line-height: 1.5;
			&:before {
				font-family: $icon;
				width: $list-icon-size;
				position: absolute;
				top: 0.25em;
				left: 0;
				display: block;
			}

			//experimental...no text-indent on subsequent items.
			*:nth-child(n+2) {
				text-indent: 0;
			}
		}
		@each $name, $color in $foundation-palette {
			&.#{$name} {
				li:before {
					color: $color;
				}
			}
		}
	}


.checklist {
	@include icon-list(25px);
	li:before {
		content: '\e014';
		line-height: 20px;
	}
}

.arrowlist {
	@include icon-list(25px);
	li:before {
		content: '\e008';
		line-height: 1.6;
		top: 0.15em; //text column fix
	}
	// added to prevent arrows from breaking to next column
	&.column-fix {
		li:before {
			top: .15em;
		}
	}
}

.faqlist {
	@include icon-list(45px);
	li:before {
		content: '\e01e';
		font-size: 30px;
		line-height: 20px;
	}
}