// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group dropdown
////


$dropdown-padding: 1rem !default;
$dropdown-border: 1px solid $global-border-color !default;
$dropdown-font-size: 1rem !default;
$dropdown-width: 300px !default;
$dropdown-radius: $global-radius !default;
$dropdown-sizes: ( 
	tiny: 100px,
	small: 200px,
	large: 400px,
) !default;

/// Applies styles for a basic dropdown.
@mixin dropdown-container {
	background-color: $body-background;
	border: $dropdown-border;
	border-radius: $dropdown-radius;
	display: block;
	font-size: $dropdown-font-size;
	padding: $dropdown-padding;
	position: absolute;
	visibility: hidden;
	width: $dropdown-width;
	z-index: 10;
	box-shadow: 1px 1px 3px rgba($black, 0.05);
	
	&.is-open {
		visibility: visible;
	}
}


.dropdown-pane {
	@include dropdown-container;
}

@each $name, $size in $dropdown-sizes {
	.dropdown-pane.#{$name} {
		width: $size;
	}
}
