// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group title-bar
////

$titlebar-background: $black !default;
$titlebar-color: $white !default;
$titlebar-padding: 0.5rem !default;
$titlebar-text-font-weight: bold !default;
$titlebar-icon-color: $white !default;
$titlebar-icon-color-hover: $iron !default;
$titlebar-icon-spacing: 0.25rem !default;


  .title-bar {
    background: $titlebar-background;
    color: $titlebar-color;
    padding: $titlebar-padding;
    @include clearfix;
    

    .menu-icon {
      margin-left: $titlebar-icon-spacing;
      margin-right: $titlebar-icon-spacing;
    }
  }
  .title-bar-left {
    float: left;
  }

  .title-bar-right {
    float: right;
    text-align: right;
  }
  

  .title-bar-title {
    font-weight: $titlebar-text-font-weight;
    vertical-align: middle;
    display: inline-block;
  }

  .menu-icon.dark {
    @include hamburger;
  }

// Menu Icon
.menu-icon {
  @include hamburger($color: $titlebar-icon-color, $color-hover: $titlebar-icon-color-hover);
  }
  
  .menu-icon.dark {
  @include hamburger;
  }

