/* ==========================================================================
#
# //////////////////////////////// GLOBAL ///////////////////////////////////
#
========================================================================== */

/*-------------------------------------------

		Global variables and mixins for SASS/Foundation

		Table of Contents:

		1. Color Variables
		2. Font Variables
		3. Foundation global variables
		4. Mixins

-------------------------------------------*/

/* ==========================================================================
	1. 	COLOR VARIABLES
	========================================================================== */
	
	/* 	Brand Colors — Create unique, meaningful variable names. Resources: https://goo.gl/EsRWcj, https://goo.gl/qpV8Zg
	========================================================================== */
		
	// Base Colors
	$white         : #FFFFFF !default;
	$ghost         : #FAFAFA !default;
	$snow          : #F9F9F9 !default;
	$vapor         : #F6F6F6 !default;
	$whitesmoke    : #F5F5F5 !default; 
	$silver        : #EFEFEF !default; 
	$smoke         : #EEEEEE !default;
	$gainsboro     : #DDDDDD !default; 
	$iron          : #CCCCCC !default;
	$base          : #AAAAAA !default;
	$aluminum      : #999999 !default;
	$jumbo         : #888888 !default;
	$monsoon       : #777777 !default;
	$steel         : #666666 !default;
	$charcoal      : #555555 !default;
	$tuatara       : #454142 !default;
	$oil           : #333333 !default;
	$jet           : #222222 !default;
	$black         : #000000 !default;

	
	/* 	Foundation UI — Colors used for buttons and callouts. There must always be a color called `primary`.
	========================================================================== */
	$foundation-palette: (
		primary: #031d56,
		secondary: #00adff,
		success: #3adb76,
		warning: #ffae00,
		alert: #ff0f0f,
		info: #00adff
	) !default;

	@if not map-has-key($foundation-palette, primary) {
		@error 'In $foundation-palette, you must have a color named "primary".';
	}

	$primary-color: map-get($foundation-palette, primary);
	$secondary-color: map-get($foundation-palette, secondary);
	$success-color: map-get($foundation-palette, success);
	$warning-color: map-get($foundation-palette, warning);
	$alert-color: map-get($foundation-palette, alert);
	$info-color: map-get($foundation-palette, info);

	$link-color: $primary-color;
	

	/* 	Gray UI — Used heavily in components.
	========================================================================== */
	$gray-palette: (
		white: #fff,
		black: #000,
		gray: #767676,
		light-gray: #e6e6e6,
		medium-gray: #cacaca,
		dark-gray: #8a8a8a	
	) !default;

	$white: map-get($gray-palette, white);
	$black: map-get($gray-palette, black);
	$gray: map-get($gray-palette, gray);
	$light-gray: map-get($gray-palette, light-gray);
	$medium-gray: map-get($gray-palette, medium-gray);
	$dark-gray: map-get($gray-palette, dark-gray);
		
	
	/* 	Social Colors
	========================================================================== */
	$social-palette: (
		twitter: #00aced,
		facebook: #3b5998,
		google: #dd4b39,
		youtube: #bb0000,
		linkedin: #007bb6,
		instagram: #517fa4,
		pinterest: #cb2027,
		//foursquare: #0072b1,
		//tumblr: #32506d,
	) !default;

	$twitter: map-get($social-palette, twitter);
	$facebook: map-get($social-palette, facebook);
	$google: map-get($social-palette, google);
	$youtube: map-get($social-palette, youtube);
	$linkedin: map-get($social-palette, linkedin);
	$instagram: map-get($social-palette, instagram);
	$pinterest: map-get($social-palette, pinterest);
	//$foursquare: map-get($social-palette, foursquare);
	//$tumblr: map-get($social-palette, tumblr);


	
/* ==========================================================================
	2. 	FONT VARIABLES
	========================================================================== */

	$serif: Georgia, serif;
	$sans-serif:'Roboto', Arial, sans-serif;
	$sans-serif-condensed: 'Roboto Condensed', Helvetica, Arial, sans-serif;
	$icon: "fontawesome";
	$monospace: Consolas, 'Liberation Mono', Courier, monospace !default;


/* ==========================================================================
	3. 	FOUNDATION GLOBAL VARIABLES
	========================================================================== */

	// Global typography settings
	$body-font-family: $sans-serif !default;
	$body-font-color: $oil !default;
	$global-font-size: 16px;
	$global-lineheight: 1.3 !default;
	$global-weight-normal: normal !default;
	$global-weight-bold: bold !default;

	// Global body settings
	$body-background: $white !default;
	$body-antialiased: true !default;

	//Other global settings
	$global-radius: 3px !default;
	$global-width: rem-calc(1200) !default;
	$global-margin: 1rem !default;
	$global-padding: 1rem !default;
	$global-border-color: $silver;

	$global-menu-padding: 0.7rem 1rem !default;

	//try to remove
	$-zf-menu-icon-imported: false;
	//$-zf-flex-classes-imported: false;


/* ==========================================================================
	4. 	MIXINS
	========================================================================== */

	// Add transitions to elements
	@mixin single-transition($property:all, $speed:300ms, $ease:ease-in-out) {
		-webkit-transition: $property $speed $ease;
		-moz-transition: $property $speed $ease;
		transition: $property $speed $ease;
	}

	// Box Mixin: Easily create a square, rectangle or a circle
	// Example (creates 100px circle): @include box(100px,100px,true); 
	@mixin box(
		$width, 
		$height: $width, 
		$circle: false
	) {
		width: $width;
		height: $height;
		@if $circle {
			border-radius: 50% !important;
		}
	}

	// Merge 3+ Sass maps. Used for combining palettes
	//Example usage: 		$text-color-palette: map-collect($gray-palette, $foundation-palette, $text-color-palette); 
	@function map-collect($maps...) {
		$collection: ();
		@each $map in $maps {
			$collection: map-merge($collection, $map);
		}
		@return $collection;
	}

	// Mixin for Clearfix... Enough Said...
	@mixin clearfix {
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}
	  }

