@charset "UTF-8";
/* !  Dependent files
	 ------------------------------------------------------------------------------------------------------*/
/* ==========================================================================
#
# //////////////////////////////// GLOBAL ///////////////////////////////////
#
========================================================================== */
/*-------------------------------------------

		Global variables and mixins for SASS/Foundation

		Table of Contents:

		1. Color Variables
		2. Font Variables
		3. Foundation global variables
		4. Mixins

-------------------------------------------*/
/* ==========================================================================
	1. 	COLOR VARIABLES
	========================================================================== */
/* 	Brand Colors — Create unique, meaningful variable names. Resources: https://goo.gl/EsRWcj, https://goo.gl/qpV8Zg
	========================================================================== */
/* 	Foundation UI — Colors used for buttons and callouts. There must always be a color called `primary`.
	========================================================================== */
/* 	Gray UI — Used heavily in components.
	========================================================================== */
/* 	Social Colors
	========================================================================== */
/* ==========================================================================
	2. 	FONT VARIABLES
	========================================================================== */
/* ==========================================================================
	3. 	FOUNDATION GLOBAL VARIABLES
	========================================================================== */
/* ==========================================================================
	4. 	MIXINS
	========================================================================== */
/* !  Your Base Styles: Grid, Typography, etc.
	 ------------------------------------------------------------------------------------------------------*/
/* ==========================================================================
#
# ////////////////////////////// NORMALIZE //////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  FOUNDATION 6 NORMALIZE
	========================================================================== */
.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  font-size: 16px;
  box-sizing: border-box;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.3;
  color: #333333;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* ==========================================================================
	!  HTML5 NORMALIZE.CSS 
	========================================================================== */
article,
aside,
footer,
header,
hgroup,
details nav,
section,
menu,
figcaption,
figure,
main {
  display: block; }

figure {
  margin: 1rem 1rem; }

summary {
  display: list-item; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

/* Links
	========================================================================== */
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a a:active,
  a a:hover {
    outline-width: 0; }

/* Text Semantics
	========================================================================== */
pre {
  font-family: monospace, monospace;
  font-size: 1em; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: inherit; }

b, strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded Media
	========================================================================== */
audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  border-style: none;
  display: inline-block;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

/* Forms
	========================================================================== */
button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 100%;
  line-height: 1.3;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0;
  border: 0;
  border-radius: 3px;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

select {
  width: 100%;
  border-radius: 3px; }

input {
  overflow: visible; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

fieldset {
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 3px;
  overflow: auto; }

/* Scripting
	========================================================================== */
canvas {
  display: inline-block; }

template {
  display: none; }

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

/* Hidden
	========================================================================== */
.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

[hidden] {
  display: none; }

/* ==========================================================================
#
# //////////////////////////////// GRID /////////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  ROW
	========================================================================== */
/* ==========================================================================
	!  COLUMNS
========================================================================== */
/* ==========================================================================
	!  SIZE
========================================================================== */
/* ==========================================================================
	!  POSITION
========================================================================== */
/* ==========================================================================
	!  GUTTER
========================================================================== */
/* ==========================================================================
	!  CLASSES
========================================================================== */
/* ==========================================================================
	!  LAYOUT
========================================================================== */
/* ==========================================================================
	!  SHOW
========================================================================== */
.row {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }
  .row:after {
    content: "";
    display: table;
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row .row {
    max-width: none;
    margin-left: -0.625rem;
    margin-right: -0.625rem; }
    @media screen and (min-width: 40em) {
      .row .row {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem; } }
    .row .row.collapse {
      margin-left: 0;
      margin-right: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-left: auto;
      margin-right: auto; }

.column, .columns {
  width: 100%;
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media screen and (min-width: 40em) {
    .column, .columns {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none; }
  .row .column.row.row, .row .row.row.columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  width: 100%;
  float: left; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  width: 50%;
  float: left; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  width: 33.33333%;
  float: left; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  width: 25%;
  float: left; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  width: 20%;
  float: left; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  width: 16.66667%;
  float: left; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  width: 14.28571%;
  float: left; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  width: 12.5%;
  float: left; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-up-9 > .column, .small-up-9 > .columns {
  width: 11.11111%;
  float: left; }
  .small-up-9 > .column:nth-of-type(1n), .small-up-9 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-9 > .column:nth-of-type(9n+1), .small-up-9 > .columns:nth-of-type(9n+1) {
    clear: both; }
  .small-up-9 > .column:last-child, .small-up-9 > .columns:last-child {
    float: left; }

.small-up-10 > .column, .small-up-10 > .columns {
  width: 10%;
  float: left; }
  .small-up-10 > .column:nth-of-type(1n), .small-up-10 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-10 > .column:nth-of-type(10n+1), .small-up-10 > .columns:nth-of-type(10n+1) {
    clear: both; }
  .small-up-10 > .column:last-child, .small-up-10 > .columns:last-child {
    float: left; }

.small-up-11 > .column, .small-up-11 > .columns {
  width: 9.09091%;
  float: left; }
  .small-up-11 > .column:nth-of-type(1n), .small-up-11 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-11 > .column:nth-of-type(11n+1), .small-up-11 > .columns:nth-of-type(11n+1) {
    clear: both; }
  .small-up-11 > .column:last-child, .small-up-11 > .columns:last-child {
    float: left; }

.small-up-12 > .column, .small-up-12 > .columns {
  width: 8.33333%;
  float: left; }
  .small-up-12 > .column:nth-of-type(1n), .small-up-12 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-12 > .column:nth-of-type(12n+1), .small-up-12 > .columns:nth-of-type(12n+1) {
    clear: both; }
  .small-up-12 > .column:last-child, .small-up-12 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-left: 0;
  padding-right: 0; }

.small-collapse .row,
.expanded.row .small-collapse.row {
  margin-left: 0;
  margin-right: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.small-centered {
  float: none;
  margin-left: auto;
  margin-right: auto; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  margin-left: 0;
  margin-right: 0;
  float: left; }

@media screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    width: 100%;
    float: left; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    width: 50%;
    float: left; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    width: 33.33333%;
    float: left; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    width: 25%;
    float: left; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    width: 20%;
    float: left; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    width: 16.66667%;
    float: left; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    width: 14.28571%;
    float: left; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    width: 12.5%;
    float: left; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-up-9 > .column, .medium-up-9 > .columns {
    width: 11.11111%;
    float: left; }
    .medium-up-9 > .column:nth-of-type(1n), .medium-up-9 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-9 > .column:nth-of-type(9n+1), .medium-up-9 > .columns:nth-of-type(9n+1) {
      clear: both; }
    .medium-up-9 > .column:last-child, .medium-up-9 > .columns:last-child {
      float: left; }
  .medium-up-10 > .column, .medium-up-10 > .columns {
    width: 10%;
    float: left; }
    .medium-up-10 > .column:nth-of-type(1n), .medium-up-10 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-10 > .column:nth-of-type(10n+1), .medium-up-10 > .columns:nth-of-type(10n+1) {
      clear: both; }
    .medium-up-10 > .column:last-child, .medium-up-10 > .columns:last-child {
      float: left; }
  .medium-up-11 > .column, .medium-up-11 > .columns {
    width: 9.09091%;
    float: left; }
    .medium-up-11 > .column:nth-of-type(1n), .medium-up-11 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-11 > .column:nth-of-type(11n+1), .medium-up-11 > .columns:nth-of-type(11n+1) {
      clear: both; }
    .medium-up-11 > .column:last-child, .medium-up-11 > .columns:last-child {
      float: left; }
  .medium-up-12 > .column, .medium-up-12 > .columns {
    width: 8.33333%;
    float: left; }
    .medium-up-12 > .column:nth-of-type(1n), .medium-up-12 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-12 > .column:nth-of-type(12n+1), .medium-up-12 > .columns:nth-of-type(12n+1) {
      clear: both; }
    .medium-up-12 > .column:last-child, .medium-up-12 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .medium-collapse .row,
  .expanded.row .medium-collapse.row {
    margin-left: 0;
    margin-right: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .medium-centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left; } }

@media screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    width: 100%;
    float: left; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    width: 50%;
    float: left; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    width: 33.33333%;
    float: left; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    width: 25%;
    float: left; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    width: 20%;
    float: left; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    width: 16.66667%;
    float: left; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    width: 14.28571%;
    float: left; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    width: 12.5%;
    float: left; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-up-9 > .column, .large-up-9 > .columns {
    width: 11.11111%;
    float: left; }
    .large-up-9 > .column:nth-of-type(1n), .large-up-9 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-9 > .column:nth-of-type(9n+1), .large-up-9 > .columns:nth-of-type(9n+1) {
      clear: both; }
    .large-up-9 > .column:last-child, .large-up-9 > .columns:last-child {
      float: left; }
  .large-up-10 > .column, .large-up-10 > .columns {
    width: 10%;
    float: left; }
    .large-up-10 > .column:nth-of-type(1n), .large-up-10 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-10 > .column:nth-of-type(10n+1), .large-up-10 > .columns:nth-of-type(10n+1) {
      clear: both; }
    .large-up-10 > .column:last-child, .large-up-10 > .columns:last-child {
      float: left; }
  .large-up-11 > .column, .large-up-11 > .columns {
    width: 9.09091%;
    float: left; }
    .large-up-11 > .column:nth-of-type(1n), .large-up-11 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-11 > .column:nth-of-type(11n+1), .large-up-11 > .columns:nth-of-type(11n+1) {
      clear: both; }
    .large-up-11 > .column:last-child, .large-up-11 > .columns:last-child {
      float: left; }
  .large-up-12 > .column, .large-up-12 > .columns {
    width: 8.33333%;
    float: left; }
    .large-up-12 > .column:nth-of-type(1n), .large-up-12 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-12 > .column:nth-of-type(12n+1), .large-up-12 > .columns:nth-of-type(12n+1) {
      clear: both; }
    .large-up-12 > .column:last-child, .large-up-12 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .large-collapse .row,
  .expanded.row .large-collapse.row {
    margin-left: 0;
    margin-right: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .large-centered {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left; } }

/* ==========================================================================
!  Base. These are styles applied to basic HTML tags, including:
	- Paragraphs <p>
	- Bold/italics <b> <strong> <i> <em>
	- Small text <small>
	- Headings <h1>—<h6>
	- Anchors <a>
	- Dividers <hr>
	- Lists <ul> <ol> <dl>
	- Blockquotes <blockquote>
	- Code blocks <code>
	- Abbreviations <abbr>
	- Citations <cite>
	- Keystrokes <kbd>
========================================================================== */
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

p {
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-rendering: optimizeLegibility; }

em, i {
  font-style: italic;
  line-height: inherit; }

strong, b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.4; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    color: #cacaca;
    line-height: 0; }

h1 {
  font-size: 1.5rem; }

h2 {
  font-size: 1.25rem; }

h3 {
  font-size: 1.1875rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1.0625rem; }

h6 {
  font-size: 1rem; }

@media screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

.h1 {
  font-size: 1.5rem; }

.h2 {
  font-size: 1.25rem; }

.h3 {
  font-size: 1.1875rem; }

.h4 {
  font-size: 1.125rem; }

.h5 {
  font-size: 1.0625rem; }

.h6 {
  font-size: 1rem; }

@media screen and (min-width: 40em) {
  .h1 {
    font-size: 3rem; }
  .h2 {
    font-size: 2.5rem; }
  .h3 {
    font-size: 1.9375rem; }
  .h4 {
    font-size: 1.5625rem; }
  .h5 {
    font-size: 1.25rem; }
  .h6 {
    font-size: 1rem; } }

a {
  color: #031d56;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer; }
  a:hover, a:focus {
    color: #03194a; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #EFEFEF;
  border-left: 0; }

ul, ol, dl {
  line-height: 1.6;
  margin-bottom: 1rem;
  list-style-position: outside; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #EFEFEF; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: '\2014 \0020'; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  text-decoration: none;
  cursor: help; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #EFEFEF;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000;
  border-radius: 3px; }

/* ==========================================================================
#
# //////////////////////////////// VISIBILITY ///////////////////////////////
#
========================================================================== */
@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

/* !  Foundation 6 Components
	 ------------------------------------------------------------------------------------------------------*/
.accordion {
  list-style-type: none;
  background: #fff;
  margin-left: 0; }

.accordion-item:first-child > :first-child {
  border-radius: 3px 3px 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 3px 3px; }

.accordion-title {
  display: block;
  padding: 1.25rem 1rem;
  line-height: 1;
  font-size: 0.75rem;
  color: #031d56;
  position: relative;
  border: 1px solid #EFEFEF;
  border-bottom: 0; }
  :last-child:not(.is-active) > .accordion-title {
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid #EFEFEF; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    content: '+';
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -0.5rem; }
  .is-active > .accordion-title::before {
    content: '–'; }

.accordion-content {
  padding: 1rem;
  display: none;
  border: 1px solid #EFEFEF;
  border-bottom: 0;
  background-color: #fff;
  color: #333333; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #EFEFEF; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: #031d56 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform-origin: 50% 50%;
  transform: scaleY(-1); }

.breadcrumbs {
  list-style: none;
  margin: 0 0 1rem 0; }
  .breadcrumbs:after {
    content: "";
    display: table;
    clear: both; }
  .breadcrumbs li {
    float: left;
    color: #000;
    font-size: 0.6875rem;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      color: #cacaca;
      content: "/"; }
  .breadcrumbs a {
    color: #031d56; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button {
  display: inline-block;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0.5em 2em;
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
  background-color: #031d56;
  color: #fff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #031949;
    color: #fff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .button.primary {
    background-color: #031d56;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #021745;
      color: #fff; }
  .button.secondary {
    background-color: #00adff;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #008acc;
      color: #fff; }
  .button.success {
    background-color: #3adb76;
    color: #fff; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #fff; }
  .button.warning {
    background-color: #ffae00;
    color: #fff; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #fff; }
  .button.alert {
    background-color: #ff0f0f;
    color: #fff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #d80000;
      color: #fff; }
  .button.info {
    background-color: #00adff;
    color: #fff; }
    .button.info:hover, .button.info:focus {
      background-color: #008acc;
      color: #fff; }
  .button.hollow {
    border: 1px solid #031d56;
    color: #031d56; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #020f2b;
      color: #020f2b; }
    .button.hollow.primary {
      border: 1px solid #031d56;
      color: #031d56; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #020f2b;
        color: #020f2b; }
    .button.hollow.secondary {
      border: 1px solid #00adff;
      color: #00adff; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #005780;
        color: #005780; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #ff0f0f;
      color: #ff0f0f; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #870000;
        color: #870000; }
    .button.hollow.info {
      border: 1px solid #00adff;
      color: #00adff; }
      .button.hollow.info:hover, .button.hollow.info:focus {
        border-color: #005780;
        color: #005780; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
      background-color: #031d56;
      color: #fff; }
  .button.dropdown::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    border-color: #fff transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    position: relative;
    top: 0.4em;
    float: right;
    margin-left: 2em;
    display: inline-block; }
  .button.arrow-only::after {
    margin-left: 0;
    float: none;
    top: -0.1em; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group:after {
    content: "";
    display: table;
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #031d56;
    color: #fff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #021745;
      color: #fff; }
  .button-group.secondary .button {
    background-color: #00adff;
    color: #fff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #008acc;
      color: #fff; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #fff; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #fff; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #fff; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #fff; }
  .button-group.alert .button {
    background-color: #ff0f0f;
    color: #fff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #d80000;
      color: #fff; }
  .button-group.info .button {
    background-color: #00adff;
    color: #fff; }
    .button-group.info .button:hover, .button-group.info .button:focus {
      background-color: #008acc;
      color: #fff; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: relative;
  color: #333333;
  background-color: white; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #c2d4fd; }
  .callout.secondary {
    background-color: #d9f3ff; }
  .callout.success {
    background-color: #e1faea; }
  .callout.warning {
    background-color: #fff3d9; }
  .callout.alert {
    background-color: #ffdbdb; }
  .callout.info {
    background-color: #d9f3ff; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block !important; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: #fff;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent transparent transparent #031d56;
    border-left-style: solid;
    border-right-width: 0;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent #031d56 transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem; }

.dropdown-pane {
  background-color: #fff;
  border: 1px solid #EFEFEF;
  border-radius: 3px;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05); }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: #031d56 transparent transparent;
  border-top-style: solid;
  border-bottom-width: 0;
  right: 5px;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #031d56; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px;
  margin-top: -3px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent #031d56 transparent transparent;
  border-right-style: solid;
  border-left-width: 0; }

.dropdown.menu.vertical > li.opens-right > a::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  border-color: transparent transparent transparent #031d56;
  border-left-style: solid;
  border-right-width: 0; }

@media screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: #031d56 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent #031d56 transparent transparent;
    border-right-style: solid;
    border-left-width: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent transparent transparent #031d56;
    border-left-style: solid;
    border-right-width: 0; } }

@media screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: #031d56 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent #031d56 transparent transparent;
    border-right-style: solid;
    border-left-width: 0; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent transparent transparent #031d56;
    border-left-style: solid;
    border-right-width: 0; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  background: #fff;
  border: 1px solid #EFEFEF; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px;
    margin-top: -3px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent #031d56 transparent transparent;
    border-right-style: solid;
    border-left-width: 0; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    border-color: transparent transparent transparent #031d56;
    border-left-style: solid;
    border-right-width: 0; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.flex-video {
  position: relative;
  height: 0;
  padding-bottom: 75%;
  margin-bottom: 1rem;
  overflow: hidden; }
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .flex-video.widescreen {
    padding-bottom: 56.25%; }
  .flex-video.vimeo {
    padding-top: 0; }

/* ==========================================================================
#
# ////////////////////////////// FORMS /////////////////////////////////
#
========================================================================== */
/* ==========================================================================
# //////////////////////////////// TEXT /////////////////////////////////
========================================================================== */
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 3.0625rem;
  padding: 0.5rem;
  border: 1px solid #EFEFEF;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1.125rem;
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    border: 1px solid #00adff;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 5px #DDDDDD;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #DDDDDD; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #F6F6F6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type='search'] {
  box-sizing: border-box; }

/* ==========================================================================
# //////////////////////////////// CHECKBOX /////////////////////////////////
========================================================================== */
[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

/* ==========================================================================
# //////////////////////////////// LABEL  /////////////////////////////////
========================================================================== */
label {
  display: block;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.8;
  color: #454142; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

/* ==========================================================================
# //////////////////////////////// HELP TEXT  /////////////////////////////////
========================================================================== */
.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

/* ==========================================================================
# //////////////////////////////// INPUT GROUP  /////////////////////////////////
========================================================================== */
.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .input-group > :last-child > * {
    border-radius: 0 3px 3px 0; }

.input-group-label, .input-group-field, .input-group-button {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  text-align: center;
  padding: 0 1rem;
  background: #F6F6F6;
  color: #000;
  border: 1px solid #DDDDDD;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.625rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  height: 100%;
  width: 1%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button {
    margin: 0; }

.input-group .input-group-button {
  display: table-cell; }

/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

legend {
  margin-bottom: 0.5rem;
  max-width: 100%; }

.fieldset {
  border: 1px solid #DDDDDD;
  padding: 1.25rem;
  margin: 1.125rem 0; }
  .fieldset legend {
    background: #fff;
    padding: 0 0.1875rem;
    margin: 0;
    margin-left: -0.1875rem; }

/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
select {
  height: 2.5625rem;
  padding: 0.5rem;
  border: 1px solid #EFEFEF;
  margin: 0 0 1rem;
  font-size: 1.125rem;
  font-family: inherit;
  line-height: normal;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28204, 204, 204%29'></polygon></svg>");
  background-size: 9px 6px;
  background-position: right -1rem center;
  background-origin: content-box;
  background-repeat: no-repeat;
  padding-right: 1.5rem; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNrEllsOhCAMRVszC9IlzU7KCmVHTJsoMWYMUtpyv9BgbuXQB5ZSdgBYYY4ycgBivk8KYFsQMfMiTTBP4o3nUzCKzOabLJbLy2/g31evGkAginR4/ZegKH5qX3bJCscA3t0x3kgO5tQFyhhFf50xRqFLbyMUNJQzgyjGS/wgCpvKqkRBpuWrE4V9d+1E4dPUXqIg107SQOE/2DRQxMwTDygIInVDET9T3lCoj/6j/VCmGjZOl2lKpZ8AAwDQP7zIimDGFQAAAABJRU5ErkJggg=="); } }
  select:disabled {
    background-color: #F6F6F6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

/* ==========================================================================
# //////////////////////////////// FIELDSET  /////////////////////////////////
========================================================================== */
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
  border: 0;
  line-height: 1;
  border-radius: 3px; }
  input[type="range"]:focus {
    outline: 0; }
  input[type="range"][disabled] {
    opacity: 0.25; }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    background: #F6F6F6; }
  input[type="range"]::-webkit-slider-handle {
    -webkit-appearance: none;
    background: #031d56;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    border-radius: 3px; }
  input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: 0.5rem;
    background: #F6F6F6; }
  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    background: #031d56;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    border-radius: 3px; }
  input[type="range"]::-ms-track {
    height: 0.5rem;
    background: #F6F6F6;
    color: transparent;
    border: 0;
    overflow: visible;
    border-top: 0.45rem solid #fff;
    border-bottom: 0.45rem solid #fff; }
  input[type="range"]::-ms-thumb {
    background: #031d56;
    width: 1.4rem;
    height: 1.4rem;
    border: 0;
    border-radius: 3px; }
  input[type="range"]::-ms-fill-lower {
    background: #DDDDDD; }
  input[type="range"]::-ms-fill-upper {
    background: #F6F6F6; }
  output {
    line-height: 1.4rem;
    vertical-align: middle;
    margin-left: 0.5em; }

/* ==========================================================================
# //////////////////////////////// PROGRESS  /////////////////////////////////
========================================================================== */
progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  background: #DDDDDD;
  border: 0; }
  progress::-webkit-progress-bar {
    background: #DDDDDD;
    border-radius: 3px; }
  progress::-webkit-progress-value {
    background: #031d56;
    border-radius: 3px; }
  progress::-moz-progress-bar {
    background: #031d56;
    border-radius: 3px; }
  progress.primary {
    color: #031d56; }
    progress.primary::-webkit-progress-value {
      background: #031d56; }
    progress.primary::-moz-progress-bar {
      background: #031d56; }
  progress.secondary {
    color: #00adff; }
    progress.secondary::-webkit-progress-value {
      background: #00adff; }
    progress.secondary::-moz-progress-bar {
      background: #00adff; }
  progress.success {
    color: #3adb76; }
    progress.success::-webkit-progress-value {
      background: #3adb76; }
    progress.success::-moz-progress-bar {
      background: #3adb76; }
  progress.warning {
    color: #ffae00; }
    progress.warning::-webkit-progress-value {
      background: #ffae00; }
    progress.warning::-moz-progress-bar {
      background: #ffae00; }
  progress.alert {
    color: #ff0f0f; }
    progress.alert::-webkit-progress-value {
      background: #ff0f0f; }
    progress.alert::-moz-progress-bar {
      background: #ff0f0f; }
  progress.info {
    color: #00adff; }
    progress.info::-webkit-progress-value {
      background: #00adff; }
    progress.info::-moz-progress-bar {
      background: #00adff; }

/* ==========================================================================
# //////////////////////////////// METER  /////////////////////////////////
========================================================================== */
meter {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  background: #DDDDDD;
  border: 0; }
  meter::-webkit-meter-bar {
    background: #DDDDDD;
    border-radius: 3px; }
  meter::-webkit-meter-inner-element {
    border-radius: 3px; }
  meter::-webkit-meter-optimum-value {
    background: #3adb76;
    border-radius: 3px; }
  meter::-webkit-meter-suboptimum-value {
    background: #ffae00;
    border-radius: 3px; }
  meter::-webkit-meter-even-less-good-value {
    background: #ff0f0f;
    border-radius: 3px; }
  meter::-moz-meter-bar {
    background: #031d56;
    border-radius: 3px; }
  meter:-moz-meter-optimum::-moz-meter-bar {
    background: #3adb76; }
  meter:-moz-meter-sub-optimum::-moz-meter-bar {
    background: #ffae00; }
  meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: #ff0f0f; }

/* ==========================================================================
# //////////////////////////////// ERROR /////////////////////////////////
========================================================================== */
.is-invalid-input:not(:focus) {
  background-color: rgba(255, 15, 15, 0.1);
  border-color: #ff0f0f; }

.is-invalid-label {
  color: #ff0f0f; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ff0f0f; }
  .form-error.is-visible {
    display: block; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li {
    display: table-cell; }
  .menu.vertical > li {
    display: block; }
  @media screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-vertical > li {
      display: block; } }
  @media screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    line-height: 1;
    display: inline-block;
    margin-right: 1rem; }
  .menu.simple a {
    padding: 0; }
  .menu.align-right:after {
    content: "";
    display: table;
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.expanded {
    width: 100%;
    display: table;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img, .menu.icon-top > li > a i, .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    color: #fff;
    background: #031d56; }

.menu-text {
  font-weight: bold;
  color: inherit;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block; }

.no-js [data-responsive-menu] ul {
  display: none; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination:after {
    content: "";
    display: table;
    clear: both; }
  .pagination li {
    font-size: 0.875rem;
    margin-right: 0.0625rem;
    border-radius: 3px;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    color: #000;
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 3px; }
    .pagination a:hover,
    .pagination button:hover {
      background: #F6F6F6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #031d56;
    color: #fff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #DDDDDD;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    content: '\2026';
    padding: 0.1875rem 0.625rem;
    color: #000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  content: '\00ab';
  display: inline-block;
  margin-right: 0.5rem; }

.pagination-next a::after,
.pagination-next.disabled::after {
  content: '\00bb';
  display: inline-block;
  margin-left: 0.5rem; }

body.is-reveal-open {
  overflow: hidden; }

.reveal-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1005;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  display: none;
  z-index: 1006;
  padding: 1rem;
  border: 1px solid #DDDDDD;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns,
  .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  @media screen and (min-width: 40em) {
    .reveal .reveal {
      left: auto;
      right: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    max-width: none;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      max-width: none;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.sticky-container {
  position: relative; }

.sticky {
  position: absolute;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: absolute;
  left: auto;
  right: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 3px; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f2f2f2;
    background-color: #fff; }
  caption {
    font-weight: bold;
    padding: 0.5rem 0.625rem 0.625rem; }
  thead,
  tfoot {
    background: #f9f9f9;
    color: #333333; }
    thead tr,
    tfoot tr {
      background: transparent; }
    thead th,
    thead td,
    tfoot th,
    tfoot td {
      padding: 0.5rem 0.625rem 0.625rem;
      font-weight: bold;
      text-align: left; }
  tbody tr:nth-child(even) {
    background-color: #f2f2f2; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover tr:hover {
  background-color: #fafafa; }

table.hover tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  list-style-type: none;
  background: #fff;
  border: 1px solid #F6F6F6; }
  .tabs:after {
    content: "";
    display: table;
    clear: both; }

.tabs.vertical > li {
  width: auto;
  float: none;
  display: block; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #031d56; }
  .tabs.primary > li > a {
    color: #fff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #04246a; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    line-height: 1;
    font-size: 0.75rem; }
    .tabs-title > a:hover {
      background: #fff; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #F6F6F6; }

.tabs-content {
  background: #fff;
  transition: all 0.5s ease;
  border: 1px solid #F6F6F6;
  border-top: 0; }

.tabs-content.vertical {
  border: 1px solid #F6F6F6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.has-tip {
  border-bottom: dotted 1px #CCCCCC;
  font-weight: bold;
  position: relative;
  display: inline-block;
  cursor: help; }

.tooltip {
  background-color: #000;
  color: #fff;
  font-size: 80%;
  padding: 0.75rem;
  position: absolute;
  z-index: 10;
  top: calc(100% + 0.6495rem);
  max-width: 10rem !important;
  border-radius: 3px; }
  .tooltip::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    border-color: transparent transparent #000;
    border-bottom-style: solid;
    border-top-width: 0;
    bottom: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    border-color: #000 transparent transparent;
    border-top-style: solid;
    border-bottom-width: 0;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    border-color: transparent transparent transparent #000;
    border-left-style: solid;
    border-right-width: 0;
    bottom: auto;
    left: 100%;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    border-color: transparent #000 transparent transparent;
    border-right-style: solid;
    border-left-width: 0;
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translateY(-50%); }

.title-bar {
  background: #000;
  color: #fff;
  padding: 0.5rem; }
  .title-bar:after {
    content: "";
    display: table;
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  font-weight: bold;
  vertical-align: middle;
  display: inline-block; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 20px;
  height: 16px; }
  .menu-icon.dark::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    top: 0;
    left: 0;
    box-shadow: 0 7px 0 #000, 0 14px 0 #000; }
  .menu-icon.dark:hover::after {
    background: #CCCCCC;
    box-shadow: 0 7px 0 #CCCCCC, 0 14px 0 #CCCCCC; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 20px;
  height: 16px; }
  .menu-icon::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    top: 0;
    left: 0;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff; }
  .menu-icon:hover::after {
    background: #CCCCCC;
    box-shadow: 0 7px 0 #CCCCCC, 0 14px 0 #CCCCCC; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 20px;
  height: 16px; }
  .menu-icon.dark::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    top: 0;
    left: 0;
    box-shadow: 0 7px 0 #000, 0 14px 0 #000; }
  .menu-icon.dark:hover::after {
    background: #CCCCCC;
    box-shadow: 0 7px 0 #CCCCCC, 0 14px 0 #CCCCCC; }

/* !  Extra Components — UI outside of Foundation
	 ------------------------------------------------------------------------------------------------------*/
/* ==========================================================================
#
# //////////////////////////////// ICONS ////////////////////////////////////
#
========================================================================== */
@font-face {
  font-family: "fontawesome";
  src: url("../fonts/fontawesome.eot");
  src: url("../fonts/fontawesome.eot?#iefix") format("embedded-opentype"), url("../fonts/fontawesome.woff") format("woff"), url("../fonts/fontawesome.ttf") format("truetype"), url("../fonts/fontawesome.svg#fontawesome") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "fontawesome" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontawesome" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-asterisk:before {
  content: "\f069"; }

.icon-plus:before {
  content: "\f067"; }

.icon-question:before {
  content: "\f128"; }

.icon-minus:before {
  content: "\f068"; }

.icon-glass:before {
  content: "\f000"; }

.icon-music:before {
  content: "\f001"; }

.icon-search:before {
  content: "\f002"; }

.icon-envelope-o:before {
  content: "\f003"; }

.icon-heart:before {
  content: "\f004"; }

.icon-star:before {
  content: "\f005"; }

.icon-star-o:before {
  content: "\f006"; }

.icon-user:before {
  content: "\f007"; }

.icon-film:before {
  content: "\f008"; }

.icon-th-large:before {
  content: "\f009"; }

.icon-th:before {
  content: "\f00a"; }

.icon-th-list:before {
  content: "\f00b"; }

.icon-check:before {
  content: "\f00c"; }

.icon-close:before {
  content: "\f00d"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-times:before {
  content: "\f00d"; }

.icon-search-plus:before {
  content: "\f00e"; }

.icon-search-minus:before {
  content: "\f010"; }

.icon-power-off:before {
  content: "\f011"; }

.icon-signal:before {
  content: "\f012"; }

.icon-cog:before {
  content: "\f013"; }

.icon-gear:before {
  content: "\f013"; }

.icon-trash-o:before {
  content: "\f014"; }

.icon-home:before {
  content: "\f015"; }

.icon-file-o:before {
  content: "\f016"; }

.icon-clock-o:before {
  content: "\f017"; }

.icon-road:before {
  content: "\f018"; }

.icon-download:before {
  content: "\f019"; }

.icon-arrow-circle-o-down:before {
  content: "\f01a"; }

.icon-arrow-circle-o-up:before {
  content: "\f01b"; }

.icon-inbox:before {
  content: "\f01c"; }

.icon-play-circle-o:before {
  content: "\f01d"; }

.icon-repeat:before {
  content: "\f01e"; }

.icon-rotate-right:before {
  content: "\f01e"; }

.icon-refresh:before {
  content: "\f021"; }

.icon-list-alt:before {
  content: "\f022"; }

.icon-lock:before {
  content: "\f023"; }

.icon-flag:before {
  content: "\f024"; }

.icon-headphones:before {
  content: "\f025"; }

.icon-volume-off:before {
  content: "\f026"; }

.icon-volume-down:before {
  content: "\f027"; }

.icon-volume-up:before {
  content: "\f028"; }

.icon-qrcode:before {
  content: "\f029"; }

.icon-barcode:before {
  content: "\f02a"; }

.icon-tag:before {
  content: "\f02b"; }

.icon-tags:before {
  content: "\f02c"; }

.icon-book:before {
  content: "\f02d"; }

.icon-bookmark:before {
  content: "\f02e"; }

.icon-print:before {
  content: "\f02f"; }

.icon-camera:before {
  content: "\f030"; }

.icon-font:before {
  content: "\f031"; }

.icon-bold:before {
  content: "\f032"; }

.icon-italic:before {
  content: "\f033"; }

.icon-text-height:before {
  content: "\f034"; }

.icon-text-width:before {
  content: "\f035"; }

.icon-align-left:before {
  content: "\f036"; }

.icon-align-center:before {
  content: "\f037"; }

.icon-align-right:before {
  content: "\f038"; }

.icon-align-justify:before {
  content: "\f039"; }

.icon-list:before {
  content: "\f03a"; }

.icon-dedent:before {
  content: "\f03b"; }

.icon-outdent:before {
  content: "\f03b"; }

.icon-indent:before {
  content: "\f03c"; }

.icon-video-camera:before {
  content: "\f03d"; }

.icon-image:before {
  content: "\f03e"; }

.icon-photo:before {
  content: "\f03e"; }

.icon-picture-o:before {
  content: "\f03e"; }

.icon-pencil:before {
  content: "\f040"; }

.icon-map-marker:before {
  content: "\f041"; }

.icon-adjust:before {
  content: "\f042"; }

.icon-tint:before {
  content: "\f043"; }

.icon-edit:before {
  content: "\f044"; }

.icon-pencil-square-o:before {
  content: "\f044"; }

.icon-share-square-o:before {
  content: "\f045"; }

.icon-check-square-o:before {
  content: "\f046"; }

.icon-arrows:before {
  content: "\f047"; }

.icon-step-backward:before {
  content: "\f048"; }

.icon-fast-backward:before {
  content: "\f049"; }

.icon-backward:before {
  content: "\f04a"; }

.icon-play:before {
  content: "\f04b"; }

.icon-pause:before {
  content: "\f04c"; }

.icon-stop:before {
  content: "\f04d"; }

.icon-forward:before {
  content: "\f04e"; }

.icon-fast-forward:before {
  content: "\f050"; }

.icon-step-forward:before {
  content: "\f051"; }

.icon-eject:before {
  content: "\f052"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-plus-circle:before {
  content: "\f055"; }

.icon-minus-circle:before {
  content: "\f056"; }

.icon-times-circle:before {
  content: "\f057"; }

.icon-check-circle:before {
  content: "\f058"; }

.icon-question-circle:before {
  content: "\f059"; }

.icon-info-circle:before {
  content: "\f05a"; }

.icon-crosshairs:before {
  content: "\f05b"; }

.icon-times-circle-o:before {
  content: "\f05c"; }

.icon-check-circle-o:before {
  content: "\f05d"; }

.icon-ban:before {
  content: "\f05e"; }

.icon-arrow-left:before {
  content: "\f060"; }

.icon-arrow-right:before {
  content: "\f061"; }

.icon-arrow-up:before {
  content: "\f062"; }

.icon-arrow-down:before {
  content: "\f063"; }

.icon-mail-forward:before {
  content: "\f064"; }

.icon-share:before {
  content: "\f064"; }

.icon-expand:before {
  content: "\f065"; }

.icon-compress:before {
  content: "\f066"; }

.icon-exclamation-circle:before {
  content: "\f06a"; }

.icon-gift:before {
  content: "\f06b"; }

.icon-leaf:before {
  content: "\f06c"; }

.icon-fire:before {
  content: "\f06d"; }

.icon-eye:before {
  content: "\f06e"; }

.icon-eye-slash:before {
  content: "\f070"; }

.icon-exclamation-triangle:before {
  content: "\f071"; }

.icon-warning:before {
  content: "\f071"; }

.icon-plane:before {
  content: "\f072"; }

.icon-calendar:before {
  content: "\f073"; }

.icon-random:before {
  content: "\f074"; }

.icon-comment:before {
  content: "\f075"; }

.icon-magnet:before {
  content: "\f076"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-retweet:before {
  content: "\f079"; }

.icon-shopping-cart:before {
  content: "\f07a"; }

.icon-folder:before {
  content: "\f07b"; }

.icon-folder-open:before {
  content: "\f07c"; }

.icon-arrows-v:before {
  content: "\f07d"; }

.icon-arrows-h:before {
  content: "\f07e"; }

.icon-bar-chart:before {
  content: "\f080"; }

.icon-bar-chart-o:before {
  content: "\f080"; }

.icon-twitter-square:before {
  content: "\f081"; }

.icon-facebook-square:before {
  content: "\f082"; }

.icon-camera-retro:before {
  content: "\f083"; }

.icon-key:before {
  content: "\f084"; }

.icon-cogs:before {
  content: "\f085"; }

.icon-gears:before {
  content: "\f085"; }

.icon-comments:before {
  content: "\f086"; }

.icon-thumbs-o-up:before {
  content: "\f087"; }

.icon-thumbs-o-down:before {
  content: "\f088"; }

.icon-star-half:before {
  content: "\f089"; }

.icon-heart-o:before {
  content: "\f08a"; }

.icon-sign-out:before {
  content: "\f08b"; }

.icon-linkedin-square:before {
  content: "\f08c"; }

.icon-thumb-tack:before {
  content: "\f08d"; }

.icon-external-link:before {
  content: "\f08e"; }

.icon-sign-in:before {
  content: "\f090"; }

.icon-trophy:before {
  content: "\f091"; }

.icon-github-square:before {
  content: "\f092"; }

.icon-upload:before {
  content: "\f093"; }

.icon-lemon-o:before {
  content: "\f094"; }

.icon-phone:before {
  content: "\f095"; }

.icon-square-o:before {
  content: "\f096"; }

.icon-bookmark-o:before {
  content: "\f097"; }

.icon-phone-square:before {
  content: "\f098"; }

.icon-twitter:before {
  content: "\f099"; }

.icon-facebook:before {
  content: "\f09a"; }

.icon-facebook-f:before {
  content: "\f09a"; }

.icon-github:before {
  content: "\f09b"; }

.icon-unlock:before {
  content: "\f09c"; }

.icon-credit-card:before {
  content: "\f09d"; }

.icon-feed:before {
  content: "\f09e"; }

.icon-rss:before {
  content: "\f09e"; }

.icon-hdd-o:before {
  content: "\f0a0"; }

.icon-bullhorn:before {
  content: "\f0a1"; }

.icon-bell-o:before {
  content: "\f0a2"; }

.icon-certificate:before {
  content: "\f0a3"; }

.icon-hand-o-right:before {
  content: "\f0a4"; }

.icon-hand-o-left:before {
  content: "\f0a5"; }

.icon-hand-o-up:before {
  content: "\f0a6"; }

.icon-hand-o-down:before {
  content: "\f0a7"; }

.icon-arrow-circle-left:before {
  content: "\f0a8"; }

.icon-arrow-circle-right:before {
  content: "\f0a9"; }

.icon-arrow-circle-up:before {
  content: "\f0aa"; }

.icon-arrow-circle-down:before {
  content: "\f0ab"; }

.icon-globe:before {
  content: "\f0ac"; }

.icon-wrench:before {
  content: "\f0ad"; }

.icon-tasks:before {
  content: "\f0ae"; }

.icon-filter:before {
  content: "\f0b0"; }

.icon-briefcase:before {
  content: "\f0b1"; }

.icon-arrows-alt:before {
  content: "\f0b2"; }

.icon-group:before {
  content: "\f0c0"; }

.icon-users:before {
  content: "\f0c0"; }

.icon-chain:before {
  content: "\f0c1"; }

.icon-link:before {
  content: "\f0c1"; }

.icon-cloud:before {
  content: "\f0c2"; }

.icon-flask:before {
  content: "\f0c3"; }

.icon-cut:before {
  content: "\f0c4"; }

.icon-scissors:before {
  content: "\f0c4"; }

.icon-copy:before {
  content: "\f0c5"; }

.icon-files-o:before {
  content: "\f0c5"; }

.icon-paperclip:before {
  content: "\f0c6"; }

.icon-floppy-o:before {
  content: "\f0c7"; }

.icon-save:before {
  content: "\f0c7"; }

.icon-square:before {
  content: "\f0c8"; }

.icon-bars:before {
  content: "\f0c9"; }

.icon-navicon:before {
  content: "\f0c9"; }

.icon-reorder:before {
  content: "\f0c9"; }

.icon-list-ul:before {
  content: "\f0ca"; }

.icon-list-ol:before {
  content: "\f0cb"; }

.icon-strikethrough:before {
  content: "\f0cc"; }

.icon-underline:before {
  content: "\f0cd"; }

.icon-table:before {
  content: "\f0ce"; }

.icon-magic:before {
  content: "\f0d0"; }

.icon-truck:before {
  content: "\f0d1"; }

.icon-pinterest:before {
  content: "\f0d2"; }

.icon-pinterest-square:before {
  content: "\f0d3"; }

.icon-google-plus-square:before {
  content: "\f0d4"; }

.icon-google-plus:before {
  content: "\f0d5"; }

.icon-money:before {
  content: "\f0d6"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-caret-left:before {
  content: "\f0d9"; }

.icon-caret-right:before {
  content: "\f0da"; }

.icon-columns:before {
  content: "\f0db"; }

.icon-sort:before {
  content: "\f0dc"; }

.icon-unsorted:before {
  content: "\f0dc"; }

.icon-sort-desc:before {
  content: "\f0dd"; }

.icon-sort-down:before {
  content: "\f0dd"; }

.icon-sort-asc:before {
  content: "\f0de"; }

.icon-sort-up:before {
  content: "\f0de"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-linkedin:before {
  content: "\f0e1"; }

.icon-rotate-left:before {
  content: "\f0e2"; }

.icon-undo:before {
  content: "\f0e2"; }

.icon-gavel:before {
  content: "\f0e3"; }

.icon-legal:before {
  content: "\f0e3"; }

.icon-dashboard:before {
  content: "\f0e4"; }

.icon-tachometer:before {
  content: "\f0e4"; }

.icon-comment-o:before {
  content: "\f0e5"; }

.icon-comments-o:before {
  content: "\f0e6"; }

.icon-bolt:before {
  content: "\f0e7"; }

.icon-flash:before {
  content: "\f0e7"; }

.icon-sitemap:before {
  content: "\f0e8"; }

.icon-umbrella:before {
  content: "\f0e9"; }

.icon-clipboard:before {
  content: "\f0ea"; }

.icon-paste:before {
  content: "\f0ea"; }

.icon-lightbulb-o:before {
  content: "\f0eb"; }

.icon-exchange:before {
  content: "\f0ec"; }

.icon-cloud-download:before {
  content: "\f0ed"; }

.icon-cloud-upload:before {
  content: "\f0ee"; }

.icon-user-md:before {
  content: "\f0f0"; }

.icon-stethoscope:before {
  content: "\f0f1"; }

.icon-suitcase:before {
  content: "\f0f2"; }

.icon-bell:before {
  content: "\f0f3"; }

.icon-coffee:before {
  content: "\f0f4"; }

.icon-cutlery:before {
  content: "\f0f5"; }

.icon-file-text-o:before {
  content: "\f0f6"; }

.icon-building-o:before {
  content: "\f0f7"; }

.icon-hospital-o:before {
  content: "\f0f8"; }

.icon-ambulance:before {
  content: "\f0f9"; }

.icon-medkit:before {
  content: "\f0fa"; }

.icon-fighter-jet:before {
  content: "\f0fb"; }

.icon-beer:before {
  content: "\f0fc"; }

.icon-h-square:before {
  content: "\f0fd"; }

.icon-plus-square:before {
  content: "\f0fe"; }

.icon-angle-double-left:before {
  content: "\f100"; }

.icon-angle-double-right:before {
  content: "\f101"; }

.icon-angle-double-up:before {
  content: "\f102"; }

.icon-angle-double-down:before {
  content: "\f103"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

.icon-desktop:before {
  content: "\f108"; }

.icon-laptop:before {
  content: "\f109"; }

.icon-tablet:before {
  content: "\f10a"; }

.icon-mobile:before {
  content: "\f10b"; }

.icon-mobile-phone:before {
  content: "\f10b"; }

.icon-circle-o:before {
  content: "\f10c"; }

.icon-quote-left:before {
  content: "\f10d"; }

.icon-quote-right:before {
  content: "\f10e"; }

.icon-spinner:before {
  content: "\f110"; }

.icon-circle:before {
  content: "\f111"; }

.icon-mail-reply:before {
  content: "\f112"; }

.icon-reply:before {
  content: "\f112"; }

.icon-github-alt:before {
  content: "\f113"; }

.icon-folder-o:before {
  content: "\f114"; }

.icon-folder-open-o:before {
  content: "\f115"; }

.icon-smile-o:before {
  content: "\f118"; }

.icon-frown-o:before {
  content: "\f119"; }

.icon-meh-o:before {
  content: "\f11a"; }

.icon-gamepad:before {
  content: "\f11b"; }

.icon-keyboard-o:before {
  content: "\f11c"; }

.icon-flag-o:before {
  content: "\f11d"; }

.icon-flag-checkered:before {
  content: "\f11e"; }

.icon-terminal:before {
  content: "\f120"; }

.icon-code:before {
  content: "\f121"; }

.icon-mail-reply-all:before {
  content: "\f122"; }

.icon-reply-all:before {
  content: "\f122"; }

.icon-star-half-empty:before {
  content: "\f123"; }

.icon-star-half-full:before {
  content: "\f123"; }

.icon-star-half-o:before {
  content: "\f123"; }

.icon-location-arrow:before {
  content: "\f124"; }

.icon-crop:before {
  content: "\f125"; }

.icon-code-fork:before {
  content: "\f126"; }

.icon-chain-broken:before {
  content: "\f127"; }

.icon-unlink:before {
  content: "\f127"; }

.icon-info:before {
  content: "\f129"; }

.icon-exclamation:before {
  content: "\f12a"; }

.icon-superscript:before {
  content: "\f12b"; }

.icon-subscript:before {
  content: "\f12c"; }

.icon-eraser:before {
  content: "\f12d"; }

.icon-puzzle-piece:before {
  content: "\f12e"; }

.icon-microphone:before {
  content: "\f130"; }

.icon-microphone-slash:before {
  content: "\f131"; }

.icon-shield:before {
  content: "\f132"; }

.icon-calendar-o:before {
  content: "\f133"; }

.icon-fire-extinguisher:before {
  content: "\f134"; }

.icon-rocket:before {
  content: "\f135"; }

.icon-maxcdn:before {
  content: "\f136"; }

.icon-chevron-circle-left:before {
  content: "\f137"; }

.icon-chevron-circle-right:before {
  content: "\f138"; }

.icon-chevron-circle-up:before {
  content: "\f139"; }

.icon-chevron-circle-down:before {
  content: "\f13a"; }

.icon-html5:before {
  content: "\f13b"; }

.icon-css3:before {
  content: "\f13c"; }

.icon-anchor:before {
  content: "\f13d"; }

.icon-unlock-alt:before {
  content: "\f13e"; }

.icon-bullseye:before {
  content: "\f140"; }

.icon-ellipsis-h:before {
  content: "\f141"; }

.icon-ellipsis-v:before {
  content: "\f142"; }

.icon-rss-square:before {
  content: "\f143"; }

.icon-play-circle:before {
  content: "\f144"; }

.icon-ticket:before {
  content: "\f145"; }

.icon-minus-square:before {
  content: "\f146"; }

.icon-minus-square-o:before {
  content: "\f147"; }

.icon-level-up:before {
  content: "\f148"; }

.icon-level-down:before {
  content: "\f149"; }

.icon-check-square:before {
  content: "\f14a"; }

.icon-pencil-square:before {
  content: "\f14b"; }

.icon-external-link-square:before {
  content: "\f14c"; }

.icon-share-square:before {
  content: "\f14d"; }

.icon-compass:before {
  content: "\f14e"; }

.icon-caret-square-o-down:before {
  content: "\f150"; }

.icon-toggle-down:before {
  content: "\f150"; }

.icon-caret-square-o-up:before {
  content: "\f151"; }

.icon-toggle-up:before {
  content: "\f151"; }

.icon-caret-square-o-right:before {
  content: "\f152"; }

.icon-toggle-right:before {
  content: "\f152"; }

.icon-eur:before {
  content: "\f153"; }

.icon-euro:before {
  content: "\f153"; }

.icon-gbp:before {
  content: "\f154"; }

.icon-dollar:before {
  content: "\f155"; }

.icon-usd:before {
  content: "\f155"; }

.icon-inr:before {
  content: "\f156"; }

.icon-rupee:before {
  content: "\f156"; }

.icon-cny:before {
  content: "\f157"; }

.icon-jpy:before {
  content: "\f157"; }

.icon-rmb:before {
  content: "\f157"; }

.icon-yen:before {
  content: "\f157"; }

.icon-rouble:before {
  content: "\f158"; }

.icon-rub:before {
  content: "\f158"; }

.icon-ruble:before {
  content: "\f158"; }

.icon-krw:before {
  content: "\f159"; }

.icon-won:before {
  content: "\f159"; }

.icon-bitcoin:before {
  content: "\f15a"; }

.icon-btc:before {
  content: "\f15a"; }

.icon-file:before {
  content: "\f15b"; }

.icon-file-text:before {
  content: "\f15c"; }

.icon-sort-alpha-asc:before {
  content: "\f15d"; }

.icon-sort-alpha-desc:before {
  content: "\f15e"; }

.icon-sort-amount-asc:before {
  content: "\f160"; }

.icon-sort-amount-desc:before {
  content: "\f161"; }

.icon-sort-numeric-asc:before {
  content: "\f162"; }

.icon-sort-numeric-desc:before {
  content: "\f163"; }

.icon-thumbs-up:before {
  content: "\f164"; }

.icon-thumbs-down:before {
  content: "\f165"; }

.icon-youtube-square:before {
  content: "\f166"; }

.icon-youtube:before {
  content: "\f167"; }

.icon-xing:before {
  content: "\f168"; }

.icon-xing-square:before {
  content: "\f169"; }

.icon-youtube-play:before {
  content: "\f16a"; }

.icon-dropbox:before {
  content: "\f16b"; }

.icon-stack-overflow:before {
  content: "\f16c"; }

.icon-instagram:before {
  content: "\f16d"; }

.icon-flickr:before {
  content: "\f16e"; }

.icon-adn:before {
  content: "\f170"; }

.icon-bitbucket:before {
  content: "\f171"; }

.icon-bitbucket-square:before {
  content: "\f172"; }

.icon-tumblr:before {
  content: "\f173"; }

.icon-tumblr-square:before {
  content: "\f174"; }

.icon-long-arrow-down:before {
  content: "\f175"; }

.icon-long-arrow-up:before {
  content: "\f176"; }

.icon-long-arrow-left:before {
  content: "\f177"; }

.icon-long-arrow-right:before {
  content: "\f178"; }

.icon-apple:before {
  content: "\f179"; }

.icon-windows:before {
  content: "\f17a"; }

.icon-android:before {
  content: "\f17b"; }

.icon-linux:before {
  content: "\f17c"; }

.icon-dribbble:before {
  content: "\f17d"; }

.icon-skype:before {
  content: "\f17e"; }

.icon-foursquare:before {
  content: "\f180"; }

.icon-trello:before {
  content: "\f181"; }

.icon-female:before {
  content: "\f182"; }

.icon-male:before {
  content: "\f183"; }

.icon-gittip:before {
  content: "\f184"; }

.icon-gratipay:before {
  content: "\f184"; }

.icon-sun-o:before {
  content: "\f185"; }

.icon-moon-o:before {
  content: "\f186"; }

.icon-archive:before {
  content: "\f187"; }

.icon-bug:before {
  content: "\f188"; }

.icon-vk:before {
  content: "\f189"; }

.icon-weibo:before {
  content: "\f18a"; }

.icon-renren:before {
  content: "\f18b"; }

.icon-pagelines:before {
  content: "\f18c"; }

.icon-stack-exchange:before {
  content: "\f18d"; }

.icon-arrow-circle-o-right:before {
  content: "\f18e"; }

.icon-arrow-circle-o-left:before {
  content: "\f190"; }

.icon-caret-square-o-left:before {
  content: "\f191"; }

.icon-toggle-left:before {
  content: "\f191"; }

.icon-dot-circle-o:before {
  content: "\f192"; }

.icon-wheelchair:before {
  content: "\f193"; }

.icon-vimeo-square:before {
  content: "\f194"; }

.icon-try:before {
  content: "\f195"; }

.icon-turkish-lira:before {
  content: "\f195"; }

.icon-plus-square-o:before {
  content: "\f196"; }

.icon-space-shuttle:before {
  content: "\f197"; }

.icon-slack:before {
  content: "\f198"; }

.icon-envelope-square:before {
  content: "\f199"; }

.icon-wordpress:before {
  content: "\f19a"; }

.icon-openid:before {
  content: "\f19b"; }

.icon-bank:before {
  content: "\f19c"; }

.icon-institution:before {
  content: "\f19c"; }

.icon-university:before {
  content: "\f19c"; }

.icon-graduation-cap:before {
  content: "\f19d"; }

.icon-mortar-board:before {
  content: "\f19d"; }

.icon-yahoo:before {
  content: "\f19e"; }

.icon-google:before {
  content: "\f1a0"; }

.icon-reddit:before {
  content: "\f1a1"; }

.icon-reddit-square:before {
  content: "\f1a2"; }

.icon-stumbleupon-circle:before {
  content: "\f1a3"; }

.icon-stumbleupon:before {
  content: "\f1a4"; }

.icon-delicious:before {
  content: "\f1a5"; }

.icon-digg:before {
  content: "\f1a6"; }

.icon-pied-piper-pp:before {
  content: "\f1a7"; }

.icon-pied-piper-alt:before {
  content: "\f1a8"; }

.icon-drupal:before {
  content: "\f1a9"; }

.icon-joomla:before {
  content: "\f1aa"; }

.icon-language:before {
  content: "\f1ab"; }

.icon-fax:before {
  content: "\f1ac"; }

.icon-building:before {
  content: "\f1ad"; }

.icon-child:before {
  content: "\f1ae"; }

.icon-paw:before {
  content: "\f1b0"; }

.icon-spoon:before {
  content: "\f1b1"; }

.icon-cube:before {
  content: "\f1b2"; }

.icon-cubes:before {
  content: "\f1b3"; }

.icon-behance:before {
  content: "\f1b4"; }

.icon-behance-square:before {
  content: "\f1b5"; }

.icon-steam:before {
  content: "\f1b6"; }

.icon-steam-square:before {
  content: "\f1b7"; }

.icon-recycle:before {
  content: "\f1b8"; }

.icon-automobile:before {
  content: "\f1b9"; }

.icon-car:before {
  content: "\f1b9"; }

.icon-cab:before {
  content: "\f1ba"; }

.icon-taxi:before {
  content: "\f1ba"; }

.icon-tree:before {
  content: "\f1bb"; }

.icon-spotify:before {
  content: "\f1bc"; }

.icon-deviantart:before {
  content: "\f1bd"; }

.icon-soundcloud:before {
  content: "\f1be"; }

.icon-database:before {
  content: "\f1c0"; }

.icon-file-pdf-o:before {
  content: "\f1c1"; }

.icon-file-word-o:before {
  content: "\f1c2"; }

.icon-file-excel-o:before {
  content: "\f1c3"; }

.icon-file-powerpoint-o:before {
  content: "\f1c4"; }

.icon-file-image-o:before {
  content: "\f1c5"; }

.icon-file-photo-o:before {
  content: "\f1c5"; }

.icon-file-picture-o:before {
  content: "\f1c5"; }

.icon-file-archive-o:before {
  content: "\f1c6"; }

.icon-file-zip-o:before {
  content: "\f1c6"; }

.icon-file-audio-o:before {
  content: "\f1c7"; }

.icon-file-sound-o:before {
  content: "\f1c7"; }

.icon-file-movie-o:before {
  content: "\f1c8"; }

.icon-file-video-o:before {
  content: "\f1c8"; }

.icon-file-code-o:before {
  content: "\f1c9"; }

.icon-vine:before {
  content: "\f1ca"; }

.icon-codepen:before {
  content: "\f1cb"; }

.icon-jsfiddle:before {
  content: "\f1cc"; }

.icon-life-bouy:before {
  content: "\f1cd"; }

.icon-life-buoy:before {
  content: "\f1cd"; }

.icon-life-ring:before {
  content: "\f1cd"; }

.icon-life-saver:before {
  content: "\f1cd"; }

.icon-support:before {
  content: "\f1cd"; }

.icon-circle-o-notch:before {
  content: "\f1ce"; }

.icon-ra:before {
  content: "\f1d0"; }

.icon-rebel:before {
  content: "\f1d0"; }

.icon-resistance:before {
  content: "\f1d0"; }

.icon-empire:before {
  content: "\f1d1"; }

.icon-ge:before {
  content: "\f1d1"; }

.icon-git-square:before {
  content: "\f1d2"; }

.icon-git:before {
  content: "\f1d3"; }

.icon-hacker-news:before {
  content: "\f1d4"; }

.icon-y-combinator-square:before {
  content: "\f1d4"; }

.icon-yc-square:before {
  content: "\f1d4"; }

.icon-tencent-weibo:before {
  content: "\f1d5"; }

.icon-qq:before {
  content: "\f1d6"; }

.icon-wechat:before {
  content: "\f1d7"; }

.icon-weixin:before {
  content: "\f1d7"; }

.icon-paper-plane:before {
  content: "\f1d8"; }

.icon-send:before {
  content: "\f1d8"; }

.icon-paper-plane-o:before {
  content: "\f1d9"; }

.icon-send-o:before {
  content: "\f1d9"; }

.icon-history:before {
  content: "\f1da"; }

.icon-circle-thin:before {
  content: "\f1db"; }

.icon-header:before {
  content: "\f1dc"; }

.icon-paragraph:before {
  content: "\f1dd"; }

.icon-sliders:before {
  content: "\f1de"; }

.icon-share-alt:before {
  content: "\f1e0"; }

.icon-share-alt-square:before {
  content: "\f1e1"; }

.icon-bomb:before {
  content: "\f1e2"; }

.icon-futbol-o:before {
  content: "\f1e3"; }

.icon-soccer-ball-o:before {
  content: "\f1e3"; }

.icon-tty:before {
  content: "\f1e4"; }

.icon-binoculars:before {
  content: "\f1e5"; }

.icon-plug:before {
  content: "\f1e6"; }

.icon-slideshare:before {
  content: "\f1e7"; }

.icon-twitch:before {
  content: "\f1e8"; }

.icon-yelp:before {
  content: "\f1e9"; }

.icon-newspaper-o:before {
  content: "\f1ea"; }

.icon-wifi:before {
  content: "\f1eb"; }

.icon-calculator:before {
  content: "\f1ec"; }

.icon-paypal:before {
  content: "\f1ed"; }

.icon-google-wallet:before {
  content: "\f1ee"; }

.icon-cc-visa:before {
  content: "\f1f0"; }

.icon-cc-mastercard:before {
  content: "\f1f1"; }

.icon-cc-discover:before {
  content: "\f1f2"; }

.icon-cc-amex:before {
  content: "\f1f3"; }

.icon-cc-paypal:before {
  content: "\f1f4"; }

.icon-cc-stripe:before {
  content: "\f1f5"; }

.icon-bell-slash:before {
  content: "\f1f6"; }

.icon-bell-slash-o:before {
  content: "\f1f7"; }

.icon-trash:before {
  content: "\f1f8"; }

.icon-copyright:before {
  content: "\f1f9"; }

.icon-at:before {
  content: "\f1fa"; }

.icon-eyedropper:before {
  content: "\f1fb"; }

.icon-paint-brush:before {
  content: "\f1fc"; }

.icon-birthday-cake:before {
  content: "\f1fd"; }

.icon-area-chart:before {
  content: "\f1fe"; }

.icon-pie-chart:before {
  content: "\f200"; }

.icon-line-chart:before {
  content: "\f201"; }

.icon-lastfm:before {
  content: "\f202"; }

.icon-lastfm-square:before {
  content: "\f203"; }

.icon-toggle-off:before {
  content: "\f204"; }

.icon-toggle-on:before {
  content: "\f205"; }

.icon-bicycle:before {
  content: "\f206"; }

.icon-bus:before {
  content: "\f207"; }

.icon-ioxhost:before {
  content: "\f208"; }

.icon-angellist:before {
  content: "\f209"; }

.icon-cc:before {
  content: "\f20a"; }

.icon-ils:before {
  content: "\f20b"; }

.icon-shekel:before {
  content: "\f20b"; }

.icon-sheqel:before {
  content: "\f20b"; }

.icon-meanpath:before {
  content: "\f20c"; }

.icon-buysellads:before {
  content: "\f20d"; }

.icon-connectdevelop:before {
  content: "\f20e"; }

.icon-dashcube:before {
  content: "\f210"; }

.icon-forumbee:before {
  content: "\f211"; }

.icon-leanpub:before {
  content: "\f212"; }

.icon-sellsy:before {
  content: "\f213"; }

.icon-shirtsinbulk:before {
  content: "\f214"; }

.icon-simplybuilt:before {
  content: "\f215"; }

.icon-skyatlas:before {
  content: "\f216"; }

.icon-cart-plus:before {
  content: "\f217"; }

.icon-cart-arrow-down:before {
  content: "\f218"; }

.icon-diamond:before {
  content: "\f219"; }

.icon-ship:before {
  content: "\f21a"; }

.icon-user-secret:before {
  content: "\f21b"; }

.icon-motorcycle:before {
  content: "\f21c"; }

.icon-street-view:before {
  content: "\f21d"; }

.icon-heartbeat:before {
  content: "\f21e"; }

.icon-venus:before {
  content: "\f221"; }

.icon-mars:before {
  content: "\f222"; }

.icon-mercury:before {
  content: "\f223"; }

.icon-intersex:before {
  content: "\f224"; }

.icon-transgender:before {
  content: "\f224"; }

.icon-transgender-alt:before {
  content: "\f225"; }

.icon-venus-double:before {
  content: "\f226"; }

.icon-mars-double:before {
  content: "\f227"; }

.icon-venus-mars:before {
  content: "\f228"; }

.icon-mars-stroke:before {
  content: "\f229"; }

.icon-mars-stroke-v:before {
  content: "\f22a"; }

.icon-mars-stroke-h:before {
  content: "\f22b"; }

.icon-neuter:before {
  content: "\f22c"; }

.icon-genderless:before {
  content: "\f22d"; }

.icon-facebook-official:before {
  content: "\f230"; }

.icon-pinterest-p:before {
  content: "\f231"; }

.icon-whatsapp:before {
  content: "\f232"; }

.icon-server:before {
  content: "\f233"; }

.icon-user-plus:before {
  content: "\f234"; }

.icon-user-times:before {
  content: "\f235"; }

.icon-bed:before {
  content: "\f236"; }

.icon-hotel:before {
  content: "\f236"; }

.icon-viacoin:before {
  content: "\f237"; }

.icon-train:before {
  content: "\f238"; }

.icon-subway:before {
  content: "\f239"; }

.icon-medium:before {
  content: "\f23a"; }

.icon-y-combinator:before {
  content: "\f23b"; }

.icon-yc:before {
  content: "\f23b"; }

.icon-optin-monster:before {
  content: "\f23c"; }

.icon-opencart:before {
  content: "\f23d"; }

.icon-expeditedssl:before {
  content: "\f23e"; }

.icon-battery:before {
  content: "\f240"; }

.icon-battery-4:before {
  content: "\f240"; }

.icon-battery-full:before {
  content: "\f240"; }

.icon-battery-3:before {
  content: "\f241"; }

.icon-battery-three-quarters:before {
  content: "\f241"; }

.icon-battery-2:before {
  content: "\f242"; }

.icon-battery-half:before {
  content: "\f242"; }

.icon-battery-1:before {
  content: "\f243"; }

.icon-battery-quarter:before {
  content: "\f243"; }

.icon-battery-0:before {
  content: "\f244"; }

.icon-battery-empty:before {
  content: "\f244"; }

.icon-mouse-pointer:before {
  content: "\f245"; }

.icon-i-cursor:before {
  content: "\f246"; }

.icon-object-group:before {
  content: "\f247"; }

.icon-object-ungroup:before {
  content: "\f248"; }

.icon-sticky-note:before {
  content: "\f249"; }

.icon-sticky-note-o:before {
  content: "\f24a"; }

.icon-cc-jcb:before {
  content: "\f24b"; }

.icon-cc-diners-club:before {
  content: "\f24c"; }

.icon-clone:before {
  content: "\f24d"; }

.icon-balance-scale:before {
  content: "\f24e"; }

.icon-hourglass-o:before {
  content: "\f250"; }

.icon-hourglass-1:before {
  content: "\f251"; }

.icon-hourglass-start:before {
  content: "\f251"; }

.icon-hourglass-2:before {
  content: "\f252"; }

.icon-hourglass-half:before {
  content: "\f252"; }

.icon-hourglass-3:before {
  content: "\f253"; }

.icon-hourglass-end:before {
  content: "\f253"; }

.icon-hourglass:before {
  content: "\f254"; }

.icon-hand-grab-o:before {
  content: "\f255"; }

.icon-hand-rock-o:before {
  content: "\f255"; }

.icon-hand-paper-o:before {
  content: "\f256"; }

.icon-hand-stop-o:before {
  content: "\f256"; }

.icon-hand-scissors-o:before {
  content: "\f257"; }

.icon-hand-lizard-o:before {
  content: "\f258"; }

.icon-hand-spock-o:before {
  content: "\f259"; }

.icon-hand-pointer-o:before {
  content: "\f25a"; }

.icon-hand-peace-o:before {
  content: "\f25b"; }

.icon-trademark:before {
  content: "\f25c"; }

.icon-registered:before {
  content: "\f25d"; }

.icon-creative-commons:before {
  content: "\f25e"; }

.icon-gg:before {
  content: "\f260"; }

.icon-gg-circle:before {
  content: "\f261"; }

.icon-tripadvisor:before {
  content: "\f262"; }

.icon-odnoklassniki:before {
  content: "\f263"; }

.icon-odnoklassniki-square:before {
  content: "\f264"; }

.icon-get-pocket:before {
  content: "\f265"; }

.icon-wikipedia-w:before {
  content: "\f266"; }

.icon-safari:before {
  content: "\f267"; }

.icon-chrome:before {
  content: "\f268"; }

.icon-firefox:before {
  content: "\f269"; }

.icon-opera:before {
  content: "\f26a"; }

.icon-internet-explorer:before {
  content: "\f26b"; }

.icon-television:before {
  content: "\f26c"; }

.icon-tv:before {
  content: "\f26c"; }

.icon-contao:before {
  content: "\f26d"; }

.icon-500px:before {
  content: "\f26e"; }

.icon-amazon:before {
  content: "\f270"; }

.icon-calendar-plus-o:before {
  content: "\f271"; }

.icon-calendar-minus-o:before {
  content: "\f272"; }

.icon-calendar-times-o:before {
  content: "\f273"; }

.icon-calendar-check-o:before {
  content: "\f274"; }

.icon-industry:before {
  content: "\f275"; }

.icon-map-pin:before {
  content: "\f276"; }

.icon-map-signs:before {
  content: "\f277"; }

.icon-map-o:before {
  content: "\f278"; }

.icon-map:before {
  content: "\f279"; }

.icon-commenting:before {
  content: "\f27a"; }

.icon-commenting-o:before {
  content: "\f27b"; }

.icon-houzz:before {
  content: "\f27c"; }

.icon-vimeo:before {
  content: "\f27d"; }

.icon-black-tie:before {
  content: "\f27e"; }

.icon-fonticons:before {
  content: "\f280"; }

.icon-reddit-alien:before {
  content: "\f281"; }

.icon-edge:before {
  content: "\f282"; }

.icon-credit-card-alt:before {
  content: "\f283"; }

.icon-codiepie:before {
  content: "\f284"; }

.icon-modx:before {
  content: "\f285"; }

.icon-fort-awesome:before {
  content: "\f286"; }

.icon-usb:before {
  content: "\f287"; }

.icon-product-hunt:before {
  content: "\f288"; }

.icon-mixcloud:before {
  content: "\f289"; }

.icon-scribd:before {
  content: "\f28a"; }

.icon-pause-circle:before {
  content: "\f28b"; }

.icon-pause-circle-o:before {
  content: "\f28c"; }

.icon-stop-circle:before {
  content: "\f28d"; }

.icon-stop-circle-o:before {
  content: "\f28e"; }

.icon-shopping-bag:before {
  content: "\f290"; }

.icon-shopping-basket:before {
  content: "\f291"; }

.icon-hashtag:before {
  content: "\f292"; }

.icon-bluetooth:before {
  content: "\f293"; }

.icon-bluetooth-b:before {
  content: "\f294"; }

.icon-percent:before {
  content: "\f295"; }

.icon-gitlab:before {
  content: "\f296"; }

.icon-wpbeginner:before {
  content: "\f297"; }

.icon-wpforms:before {
  content: "\f298"; }

.icon-envira:before {
  content: "\f299"; }

.icon-universal-access:before {
  content: "\f29a"; }

.icon-wheelchair-alt:before {
  content: "\f29b"; }

.icon-question-circle-o:before {
  content: "\f29c"; }

.icon-blind:before {
  content: "\f29d"; }

.icon-audio-description:before {
  content: "\f29e"; }

.icon-volume-control-phone:before {
  content: "\f2a0"; }

.icon-braille:before {
  content: "\f2a1"; }

.icon-assistive-listening-systems:before {
  content: "\f2a2"; }

.icon-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.icon-asl-interpreting:before {
  content: "\f2a3"; }

.icon-deaf:before {
  content: "\f2a4"; }

.icon-deafness:before {
  content: "\f2a4"; }

.icon-hard-of-hearing:before {
  content: "\f2a4"; }

.icon-glide:before {
  content: "\f2a5"; }

.icon-glide-g:before {
  content: "\f2a6"; }

.icon-sign-language:before {
  content: "\f2a7"; }

.icon-signing:before {
  content: "\f2a7"; }

.icon-low-vision:before {
  content: "\f2a8"; }

.icon-viadeo:before {
  content: "\f2a9"; }

.icon-viadeo-square:before {
  content: "\f2aa"; }

.icon-snapchat:before {
  content: "\f2ab"; }

.icon-snapchat-ghost:before {
  content: "\f2ac"; }

.icon-snapchat-square:before {
  content: "\f2ad"; }

.icon-pied-piper:before {
  content: "\f2ae"; }

.icon-first-order:before {
  content: "\f2b0"; }

.icon-yoast:before {
  content: "\f2b1"; }

.icon-themeisle:before {
  content: "\f2b2"; }

.icon-google-plus-circle:before {
  content: "\f2b3"; }

.icon-google-plus-official:before {
  content: "\f2b3"; }

.icon-fa:before {
  content: "\f2b4"; }

.icon-font-awesome:before {
  content: "\f2b4"; }

.icon-handshake-o:before {
  content: "\f2b5"; }

.icon-envelope-open:before {
  content: "\f2b6"; }

.icon-envelope-open-o:before {
  content: "\f2b7"; }

.icon-linode:before {
  content: "\f2b8"; }

.icon-address-book:before {
  content: "\f2b9"; }

.icon-address-book-o:before {
  content: "\f2ba"; }

.icon-address-card:before {
  content: "\f2bb"; }

.icon-vcard:before {
  content: "\f2bb"; }

.icon-address-card-o:before {
  content: "\f2bc"; }

.icon-vcard-o:before {
  content: "\f2bc"; }

.icon-user-circle:before {
  content: "\f2bd"; }

.icon-user-circle-o:before {
  content: "\f2be"; }

.icon-user-o:before {
  content: "\f2c0"; }

.icon-id-badge:before {
  content: "\f2c1"; }

.icon-drivers-license:before {
  content: "\f2c2"; }

.icon-id-card:before {
  content: "\f2c2"; }

.icon-drivers-license-o:before {
  content: "\f2c3"; }

.icon-id-card-o:before {
  content: "\f2c3"; }

.icon-quora:before {
  content: "\f2c4"; }

.icon-free-code-camp:before {
  content: "\f2c5"; }

.icon-telegram:before {
  content: "\f2c6"; }

.icon-thermometer:before {
  content: "\f2c7"; }

.icon-thermometer-4:before {
  content: "\f2c7"; }

.icon-thermometer-full:before {
  content: "\f2c7"; }

.icon-thermometer-3:before {
  content: "\f2c8"; }

.icon-thermometer-three-quarters:before {
  content: "\f2c8"; }

.icon-thermometer-2:before {
  content: "\f2c9"; }

.icon-thermometer-half:before {
  content: "\f2c9"; }

.icon-thermometer-1:before {
  content: "\f2ca"; }

.icon-thermometer-quarter:before {
  content: "\f2ca"; }

.icon-thermometer-0:before {
  content: "\f2cb"; }

.icon-thermometer-empty:before {
  content: "\f2cb"; }

.icon-shower:before {
  content: "\f2cc"; }

.icon-bath:before {
  content: "\f2cd"; }

.icon-bathtub:before {
  content: "\f2cd"; }

.icon-s15:before {
  content: "\f2cd"; }

.icon-podcast:before {
  content: "\f2ce"; }

.icon-window-maximize:before {
  content: "\f2d0"; }

.icon-window-minimize:before {
  content: "\f2d1"; }

.icon-window-restore:before {
  content: "\f2d2"; }

.icon-times-rectangle:before {
  content: "\f2d3"; }

.icon-window-close:before {
  content: "\f2d3"; }

.icon-times-rectangle-o:before {
  content: "\f2d4"; }

.icon-window-close-o:before {
  content: "\f2d4"; }

.icon-bandcamp:before {
  content: "\f2d5"; }

.icon-grav:before {
  content: "\f2d6"; }

.icon-etsy:before {
  content: "\f2d7"; }

.icon-imdb:before {
  content: "\f2d8"; }

.icon-ravelry:before {
  content: "\f2d9"; }

.icon-eercast:before {
  content: "\f2da"; }

.icon-microchip:before {
  content: "\f2db"; }

.icon-snowflake-o:before {
  content: "\f2dc"; }

.icon-superpowers:before {
  content: "\f2dd"; }

.icon-wpexplorer:before {
  content: "\f2de"; }

.icon-meetup:before {
  content: "\f2e0"; }

/* ==========================================================================
#
# ///////////////////////////// CUSTOM FORMS ///////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  VARIABLES
	========================================================================== */
/* ==========================================================================
	!  BASE
	========================================================================== */
.custom-form input[type=radio], .custom-form input[type=checkbox] {
  display: none; }
  .custom-form input[type=radio]:disabled + label, .custom-form input[type=checkbox]:disabled + label {
    opacity: 0.4;
    cursor: default; }

.custom-form label {
  cursor: pointer;
  position: relative;
  margin-right: 15px;
  padding-left: 30px;
  margin-left: 0;
  padding-left: 0; }
  .custom-form label:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0px;
    background-color: none;
    border: none; }

.custom-form input[type=radio] + label {
  margin-bottom: 15px;
  padding-left: 30px; }
  .custom-form input[type=radio] + label:before {
    border-radius: 100%;
    width: 22px;
    height: 22px;
    top: 1px;
    z-index: 9;
    background-color: #e6e6e6;
    border: 1px solid #EFEFEF; }

.custom-form input[type=radio]:checked + label:before {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  line-height: 21px;
  background-color: #031d56;
  z-index: 10; }

.custom-form input[type=checkbox] + label {
  margin-bottom: 10px;
  padding-left: 30px; }
  .custom-form input[type=checkbox] + label:before {
    width: 20px;
    height: 20px;
    top: 2px;
    background-color: #e6e6e6;
    border: 1px solid #EFEFEF; }

.custom-form input[type=checkbox]:checked + label:before {
  font-family: "fontawesome";
  content: '\e61e';
  font-size: 18px;
  font-weight: normal;
  color: #00adff;
  text-align: center;
  line-height: 18px;
  background-color: #fff; }

.custom-form input[type=submit].postfix {
  border-radius: 0 3px 3px 0; }

/* ==========================================================================
#
# //////////////////////////////// ICON LIST ////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  ICON LISTS
	========================================================================== */
.checklist {
  list-style-type: none;
  margin-left: 0; }
  .checklist li {
    padding: 0.25em 0 0.25em 25px;
    position: relative;
    line-height: 1.5; }
    .checklist li:before {
      font-family: "fontawesome";
      width: 25px;
      position: absolute;
      top: 0.25em;
      left: 0;
      display: block; }
    .checklist li *:nth-child(n+2) {
      text-indent: 0; }
  .checklist.primary li:before {
    color: #031d56; }
  .checklist.secondary li:before {
    color: #00adff; }
  .checklist.success li:before {
    color: #3adb76; }
  .checklist.warning li:before {
    color: #ffae00; }
  .checklist.alert li:before {
    color: #ff0f0f; }
  .checklist.info li:before {
    color: #00adff; }
  .checklist li:before {
    content: '\e014';
    line-height: 20px; }

.arrowlist {
  list-style-type: none;
  margin-left: 0; }
  .arrowlist li {
    padding: 0.25em 0 0.25em 25px;
    position: relative;
    line-height: 1.5; }
    .arrowlist li:before {
      font-family: "fontawesome";
      width: 25px;
      position: absolute;
      top: 0.25em;
      left: 0;
      display: block; }
    .arrowlist li *:nth-child(n+2) {
      text-indent: 0; }
  .arrowlist.primary li:before {
    color: #031d56; }
  .arrowlist.secondary li:before {
    color: #00adff; }
  .arrowlist.success li:before {
    color: #3adb76; }
  .arrowlist.warning li:before {
    color: #ffae00; }
  .arrowlist.alert li:before {
    color: #ff0f0f; }
  .arrowlist.info li:before {
    color: #00adff; }
  .arrowlist li:before {
    content: '\e008';
    line-height: 1.6;
    top: 0.15em; }
  .arrowlist.column-fix li:before {
    top: .15em; }

.faqlist {
  list-style-type: none;
  margin-left: 0; }
  .faqlist li {
    padding: 0.25em 0 0.25em 45px;
    position: relative;
    line-height: 1.5; }
    .faqlist li:before {
      font-family: "fontawesome";
      width: 45px;
      position: absolute;
      top: 0.25em;
      left: 0;
      display: block; }
    .faqlist li *:nth-child(n+2) {
      text-indent: 0; }
  .faqlist.primary li:before {
    color: #031d56; }
  .faqlist.secondary li:before {
    color: #00adff; }
  .faqlist.success li:before {
    color: #3adb76; }
  .faqlist.warning li:before {
    color: #ffae00; }
  .faqlist.alert li:before {
    color: #ff0f0f; }
  .faqlist.info li:before {
    color: #00adff; }
  .faqlist li:before {
    content: '\e01e';
    font-size: 30px;
    line-height: 20px; }

/* ==========================================================================
#
# //////////////////////////////// INLINE LIST //////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  INLINE LIST
	========================================================================== */
.inline-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden; }
  .inline-list > li {
    display: block;
    float: left;
    list-style: none;
    margin: 0 1rem 0 0; }
    .inline-list > li > * {
      display: block; }

/* ==========================================================================
#
# //////////////////////////////// JQUERY UI ////////////////////////////////
#
========================================================================== */
/*! jQuery UI - v1.10.3 - 2013-05-03
* http://jqueryui.com */
.ui-slider-range-label {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 25px !important;
  font-weight: 400 !important;
  font-size: 0.8em !important;
  margin-bottom: 10px !important;
  padding-left: 0 !important; }

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted black; }

.ui-slider {
  position: relative;
  text-align: left; }

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 18px;
  height: 18px;
  cursor: default;
  -moz-border-radius: 10em;
  -webkit-border-radius: 10em;
  border-radius: 10em; }

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0; }

/* For IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit; }

.ui-slider-horizontal {
  height: .8em; }

.ui-slider-horizontal .ui-slider-handle {
  top: -5px;
  margin-left: -.6em; }

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range-max {
  right: 0; }

.ui-slider-vertical {
  width: .8em;
  height: 100px; }

.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em; }

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%; }

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0; }

.ui-slider-vertical .ui-slider-range-max {
  top: 0; }

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Verdana,Arial,sans-serif;
  font-size: 0.9em; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Verdana,Arial,sans-serif;
  font-size: 1em; }

.ui-widget-content {
  border: 1px solid #DDDDDD;
  background: #F5F5F5;
  color: #222222; }

.ui-widget-content a {
  color: #222222; }

.ui-widget-header {
  border: 1px solid #aaaaaa;
  background: #777777;
  color: #222222;
  font-weight: bold; }

.ui-widget-header a {
  color: #222222; }

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #777777;
  font-weight: normal;
  color: #555555; }

.ui-state-default:after,
.ui-widget-content .ui-state-default:after {
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  z-index: 100;
  -moz-border-radius: 10em;
  -webkit-border-radius: 10em;
  border-radius: 10em;
  margin: 3px;
  background: #fff; }

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #d2d2d2;
  font-weight: normal;
  color: #212121;
  box-shadow: 0px 0px 5px #031d56; }

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
  color: #212121;
  text-decoration: none; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: #d2d2d2;
  font-weight: normal;
  color: #212121; }

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none; }

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 4px; }

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 4px; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 4px; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 4px; }

/* ==========================================================================
#
# ///////////////////////////// MENU HAMBURGER //////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  VARIABLES & MIXINS
	========================================================================== */
/* ==========================================================================
	!  BASE STYLES
	========================================================================== */
.lines-button {
  display: block;
  padding: 0 0.75rem;
  margin-top: -0.25rem;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
  border-radius: 0.28571rem; }
  .lines-button:hover {
    opacity: 1; }
  .lines-button:active {
    transition: 0;
    background: rgba(0, 0, 0, 0.1); }

.lines {
  display: inline-block;
  width: 2rem;
  height: 0.28571rem;
  background: #fff;
  border-radius: 0.14286rem;
  transition: 1s;
  position: relative; }
  .lines:before, .lines:after {
    display: inline-block;
    width: 2rem;
    height: 0.28571rem;
    background: #fff;
    border-radius: 0.14286rem;
    transition: 1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.14286rem center; }
  .lines:before {
    top: 0.5rem; }
  .lines:after {
    top: -0.5rem; }

.lines-button:hover {
  opacity: 1; }
  .lines-button:hover .lines:before {
    top: 0.57143rem; }
  .lines-button:hover .lines:after {
    top: -0.57143rem; }

.lines-button .lines {
  transition: background 0.3s 0.6s ease; }
  .lines-button .lines:before, .lines-button .lines:after {
    transform-origin: 50% 50%;
    transition: top 0.3s 0.6s ease, transform 0.3s ease; }

.lines-button.close .lines {
  transition: background 0.3s 0s ease;
  background: transparent; }
  .lines-button.close .lines:before, .lines-button.close .lines:after {
    transition: top 0.3s ease, transform 0.3s 0.5s ease;
    top: 0;
    width: 2rem;
    background: #e6e6e6; }
  .lines-button.close .lines:before {
    transform: rotate3d(0, 0, 1, 45deg); }
  .lines-button.close .lines:after {
    transform: rotate3d(0, 0, 1, -45deg); }

/* ==========================================================================
#
# ////////////////////////////// SELECT 2 ///////////////////////////////////
#
========================================================================== */
/*
Version: 3.4.8 Timestamp: Thu May  1 09:50:32 EDT 2014
*/
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle; }

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box;
  /* webkit */
  -moz-box-sizing: border-box;
  /* firefox */
  box-sizing: border-box;
  /* css3 */ }

.select2-container .select2-choice {
  display: block;
  height: 2.875rem;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  border: 2px solid #FFF;
  white-space: nowrap;
  line-height: 2;
  color: #333333;
  text-decoration: none;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #F5F5F5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2); }

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
  border-radius: 0 0 4px 4px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #eee), color-stop(0.9, #fff));
  background-image: -webkit-linear-gradient(center bottom, #eee 0%, #fff 90%);
  background-image: -moz-linear-gradient(center bottom, #eee 0%, #fff 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
  background-image: linear-gradient(to bottom, #eee 0%, #fff 90%); }

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px; }

.select2-container .select2-choice > .select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: none;
  width: auto; }

.select2-container .select2-choice abbr {
  display: none;
  width: 37px;
  height: 100%;
  position: absolute;
  right: 36px;
  top: 0;
  text-decoration: none;
  font-size: 1em;
  border: 0;
  cursor: pointer;
  outline: 0;
  background: #999999; }
  .select2-container .select2-choice abbr:before {
    font-family: "fontawesome";
    content: "\2421";
    position: relative;
    display: block;
    color: #DDDDDD;
    font-size: 1em;
    padding: 0.1em 0.2em; }

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block; }
  .select2-container.select2-allowclear .select2-choice abbr:before {
    font-family: "fontawesome";
    content: "\2421";
    position: relative;
    display: block;
    color: #DDDDDD;
    font-size: 1em; }

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer; }
  .select2-container .select2-choice abbr:hover:before {
    color: #F5F5F5; }

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-drop {
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #F5F5F5;
  color: #000;
  border: 1px solid #aaa;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15); }

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15); }

.select2-drop-active {
  border: 1px solid #5897fb;
  border-top: none; }

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #5897fb; }

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto; }

.select2-drop-auto-width .select2-search {
  padding-top: 4px; }

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  background-clip: padding-box; }

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%;
  background: #DDDDDD;
  height: 2.875rem; }
  .select2-container .select2-choice .select2-arrow b:before {
    font-family: "fontawesome";
    content: "\25BE";
    position: relative;
    display: block;
    color: #FFF;
    font-size: 2em;
    padding: 0.1em 0.25em;
    line-height: 1; }

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap; }

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #aaa;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff url("select2.png") no-repeat 100% -22px;
  background: url("../../img/select2/select2.png") no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url("../../img/select2/select2.png") no-repeat 100% -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("../../img/select2/select2.png") no-repeat 100% -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("../../img/select2/select2.png") no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0; }

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px; }

.select2-search input.select2-active {
  background: #fff url("select2-spinner.gif") no-repeat 100%;
  background: url("../../img/select2/select2-spinner.gif") no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url("../../img/select2/select2-spinner.gif") no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("../../img/select2/select2-spinner.gif") no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("../../img/select2/select2-spinner.gif") no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0; }

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid #5897fb;
  outline: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }

.select2-dropdown-open .select2-choice {
  border-bottom: solid 1px #999999;
  background-color: #F5F5F5; }

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #5897fb;
  border-top-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(0.5, #eee));
  background-image: -webkit-linear-gradient(center top, #fff 0%, #eee 50%);
  background-image: -moz-linear-gradient(center top, #fff 0%, #eee 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%); }

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none; }

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px; }

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0; }

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none; }

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold; }

.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  font-size: 1.1em;
  color: #333333;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.select2-results-dept-1 .select2-result-label {
  padding-left: 10px; }

.select2-results-dept-2 .select2-result-label {
  padding-left: 20px; }

.select2-results-dept-3 .select2-result-label {
  padding-left: 30px; }

.select2-results-dept-4 .select2-result-label {
  padding-left: 40px; }

.select2-results-dept-5 .select2-result-label {
  padding-left: 50px; }

.select2-results-dept-6 .select2-result-label {
  padding-left: 60px; }

.select2-results-dept-7 .select2-result-label {
  padding-left: 70px; }

.select2-results .select2-highlighted {
  background: #dcd9d9;
  color: #fff; }

.select2-results li em {
  background: #feffde;
  font-style: normal; }

.select2-results .select2-highlighted em {
  background: transparent; }

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000; }

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding-left: 5px; }

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default; }

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default; }

.select2-results .select2-selected {
  display: none; }

.select2-more-results.select2-active {
  background: #f4f4f4 url("../../img/select2/select2-spinner.gif") no-repeat 100%; }

.select2-more-results {
  background: #f4f4f4;
  display: list-item; }

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default; }

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0; }

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none; }

/* multiselect */
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0 0 1em;
  padding: 0.5em;
  position: relative;
  border: 2px solid white;
  cursor: text;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
  background: #F5F5F5;
  border-radius: 4px; }

.select2-locked {
  padding: 3px 5px 3px 5px !important; }

.select2-container-multi .select2-choices {
  min-height: 26px;
  margin-bottom: 0; }

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #5897fb;
  outline: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none; }

html[dir="rtl"] .select2-container-multi .select2-choices li {
  float: right; }

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap; }

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important; }

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url("../../img/select2/select2-spinner.gif") no-repeat 100% !important; }

.select2-default {
  color: #999999 !important;
  font-weight: 400 !important; }

.select2-container-multi .select2-choices .select2-search-choice {
  font-size: 17px;
  font-weight: 300;
  color: #FFF;
  padding: 0.25em 1em;
  margin: 0.25em 0.5em;
  line-height: 1;
  background: #000;
  border-radius: 5px;
  position: relative;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .select2-container-multi .select2-choices .select2-search-choice > div {
    padding-left: 10px; }
  .select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close {
    display: block;
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0 !important;
    font-size: 1em;
    outline: none;
    background: transparent !important; }
    .select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:before {
      font-family: "fontawesome";
      content: "\e610";
      position: relative;
      display: block;
      color: #FFF;
      top: 7px;
      left: 5px;
      font-size: 0.7em; }
    .select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
      color: #F5F5F5; }

html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice {
  margin-left: 0;
  margin-right: 5px; }

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default; }

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4; }

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none;
  background: url("../../img/select2/select2.png") right top no-repeat; }

html[dir="rtl"] .select2-search-choice-close {
  right: auto;
  left: 3px; }

.select2-container-multi .select2-search-choice-close {
  left: 3px; }

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px; }

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px; }

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default; }

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4; }

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none; }

/* end multiselect */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline; }

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important; }

.select2-display-none {
  display: none; }

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll; }

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx) {
  .select2-search input,
  .select2-search-choice-close,
  .select2-container .select2-choice abbr,
  .select2-container .select2-choice .select2-arrow b {
    background-image: url("../../img/select2/select2x2.png") !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important; }
  .select2-search input {
    background-position: 100% -21px !important; } }

/* ==========================================================================
#
# //////////////////////////// SLICK SLIDER /////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  BASE STYLES
	========================================================================== */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  z-index: 10; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  .slick-slide:focus {
    outline: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* ==========================================================================
	!  GENERAL THEME STYLES
	========================================================================== */
.slick-loading .slick-list {
  background: #fff url(/lib/img/loader.gif) center center no-repeat; }

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 100%;
  width: 25px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  outline: none;
  box-shadow: none;
  text-shadow: none;
  z-index: 12; }
  .slick-prev:focus,
  .slick-next:focus {
    outline: none;
    background: transparent; }
  .slick-prev:hover,
  .slick-next:hover {
    outline: none;
    background: transparent; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    display: none !important; }

.slick-prev:before,
.slick-next:before {
  font-family: "fontawesome";
  font-size: 24px;
  line-height: 1.7;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #00adff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: block; }

.slick-prev {
  left: 0; }
  .slick-prev:before {
    content: "\f053";
    right: 8px; }

.slick-next {
  right: 0; }
  .slick-next:before {
    content: "\f054";
    left: -8px; }

.slick-slider {
  margin-bottom: 50px; }

.slick-dots {
  position: absolute;
  bottom: -60px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  margin-left: 0; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      box-shadow: none;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "\e608";
        width: 20px;
        height: 20px;
        font-family: "fontawesome";
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #F9F9F9;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #031d56;
      text-shadow: 2px 2px 0 #DDDDDD; }

/* ==========================================================================
	!  SPECIFIC THEME STYLES
    ========================================================================== */
.slick-slider .container {
  padding: 0 0.5em; }

.slick-slider.no-dots .slick-dots {
  display: none !important; }

.slick-slider.no-arrows .slick-arrow {
  display: none !important; }

.arrow-controls {
  position: relative;
  transform: translate3d(0, 0, 0); }
  .arrow-controls .append-arrows {
    position: relative;
    width: 100px;
    height: 35px;
    border-radius: 15px;
    overflow: hidden; }
    .arrow-controls .append-arrows.center {
      display: inherit;
      margin: 0 auto; }
  .arrow-controls .slick-arrow {
    top: 0;
    display: inline-block !important;
    width: 48%;
    background: #031d56; }
    .arrow-controls .slick-arrow:hover {
      background: #04256f; }
    .arrow-controls .slick-arrow:before {
      color: #F5F5F5;
      font-size: 16px;
      border: none;
      line-height: 33px; }
      .arrow-controls .slick-arrow:before:hover {
        color: #fff; }
  .arrow-controls .slick-prev {
    left: 0;
    border-radius: 0;
    transform: skewX(-15deg) scale(1.1); }
    .arrow-controls .slick-prev:before {
      right: -5px;
      transform: skewX(15deg) scale(0.9); }
  .arrow-controls .slick-next {
    right: 0;
    border-radius: 0;
    transform: skewX(-15deg) scale(1.1);
    border-left: solid 1px #02153d; }
    .arrow-controls .slick-next:before {
      left: 2px;
      transform: skewX(15deg) scale(0.9); }

.collage .img-collage.layout-1 {
  padding-right: 115px;
  padding-top: 30px; }
  .collage .img-collage.layout-1 li + li {
    z-index: 10;
    margin-left: 50%;
    padding-right: 5%; }
  .collage .img-collage.layout-1 li + li + li {
    margin-left: 70%;
    top: 0;
    z-index: 5;
    padding-right: 0; }

.collage .img-collage.layout-2 {
  padding-right: 115px; }
  .collage .img-collage.layout-2 li + li {
    z-index: 10;
    margin-left: 60%; }

.collage .img-collage.layout-3 {
  padding-right: 115px;
  padding-top: 30px; }
  .collage .img-collage.layout-3 li + li {
    margin-left: 70%;
    top: 0;
    z-index: 5;
    padding-right: 0; }

@media screen and (max-width: 39.9375em) {
  .carousel.collage {
    margin-bottom: 10px; }
    .carousel.collage .img-collage {
      padding: 0;
      margin-bottom: 0; }
      .carousel.collage .img-collage li {
        display: none; }
      .carousel.collage .img-collage li:first-child {
        display: block; } }

.carousel.full-width .container {
  padding: 0; }

.carousel.full-width .slick-list {
  overflow: visible; }

.carousel.full-width .slick-slide {
  position: relative; }

.carousel.full-width .slick-prev {
  left: 20px; }

.carousel.full-width .slick-next {
  right: 20px; }

.carousel.full-width aside {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: solid 2px #524d4f;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: 90%;
  top: 5%; }

.carousel.full-width img {
  position: relative;
  opacity: 0.25;
  transition: all 500ms ease-in-out; }

.carousel.full-width h5 {
  color: #DDDDDD;
  font-style: italic;
  position: absolute;
  bottom: 0;
  padding: 20px;
  font-size: 1.5em;
  line-height: 1;
  font-weight: normal;
  visibility: hidden; }

.carousel.full-width blockquote {
  color: #DDDDDD;
  font-style: italic;
  position: absolute;
  bottom: 0;
  padding: 20px;
  font-size: 1.5em;
  line-height: 1;
  font-weight: normal;
  visibility: hidden; }
  .carousel.full-width blockquote:before {
    content: none; }
  .carousel.full-width blockquote p, .carousel.full-width blockquote cite {
    color: #DDDDDD; }

.carousel.full-width .slick-center aside {
  border: solid 2px #AAAAAA;
  border-radius: 7px;
  z-index: 11;
  height: 100%;
  top: 0;
  transform: scale(0.95);
  transition: all 500ms ease-in-out; }
  .carousel.full-width .slick-center aside:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.4) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%); }
  .carousel.full-width .slick-center aside h5 {
    z-index: 10;
    visibility: visible;
    transition: all 500ms ease-in-out; }
  .carousel.full-width .slick-center aside blockquote {
    z-index: 10;
    visibility: visible;
    transition: all 500ms ease-in-out; }

.carousel.full-width .slick-center img {
  opacity: 1;
  z-index: 10;
  transform: scale(1.05);
  border-radius: 3px;
  transition: all 500ms ease-in-out; }

@media screen and (max-width: 39.9375em) {
  .carousel.full-width .slick-list {
    overflow: hidden; }
  .carousel.full-width aside {
    border: none; }
  .carousel.full-width .slick-center aside {
    transform: scale(0.9); }
  .carousel.full-width .slick-center img {
    display: table;
    margin: 0 auto;
    border-radius: 0;
    transform: scale(1); } }

.imgslider-main {
  margin-bottom: 1em; }

/* ==========================================================================
#
# //////////////////////////// SOCIAL ICONS /////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  INLINE ICON LIST
	========================================================================== */
.social-list a {
  display: block;
  padding: 0rem !important;
  margin-right: 0.75rem;
  border-radius: 3px;
  color: #fff !important;
  background: #8a8a8a;
  position: relative;
  text-align: center;
  outline: none; }
  .social-list a i {
    width: 100%; }

.social-list a {
  width: 2rem;
  height: 2rem;
  font-size: 1.225rem; }
  .social-list a i {
    line-height: 2.4rem; }

.social-list .social-title {
  font-size: 0.9em;
  color: #8a8a8a;
  padding-right: 1rem;
  line-height: 2rem;
  display: inline-block;
  vertical-align: bottom; }
  @media screen and (max-width: 63.9375em) {
    .social-list .social-title {
      display: block;
      width: 100%; } }

.social-list.small a {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.91875rem; }
  .social-list.small a i {
    line-height: 1.8rem; }

.social-list.small .social-title {
  font-size: 0.9em;
  color: #8a8a8a;
  padding-right: 1rem;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: bottom; }
  @media screen and (max-width: 63.9375em) {
    .social-list.small .social-title {
      display: block;
      width: 100%; } }

.social-list.large a {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.53125rem; }
  .social-list.large a i {
    line-height: 3rem; }

.social-list.large .social-title {
  font-size: 0.9em;
  color: #8a8a8a;
  padding-right: 1rem;
  line-height: 2.5rem;
  display: inline-block;
  vertical-align: bottom; }
  @media screen and (max-width: 63.9375em) {
    .social-list.large .social-title {
      display: block;
      width: 100%; } }

.social-list.xlarge a {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 2.14375rem; }
  .social-list.xlarge a i {
    line-height: 4.2rem; }

.social-list.xlarge .social-title {
  font-size: 0.9em;
  color: #8a8a8a;
  padding-right: 1rem;
  line-height: 3.5rem;
  display: inline-block;
  vertical-align: bottom; }
  @media screen and (max-width: 63.9375em) {
    .social-list.xlarge .social-title {
      display: block;
      width: 100%; } }

.social-list .has-tip {
  border-bottom: none;
  cursor: pointer; }

/* ==========================================================================
#
# ////////////////////////////// TABDROPS /////////////////////////////////
#
========================================================================== */
/*!
 * Tabdrops --- Using Bootstrap v2.3.0
 * 
 * Modified by DH 05162016
 * Copyright 2012 Stefan Petre
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
/* ==========================================================================
	!  TAB MENU && TAB CONTENT
	========================================================================== */
.tab-menu {
  position: relative;
  margin-bottom: 20px;
  margin-left: 0;
  list-style: none;
  *zoom: 1;
  border-bottom: 1px solid #ddd; }
  .tab-menu > li > a {
    display: block;
    padding: 8px 12px 8px 12px;
    margin-right: 2px;
    line-height: 20px;
    color: #454142; }
    .tab-menu > li > a:hover, .tab-menu > li > a:focus {
      text-decoration: none;
      border-bottom: solid 4px #AAAAAA; }
  .tab-menu > li {
    float: left;
    margin-bottom: -1px; }
    .tab-menu > li.active > a {
      cursor: default;
      border-bottom: solid 4px #031d56;
      font-weight: bold; }
      .tab-menu > li.active > a:hover, .tab-menu > li.active > a:focus {
        cursor: default;
        border-bottom: solid 4px #031d56; }
  .tab-menu:before {
    display: table;
    line-height: 0;
    content: ""; }
  .tab-menu:after {
    display: table;
    line-height: 0;
    content: "";
    clear: both; }

.tab-content {
  overflow: auto;
  padding: 1em; }
  .tab-content > .tab-pane {
    display: none; }
  .tab-content > .active {
    display: block; }

/* ==========================================================================
	!  TAB BASE && DROPDOWN 
	========================================================================== */
.tabdrops {
  *zoom: 1; }
  .tabdrops:before {
    display: table;
    line-height: 0;
    content: ""; }
  .tabdrops:after {
    display: table;
    line-height: 0;
    content: "";
    clear: both; }
  .tabdrops .dropdown {
    position: relative;
    float: right !important; }
  .tabdrops .tabdrop-toggle {
    *margin-bottom: -3px; }
    .tabdrops .tabdrop-toggle:active {
      outline: 0; }
  .tabdrops .tabdrop-icon {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #00adff;
    text-align: center;
    font-style: normal;
    line-height: 1.2;
    color: #fff;
    cursor: pointer; }
    .tabdrops .tabdrop-icon:before {
      font-family: "fontawesome";
      content: "\e642";
      position: relative;
      top: 2px; }
  .tabdrops .open .tabdrop-toggle {
    outline: 0; }
  .tabdrops .tabdrop-menu {
    min-width: 160px;
    right: 0 !important;
    left: auto !important;
    display: none;
    position: absolute;
    background: #fff;
    border: solid 1px #DDDDDD;
    list-style: none;
    padding: 0.5em; }
    .tabdrops .tabdrop-menu.open {
      display: block; }
    .tabdrops .tabdrop-menu li.active > a {
      background: #EEEEEE; }

/* ==========================================================================
#
# ///////////////////////////// TOGGLER ///////////////////////////////////
#
========================================================================== */
/* ==========================================================================
	!  BASE 
	========================================================================== */
.toggle_wrap {
  margin-bottom: 20px; }
  .toggle_wrap ul {
    margin: 0; }
    .toggle_wrap ul li {
      list-style: none;
      border-bottom: 1px solid #DDDDDD;
      margin: 0;
      padding: 10px 0 10px 40px;
      position: relative; }
      .toggle_wrap ul li:after {
        content: "";
        display: table;
        clear: both; }
  .toggle_wrap .toggler {
    font-size: 1em;
    margin: 0;
    font-weight: normal; }
    .toggle_wrap .toggler a {
      color: #454142; }
    .toggle_wrap .toggler:before {
      font-family: "fontawesome";
      content: "\f056";
      font-weight: normal;
      display: block;
      height: 22px;
      width: 22px;
      margin-right: 20px;
      margin-top: 0px;
      position: absolute;
      left: 0;
      color: #fff;
      text-align: center;
      background: #031d56;
      font-size: 0.7em;
      line-height: 25px;
      margin-left: 5px;
      cursor: pointer; }
    .toggle_wrap .toggler.active a {
      color: #0d0d0d;
      font-weight: bold; }
    .toggle_wrap .toggler.active:before {
      content: "\f057";
      color: #fff;
      background: #00adff; }
  .toggle_wrap .togglee {
    padding: 20px 10px 20px 0px;
    color: #454142; }

/* !  Layout — Headers, footers, sidebars (reusable )
	 ------------------------------------------------------------------------------------------------------*/
/* ==========================================================================
#
# //////////////////////////// COLOR HELPERS /////////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. Text Colors
		2. Background Colors
		3. Misc
-------------------------------------------*/
/* ==========================================================================
	1.  TEXT COLORS
	========================================================================== */
.primary-color {
  color: #031d56 !important; }
  .primary-colora:hover, .primary-colora:focus {
    color: #03194a !important; }

a.primary-color:hover, a.primary-color:focus {
  color: #03194a !important; }

.primary-hover-color:hover, .primary-hover-color:focus {
  color: #031d56 !important; }

.secondary-color {
  color: #00adff !important; }
  .secondary-colora:hover, .secondary-colora:focus {
    color: #0095db !important; }

a.secondary-color:hover, a.secondary-color:focus {
  color: #0095db !important; }

.secondary-hover-color:hover, .secondary-hover-color:focus {
  color: #00adff !important; }

.success-color {
  color: #3adb76 !important; }
  .success-colora:hover, .success-colora:focus {
    color: #25c962 !important; }

a.success-color:hover, a.success-color:focus {
  color: #25c962 !important; }

.success-hover-color:hover, .success-hover-color:focus {
  color: #3adb76 !important; }

.warning-color {
  color: #ffae00 !important; }
  .warning-colora:hover, .warning-colora:focus {
    color: #db9600 !important; }

a.warning-color:hover, a.warning-color:focus {
  color: #db9600 !important; }

.warning-hover-color:hover, .warning-hover-color:focus {
  color: #ffae00 !important; }

.alert-color {
  color: #ff0f0f !important; }
  .alert-colora:hover, .alert-colora:focus {
    color: #e80000 !important; }

a.alert-color:hover, a.alert-color:focus {
  color: #e80000 !important; }

.alert-hover-color:hover, .alert-hover-color:focus {
  color: #ff0f0f !important; }

.info-color {
  color: #00adff !important; }
  .info-colora:hover, .info-colora:focus {
    color: #0095db !important; }

a.info-color:hover, a.info-color:focus {
  color: #0095db !important; }

.info-hover-color:hover, .info-hover-color:focus {
  color: #00adff !important; }

.white-color {
  color: #fff !important; }
  .white-colora:hover, .white-colora:focus {
    color: #dbdbdb !important; }

a.white-color:hover, a.white-color:focus {
  color: #dbdbdb !important; }

.white-hover-color:hover, .white-hover-color:focus {
  color: #fff !important; }

.black-color {
  color: #000 !important; }
  .black-colora:hover, .black-colora:focus {
    color: black !important; }

a.black-color:hover, a.black-color:focus {
  color: black !important; }

.black-hover-color:hover, .black-hover-color:focus {
  color: #000 !important; }

.gray-color {
  color: #767676 !important; }
  .gray-colora:hover, .gray-colora:focus {
    color: #656565 !important; }

a.gray-color:hover, a.gray-color:focus {
  color: #656565 !important; }

.gray-hover-color:hover, .gray-hover-color:focus {
  color: #767676 !important; }

.light-gray-color {
  color: #e6e6e6 !important; }
  .light-gray-colora:hover, .light-gray-colora:focus {
    color: #c6c6c6 !important; }

a.light-gray-color:hover, a.light-gray-color:focus {
  color: #c6c6c6 !important; }

.light-gray-hover-color:hover, .light-gray-hover-color:focus {
  color: #e6e6e6 !important; }

.medium-gray-color {
  color: #cacaca !important; }
  .medium-gray-colora:hover, .medium-gray-colora:focus {
    color: #aeaeae !important; }

a.medium-gray-color:hover, a.medium-gray-color:focus {
  color: #aeaeae !important; }

.medium-gray-hover-color:hover, .medium-gray-hover-color:focus {
  color: #cacaca !important; }

.dark-gray-color {
  color: #8a8a8a !important; }
  .dark-gray-colora:hover, .dark-gray-colora:focus {
    color: #777777 !important; }

a.dark-gray-color:hover, a.dark-gray-color:focus {
  color: #777777 !important; }

.dark-gray-hover-color:hover, .dark-gray-hover-color:focus {
  color: #8a8a8a !important; }

.twitter-color {
  color: #00aced !important; }
  .twitter-colora:hover, .twitter-colora:focus {
    color: #0094cc !important; }

a.twitter-color:hover, a.twitter-color:focus {
  color: #0094cc !important; }

.twitter-hover-color:hover, .twitter-hover-color:focus {
  color: #00aced !important; }

.facebook-color {
  color: #3b5998 !important; }
  .facebook-colora:hover, .facebook-colora:focus {
    color: #334d83 !important; }

a.facebook-color:hover, a.facebook-color:focus {
  color: #334d83 !important; }

.facebook-hover-color:hover, .facebook-hover-color:focus {
  color: #3b5998 !important; }

.google-color {
  color: #dd4b39 !important; }
  .google-colora:hover, .google-colora:focus {
    color: #cc3623 !important; }

a.google-color:hover, a.google-color:focus {
  color: #cc3623 !important; }

.google-hover-color:hover, .google-hover-color:focus {
  color: #dd4b39 !important; }

.youtube-color {
  color: #bb0000 !important; }
  .youtube-colora:hover, .youtube-colora:focus {
    color: #a10000 !important; }

a.youtube-color:hover, a.youtube-color:focus {
  color: #a10000 !important; }

.youtube-hover-color:hover, .youtube-hover-color:focus {
  color: #bb0000 !important; }

.linkedin-color {
  color: #007bb6 !important; }
  .linkedin-colora:hover, .linkedin-colora:focus {
    color: #006a9d !important; }

a.linkedin-color:hover, a.linkedin-color:focus {
  color: #006a9d !important; }

.linkedin-hover-color:hover, .linkedin-hover-color:focus {
  color: #007bb6 !important; }

.instagram-color {
  color: #517fa4 !important; }
  .instagram-colora:hover, .instagram-colora:focus {
    color: #466d8d !important; }

a.instagram-color:hover, a.instagram-color:focus {
  color: #466d8d !important; }

.instagram-hover-color:hover, .instagram-hover-color:focus {
  color: #517fa4 !important; }

.pinterest-color {
  color: #cb2027 !important; }
  .pinterest-colora:hover, .pinterest-colora:focus {
    color: #af1c22 !important; }

a.pinterest-color:hover, a.pinterest-color:focus {
  color: #af1c22 !important; }

.pinterest-hover-color:hover, .pinterest-hover-color:focus {
  color: #cb2027 !important; }

.link-color {
  color: #031d56 !important; }
  .link-colora:hover, .link-colora:focus {
    color: #03194a !important; }

a.link-color:hover, a.link-color:focus {
  color: #03194a !important; }

.link-hover-color:hover, .link-hover-color:focus {
  color: #031d56 !important; }

/* ==========================================================================
	2.  BACKGROUND COLORS
	========================================================================== */
.primary-bg {
  background-color: #031d56 !important; }

.secondary-bg {
  background-color: #00adff !important; }

.success-bg {
  background-color: #3adb76 !important; }

.warning-bg {
  background-color: #ffae00 !important; }

.alert-bg {
  background-color: #ff0f0f !important; }

.info-bg {
  background-color: #00adff !important; }

.white-bg {
  background-color: #fff !important; }

.black-bg {
  background-color: #000 !important; }

.gray-bg {
  background-color: #767676 !important; }

.light-gray-bg {
  background-color: #e6e6e6 !important; }

.medium-gray-bg {
  background-color: #cacaca !important; }

.dark-gray-bg {
  background-color: #8a8a8a !important; }

.twitter-bg {
  background-color: #00aced !important; }

.facebook-bg {
  background-color: #3b5998 !important; }

.google-bg {
  background-color: #dd4b39 !important; }

.youtube-bg {
  background-color: #bb0000 !important; }

.linkedin-bg {
  background-color: #007bb6 !important; }

.instagram-bg {
  background-color: #517fa4 !important; }

.pinterest-bg {
  background-color: #cb2027 !important; }

/* ==========================================================================
	3.  MISC
	========================================================================== */
.instagram-bg,
.instagram-bg-hover:hover,
.instagram-bg-hover:focus {
  background: radial-gradient(circle at 33% 100%, #FED373 0%, #F15245 40%, #D92E7F 60%, #9B36B7 75%, #515ECF) !important; }
  @media screen and (min-width: 64em) {
    .instagram-bg,
    .instagram-bg-hover:hover,
    .instagram-bg-hover:focus {
      background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%) !important; } }

/* ==========================================================================
#
# //////////////////////////// LAYOUT HELPERS ///////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. HTML Fade
		2. Floats and Clearfix
		3. Visibility
		4. Display Class Helpers
		5. Spacers, Margins, and Padding
		6. Text Columns
		7. Foundation Grid Helpers
		8. Media

-------------------------------------------*/
/* ==========================================================================
	1.  HTML FADE
	========================================================================== */
html.js .body-content-wrapper {
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in-out; }
  html.js .body-content-wrapper.initialized {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in-out; }

::selection {
  background: #031d56;
  color: #fff; }

/* ==========================================================================
	1.  FLOATS & CLEARFIX
	========================================================================== */
.float-left, .left {
  float: left !important; }

.float-right, .right {
  float: right !important; }

.float-center, .center {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

/* ==========================================================================
	3.  VISIBILITY
	========================================================================== */
.hide, .hidden, .display-none {
  display: none !important; }

.invisible {
  visibility: hidden; }

/* ==========================================================================
	4.  DISPLAY CLASS HELPERS
	========================================================================== */
.display-inline-block, .inline-block {
  display: inline-block; }

.display-block, .block {
  display: block; }

.display-inline, .inline {
  display: inline; }

.overflow-hidden {
  overflow: hidden; }

.position-relative, .relative {
  position: relative; }

/* ==========================================================================
	5.  SPACERS | MARGINS | 
	========================================================================== */
/* -----------------------------------------------------------------------------

		Add padding or margins to elements. Can use media queries in class names (but no -only classes like medium-only).
			
		SPACINGTYPE-DIRECTIONCLASS-SPACINGSIZE
		BREAKPOINTCLASS-SPACINGTYPE-DIRECTIONCLASS-SPACINGSIZE

		BREAKPOINTCLASS: 	medium, large (to add xlarge, you must change array in $breakpoint-classes in foundatin/util/_breakpoint.scss)
		SPACINGTYPE: 			padding, margin
		DIRECTIONCLASS:		(blank), top, bottom, left, right, h, v
		SPACINGSIZE: 			none, tiny, small, medium, large, xlarge
		
		Example:
			padding-left-medium: 			adds padding-left:2.5rem;
			large-padding-left-medium	adds padding-left:2.5rem; on large screens and above only

		Special shorthand:
			margin-small: 					adds margin:1.25rem; to ALL sides
			margin-h-small:				adds margin:1.25rem; to TOP and BOTTOM
			margin-v-small: 				adds margin:1.25rem; to LEFT and RIGHT
	------------------------------------------------------------------------------ */
.padding-none {
  padding: 0 !important; }

.padding-tiny {
  padding: 0.5rem !important; }

.padding-small {
  padding: 1.25rem !important; }

.padding-medium {
  padding: 2.5rem !important; }

.padding-large {
  padding: 3.75rem !important; }

.padding-xlarge {
  padding: 5rem !important; }

.padding-top-none {
  padding-top: 0 !important; }

.padding-top-tiny {
  padding-top: 0.5rem !important; }

.padding-top-small {
  padding-top: 1.25rem !important; }

.padding-top-medium {
  padding-top: 2.5rem !important; }

.padding-top-large {
  padding-top: 3.75rem !important; }

.padding-top-xlarge {
  padding-top: 5rem !important; }

.padding-bottom-none {
  padding-bottom: 0 !important; }

.padding-bottom-tiny {
  padding-bottom: 0.5rem !important; }

.padding-bottom-small {
  padding-bottom: 1.25rem !important; }

.padding-bottom-medium {
  padding-bottom: 2.5rem !important; }

.padding-bottom-large {
  padding-bottom: 3.75rem !important; }

.padding-bottom-xlarge {
  padding-bottom: 5rem !important; }

.padding-left-none {
  padding-left: 0 !important; }

.padding-left-tiny {
  padding-left: 0.5rem !important; }

.padding-left-small {
  padding-left: 1.25rem !important; }

.padding-left-medium {
  padding-left: 2.5rem !important; }

.padding-left-large {
  padding-left: 3.75rem !important; }

.padding-left-xlarge {
  padding-left: 5rem !important; }

.padding-right-none {
  padding-right: 0 !important; }

.padding-right-tiny {
  padding-right: 0.5rem !important; }

.padding-right-small {
  padding-right: 1.25rem !important; }

.padding-right-medium {
  padding-right: 2.5rem !important; }

.padding-right-large {
  padding-right: 3.75rem !important; }

.padding-right-xlarge {
  padding-right: 5rem !important; }

.padding-h-none {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.padding-h-tiny {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.padding-h-small {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important; }

.padding-h-medium {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.padding-h-large {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important; }

.padding-h-xlarge {
  padding-left: 5rem !important;
  padding-right: 5rem !important; }

.padding-v-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.padding-v-tiny {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.padding-v-small {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }

.padding-v-medium {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.padding-v-large {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important; }

.padding-v-xlarge {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

@media screen and (min-width: 40em) {
  .medium-padding-none {
    padding: 0 !important; }
  .medium-padding-tiny {
    padding: 0.5rem !important; }
  .medium-padding-small {
    padding: 1.25rem !important; }
  .medium-padding-medium {
    padding: 2.5rem !important; }
  .medium-padding-large {
    padding: 3.75rem !important; }
  .medium-padding-xlarge {
    padding: 5rem !important; }
  .medium-padding-top-none {
    padding-top: 0 !important; }
  .medium-padding-top-tiny {
    padding-top: 0.5rem !important; }
  .medium-padding-top-small {
    padding-top: 1.25rem !important; }
  .medium-padding-top-medium {
    padding-top: 2.5rem !important; }
  .medium-padding-top-large {
    padding-top: 3.75rem !important; }
  .medium-padding-top-xlarge {
    padding-top: 5rem !important; }
  .medium-padding-bottom-none {
    padding-bottom: 0 !important; }
  .medium-padding-bottom-tiny {
    padding-bottom: 0.5rem !important; }
  .medium-padding-bottom-small {
    padding-bottom: 1.25rem !important; }
  .medium-padding-bottom-medium {
    padding-bottom: 2.5rem !important; }
  .medium-padding-bottom-large {
    padding-bottom: 3.75rem !important; }
  .medium-padding-bottom-xlarge {
    padding-bottom: 5rem !important; }
  .medium-padding-left-none {
    padding-left: 0 !important; }
  .medium-padding-left-tiny {
    padding-left: 0.5rem !important; }
  .medium-padding-left-small {
    padding-left: 1.25rem !important; }
  .medium-padding-left-medium {
    padding-left: 2.5rem !important; }
  .medium-padding-left-large {
    padding-left: 3.75rem !important; }
  .medium-padding-left-xlarge {
    padding-left: 5rem !important; }
  .medium-padding-right-none {
    padding-right: 0 !important; }
  .medium-padding-right-tiny {
    padding-right: 0.5rem !important; }
  .medium-padding-right-small {
    padding-right: 1.25rem !important; }
  .medium-padding-right-medium {
    padding-right: 2.5rem !important; }
  .medium-padding-right-large {
    padding-right: 3.75rem !important; }
  .medium-padding-right-xlarge {
    padding-right: 5rem !important; }
  .medium-padding-h-none {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .medium-padding-h-tiny {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .medium-padding-h-small {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .medium-padding-h-medium {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .medium-padding-h-large {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important; }
  .medium-padding-h-xlarge {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .medium-padding-v-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .medium-padding-v-tiny {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .medium-padding-v-small {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .medium-padding-v-medium {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .medium-padding-v-large {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .medium-padding-v-xlarge {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; } }

@media screen and (min-width: 64em) {
  .large-padding-none {
    padding: 0 !important; }
  .large-padding-tiny {
    padding: 0.5rem !important; }
  .large-padding-small {
    padding: 1.25rem !important; }
  .large-padding-medium {
    padding: 2.5rem !important; }
  .large-padding-large {
    padding: 3.75rem !important; }
  .large-padding-xlarge {
    padding: 5rem !important; }
  .large-padding-top-none {
    padding-top: 0 !important; }
  .large-padding-top-tiny {
    padding-top: 0.5rem !important; }
  .large-padding-top-small {
    padding-top: 1.25rem !important; }
  .large-padding-top-medium {
    padding-top: 2.5rem !important; }
  .large-padding-top-large {
    padding-top: 3.75rem !important; }
  .large-padding-top-xlarge {
    padding-top: 5rem !important; }
  .large-padding-bottom-none {
    padding-bottom: 0 !important; }
  .large-padding-bottom-tiny {
    padding-bottom: 0.5rem !important; }
  .large-padding-bottom-small {
    padding-bottom: 1.25rem !important; }
  .large-padding-bottom-medium {
    padding-bottom: 2.5rem !important; }
  .large-padding-bottom-large {
    padding-bottom: 3.75rem !important; }
  .large-padding-bottom-xlarge {
    padding-bottom: 5rem !important; }
  .large-padding-left-none {
    padding-left: 0 !important; }
  .large-padding-left-tiny {
    padding-left: 0.5rem !important; }
  .large-padding-left-small {
    padding-left: 1.25rem !important; }
  .large-padding-left-medium {
    padding-left: 2.5rem !important; }
  .large-padding-left-large {
    padding-left: 3.75rem !important; }
  .large-padding-left-xlarge {
    padding-left: 5rem !important; }
  .large-padding-right-none {
    padding-right: 0 !important; }
  .large-padding-right-tiny {
    padding-right: 0.5rem !important; }
  .large-padding-right-small {
    padding-right: 1.25rem !important; }
  .large-padding-right-medium {
    padding-right: 2.5rem !important; }
  .large-padding-right-large {
    padding-right: 3.75rem !important; }
  .large-padding-right-xlarge {
    padding-right: 5rem !important; }
  .large-padding-h-none {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .large-padding-h-tiny {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important; }
  .large-padding-h-small {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important; }
  .large-padding-h-medium {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important; }
  .large-padding-h-large {
    padding-left: 3.75rem !important;
    padding-right: 3.75rem !important; }
  .large-padding-h-xlarge {
    padding-left: 5rem !important;
    padding-right: 5rem !important; }
  .large-padding-v-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .large-padding-v-tiny {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .large-padding-v-small {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; }
  .large-padding-v-medium {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .large-padding-v-large {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important; }
  .large-padding-v-xlarge {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; } }

.margin-none {
  margin: 0 !important; }

.margin-tiny {
  margin: 0.5rem !important; }

.margin-small {
  margin: 1.25rem !important; }

.margin-medium {
  margin: 2.5rem !important; }

.margin-large {
  margin: 3.75rem !important; }

.margin-xlarge {
  margin: 5rem !important; }

.margin-top-none {
  margin-top: 0 !important; }

.margin-top-tiny {
  margin-top: 0.5rem !important; }

.margin-top-small {
  margin-top: 1.25rem !important; }

.margin-top-medium {
  margin-top: 2.5rem !important; }

.margin-top-large {
  margin-top: 3.75rem !important; }

.margin-top-xlarge {
  margin-top: 5rem !important; }

.margin-bottom-none {
  margin-bottom: 0 !important; }

.margin-bottom-tiny {
  margin-bottom: 0.5rem !important; }

.margin-bottom-small {
  margin-bottom: 1.25rem !important; }

.margin-bottom-medium {
  margin-bottom: 2.5rem !important; }

.margin-bottom-large {
  margin-bottom: 3.75rem !important; }

.margin-bottom-xlarge {
  margin-bottom: 5rem !important; }

.margin-left-none {
  margin-left: 0 !important; }

.margin-left-tiny {
  margin-left: 0.5rem !important; }

.margin-left-small {
  margin-left: 1.25rem !important; }

.margin-left-medium {
  margin-left: 2.5rem !important; }

.margin-left-large {
  margin-left: 3.75rem !important; }

.margin-left-xlarge {
  margin-left: 5rem !important; }

.margin-right-none {
  margin-right: 0 !important; }

.margin-right-tiny {
  margin-right: 0.5rem !important; }

.margin-right-small {
  margin-right: 1.25rem !important; }

.margin-right-medium {
  margin-right: 2.5rem !important; }

.margin-right-large {
  margin-right: 3.75rem !important; }

.margin-right-xlarge {
  margin-right: 5rem !important; }

.margin-h-none {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.margin-h-tiny {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important; }

.margin-h-small {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important; }

.margin-h-medium {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.margin-h-large {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important; }

.margin-h-xlarge {
  margin-left: 5rem !important;
  margin-right: 5rem !important; }

.margin-v-none, .spacer-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.margin-v-tiny, .spacer-tiny {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.margin-v-small, .spacer-small {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }

.margin-v-medium, .spacer-medium {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.margin-v-large, .spacer-large {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important; }

.margin-v-xlarge, .spacer-xlarge {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

@media screen and (min-width: 40em) {
  .medium-margin-none {
    margin: 0 !important; }
  .medium-margin-tiny {
    margin: 0.5rem !important; }
  .medium-margin-small {
    margin: 1.25rem !important; }
  .medium-margin-medium {
    margin: 2.5rem !important; }
  .medium-margin-large {
    margin: 3.75rem !important; }
  .medium-margin-xlarge {
    margin: 5rem !important; }
  .medium-margin-top-none {
    margin-top: 0 !important; }
  .medium-margin-top-tiny {
    margin-top: 0.5rem !important; }
  .medium-margin-top-small {
    margin-top: 1.25rem !important; }
  .medium-margin-top-medium {
    margin-top: 2.5rem !important; }
  .medium-margin-top-large {
    margin-top: 3.75rem !important; }
  .medium-margin-top-xlarge {
    margin-top: 5rem !important; }
  .medium-margin-bottom-none {
    margin-bottom: 0 !important; }
  .medium-margin-bottom-tiny {
    margin-bottom: 0.5rem !important; }
  .medium-margin-bottom-small {
    margin-bottom: 1.25rem !important; }
  .medium-margin-bottom-medium {
    margin-bottom: 2.5rem !important; }
  .medium-margin-bottom-large {
    margin-bottom: 3.75rem !important; }
  .medium-margin-bottom-xlarge {
    margin-bottom: 5rem !important; }
  .medium-margin-left-none {
    margin-left: 0 !important; }
  .medium-margin-left-tiny {
    margin-left: 0.5rem !important; }
  .medium-margin-left-small {
    margin-left: 1.25rem !important; }
  .medium-margin-left-medium {
    margin-left: 2.5rem !important; }
  .medium-margin-left-large {
    margin-left: 3.75rem !important; }
  .medium-margin-left-xlarge {
    margin-left: 5rem !important; }
  .medium-margin-right-none {
    margin-right: 0 !important; }
  .medium-margin-right-tiny {
    margin-right: 0.5rem !important; }
  .medium-margin-right-small {
    margin-right: 1.25rem !important; }
  .medium-margin-right-medium {
    margin-right: 2.5rem !important; }
  .medium-margin-right-large {
    margin-right: 3.75rem !important; }
  .medium-margin-right-xlarge {
    margin-right: 5rem !important; }
  .medium-margin-h-none {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .medium-margin-h-tiny {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .medium-margin-h-small {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .medium-margin-h-medium {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .medium-margin-h-large {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important; }
  .medium-margin-h-xlarge {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .medium-margin-v-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .medium-margin-v-tiny {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .medium-margin-v-small {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .medium-margin-v-medium {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .medium-margin-v-large {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .medium-margin-v-xlarge {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; } }

@media screen and (min-width: 64em) {
  .large-margin-none {
    margin: 0 !important; }
  .large-margin-tiny {
    margin: 0.5rem !important; }
  .large-margin-small {
    margin: 1.25rem !important; }
  .large-margin-medium {
    margin: 2.5rem !important; }
  .large-margin-large {
    margin: 3.75rem !important; }
  .large-margin-xlarge {
    margin: 5rem !important; }
  .large-margin-top-none {
    margin-top: 0 !important; }
  .large-margin-top-tiny {
    margin-top: 0.5rem !important; }
  .large-margin-top-small {
    margin-top: 1.25rem !important; }
  .large-margin-top-medium {
    margin-top: 2.5rem !important; }
  .large-margin-top-large {
    margin-top: 3.75rem !important; }
  .large-margin-top-xlarge {
    margin-top: 5rem !important; }
  .large-margin-bottom-none {
    margin-bottom: 0 !important; }
  .large-margin-bottom-tiny {
    margin-bottom: 0.5rem !important; }
  .large-margin-bottom-small {
    margin-bottom: 1.25rem !important; }
  .large-margin-bottom-medium {
    margin-bottom: 2.5rem !important; }
  .large-margin-bottom-large {
    margin-bottom: 3.75rem !important; }
  .large-margin-bottom-xlarge {
    margin-bottom: 5rem !important; }
  .large-margin-left-none {
    margin-left: 0 !important; }
  .large-margin-left-tiny {
    margin-left: 0.5rem !important; }
  .large-margin-left-small {
    margin-left: 1.25rem !important; }
  .large-margin-left-medium {
    margin-left: 2.5rem !important; }
  .large-margin-left-large {
    margin-left: 3.75rem !important; }
  .large-margin-left-xlarge {
    margin-left: 5rem !important; }
  .large-margin-right-none {
    margin-right: 0 !important; }
  .large-margin-right-tiny {
    margin-right: 0.5rem !important; }
  .large-margin-right-small {
    margin-right: 1.25rem !important; }
  .large-margin-right-medium {
    margin-right: 2.5rem !important; }
  .large-margin-right-large {
    margin-right: 3.75rem !important; }
  .large-margin-right-xlarge {
    margin-right: 5rem !important; }
  .large-margin-h-none {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .large-margin-h-tiny {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important; }
  .large-margin-h-small {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important; }
  .large-margin-h-medium {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important; }
  .large-margin-h-large {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important; }
  .large-margin-h-xlarge {
    margin-left: 5rem !important;
    margin-right: 5rem !important; }
  .large-margin-v-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .large-margin-v-tiny {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .large-margin-v-small {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important; }
  .large-margin-v-medium {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .large-margin-v-large {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important; }
  .large-margin-v-xlarge {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; } }

.spacer-none {
  display: block;
  width: 100%; }
  .spacer-none:after {
    content: "";
    display: table;
    clear: both; }

.spacer-tiny {
  display: block;
  width: 100%; }
  .spacer-tiny:after {
    content: "";
    display: table;
    clear: both; }

.spacer-small {
  display: block;
  width: 100%; }
  .spacer-small:after {
    content: "";
    display: table;
    clear: both; }

.spacer-medium {
  display: block;
  width: 100%; }
  .spacer-medium:after {
    content: "";
    display: table;
    clear: both; }

.spacer-large {
  display: block;
  width: 100%; }
  .spacer-large:after {
    content: "";
    display: table;
    clear: both; }

.spacer-xlarge {
  display: block;
  width: 100%; }
  .spacer-xlarge:after {
    content: "";
    display: table;
    clear: both; }

.no-margin, .margin-collapse {
  margin: 0 !important; }

.no-padding, .padding-collapse {
  padding: 0 !important; }

/* ==========================================================================
	7.  TEXT COLUMNS
	========================================================================== */
/* -----------------------------------------------------------------------------

		Examples
			.text-columns-2				creates 2 text columns on all breakpoints
			.medium-text-columns-2		creates 2 text columns only on medium and up breakpoints

	------------------------------------------------------------------------------- */
.text-column-1, .text-columns-1 {
  column-count: 1;
  column-gap: 1rem; }

.text-column-2, .text-columns-2 {
  column-count: 2;
  column-gap: 1rem; }

.text-column-3, .text-columns-3 {
  column-count: 3;
  column-gap: 1rem; }

.text-column-4, .text-columns-4 {
  column-count: 4;
  column-gap: 1rem; }

@media screen and (min-width: 40em) {
  .medium-text-column-1, .medium-text-columns-1 {
    column-count: 1;
    column-gap: 1rem; }
  .medium-text-column-2, .medium-text-columns-2 {
    column-count: 2;
    column-gap: 1rem; }
  .medium-text-column-3, .medium-text-columns-3 {
    column-count: 3;
    column-gap: 1rem; }
  .medium-text-column-4, .medium-text-columns-4 {
    column-count: 4;
    column-gap: 1rem; } }

@media screen and (min-width: 64em) {
  .large-text-column-1, .large-text-columns-1 {
    column-count: 1;
    column-gap: 1rem; }
  .large-text-column-2, .large-text-columns-2 {
    column-count: 2;
    column-gap: 1rem; }
  .large-text-column-3, .large-text-columns-3 {
    column-count: 3;
    column-gap: 1rem; }
  .large-text-column-4, .large-text-columns-4 {
    column-count: 4;
    column-gap: 1rem; } }

/* ==========================================================================
	8.  HIDING BRs
	========================================================================== */
/* -----------------------------------------------------------------------------

		Hide/show line breaks in text using media queries. Useful for control over wrapping titles.
		To use, add classes to parent container.
		
		Example: single line header on small and medium screens, add breakpoint on large and above screens

			<div class="small-br-hide large-br-show">
				<h1>This is a headline <br>with a line break</h1>
			</div>

	------------------------------------------------------------------------------- */
.small-br-hide br {
  display: none; }

.small-br-show br {
  display: block; }

@media screen and (min-width: 40em) {
  .medium-br-hide br {
    display: none; }
  .medium-br-show br {
    display: block; } }

@media screen and (min-width: 64em) {
  .large-br-hide br {
    display: none; }
  .large-br-show br {
    display: block; } }

/* ==========================================================================
	9.  FOUNDATION GRID HELPERS
	========================================================================== */
.row.width110 {
  max-width: 82.5rem; }

.row.full {
  max-width: 100%; }

.no-collapse-columns .column, .no-collapse-columns .columns, .no-collapse-columns .columns {
  min-height: 1px; }

/* ==========================================================================
	10.  MEDIA
	========================================================================== */
.circle {
  border-radius: 100% !important; }
  .circle img {
    border-radius: 100% !important; }

.rounded {
  border-radius: 3px; }
  .rounded img {
    border-radius: 3px; }

.bordered {
  border: 1px solid #EFEFEF; }

/* ==========================================================================
	11.  Panels
	========================================================================== */
.panel {
  padding: 1rem 2rem;
  background: #fff;
  border: solid 1px #EFEFEF;
  margin-bottom: 2rem;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 3px; }

/* ==========================================================================
#
# ////////////////////////// TYPOGRAPHY HELPERS /////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. Foundation Typography Classes
		2. Foundation Text Alignment
		3. Font Style Helpers
		4. Link Style Helpers

-------------------------------------------*/
/* ==========================================================================
	1.  FOUNDATION TYPOGRAPHY CLASSES
	========================================================================== */
.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #454142; }

.lead {
  font-size: 20px;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

/* ==========================================================================
	2.  FOUNDATION TEXT ALIGNMENT
	========================================================================== */
.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

/* ==========================================================================
	3.  FONT STYLE HELPERS
	========================================================================== */
.bold {
  font-weight: bold; }

.italic {
  font-style: italic !important; }

.no-italic {
  font-style: normal !important; }

.underline {
  text-decoration: underline !important; }

.text-xxsmall {
  font-size: 0.8em !important; }

.text-xsmall {
  font-size: 0.8em !important; }

.text-small {
  font-size: 0.9em !important; }

.text-regular {
  font-size: 1em !important; }

.text-large {
  font-size: 1.15em !important; }

.text-xlarge {
  font-size: 1.25em !important; }

.text-xxlarge {
  font-size: 2em !important; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-300 {
  font-weight: 300; }

.font-400 {
  font-weight: 400; }

.font-500 {
  font-weight: 500; }

.font-600 {
  font-weight: 600; }

.font-700 {
  font-weight: 700; }

.font-800 {
  font-weight: 800; }

.font-900 {
  font-weight: 900; }

.font-sans-serif {
  font-family: "Roboto", Arial, sans-serif; }

.font-sans-serif-condensed {
  font-family: "Roboto Condensed", Helvetica, Arial, sans-serif; }

.font-serif {
  font-family: Georgia, serif; }

.font-uppercase,
.uppercase {
  text-transform: uppercase; }

.font-standard,
.sentence-case {
  text-transform: normal; }

.line-height-xsm, .line-height-xsmall {
  line-height: 1; }

.line-height-sm, .line-height-small {
  line-height: 1.2; }

.line-height-med, .line-height-medium {
  line-height: 1.4; }

.line-height-lrg, .line-height-large {
  line-height: 1.6; }

.icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1; }

/* ==========================================================================
	4.  LINK STYLE HELPERS
	========================================================================== */
.underline {
  text-decoration: underline; }

.underline-hover:hover, .underline-hover:focus {
  text-decoration: underline; }

/* ==========================================================================
#
# /////////////////////////////// HEADER ///////////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. 

-------------------------------------------*/
/* ==========================================================================
	1.  Header Main
	========================================================================== */
.header-main {
  background: #031d56;
  height: 100px;
  padding: 0.5rem 1rem; }
  .header-main img {
    width: 150px;
    margin: 0 auto;
    display: table; }

/* ==========================================================================
#
# /////////////////////////////// FOOTER ///////////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. 

-------------------------------------------*/
/* ==========================================================================
!  Print
	Basic print styles fix page break issues, convert text and background colors to print-friendly

	Hint: to force printers to show background color and use the exact text color, use this CSS property:
	
		-webkit-print-color-adjust: exact; 
		color-adjust: exact; 

	(Note: doesn't work in Firefox if option to "Print Backgrounds" isn't checked in print dialog )
========================================================================== */
.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #DDDDDD;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/* !  Pages — Page-, template-, or group-specific styles. Create new partials for ongoing site updates & comment with JIRA #
	 ------------------------------------------------------------------------------------------------------*/
/* ==========================================================================
#
# /////////////////////////////// HOME ///////////////////////////////////
#
========================================================================== */
/*-------------------------------------------
		Table of Contents:

		1. 

-------------------------------------------*/
body {
  background: linear-gradient(to top, #fff 90%, #031d56 90%, #031d56 100%); }

.main-section {
  background: linear-gradient(to top, #031d56 0%, #021847 30%, #fff 30%, #fff 90%, #02153d 90%, #031d56 100%); }
  @media screen and (max-width: 39.9375em) {
    .main-section .panel {
      padding: 0.5rem 1rem; } }
  .main-section .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F5F5F5; }
  .main-section .content {
    position: relative;
    z-index: 10;
    padding: 1rem 0.5rem; }
  .main-section .disclaimer small {
    color: #EFEFEF !important; }
  .main-section .disclaimer * {
    color: #c1d4fd;
    font-size: 0.8rem; }
