/* ==========================================================================
#
# /////////////////////////////// HOME ///////////////////////////////////
#
========================================================================== */

/*-------------------------------------------
		Table of Contents:

		1. 

-------------------------------------------*/

body{background: linear-gradient(to top,  $white 90%, $primary-color 90%, $primary-color 100%);}


.main-section{background: linear-gradient(to top, $primary-color 0%, darken($primary-color, 3%) 30%, $white 30%, $white 90%, darken($primary-color, 5%) 90%, $primary-color 100%);

	// Media Queries
	@media #{$small-only} {
		.panel{padding:0.5rem 1rem;}
	}

	.background{position:absolute;top:0;left:0;width:100%;height:100%;background:$whitesmoke;}
	.content{position:relative;z-index:10;padding:1rem 0.5rem;}

	.disclaimer {
		small{color:$silver !important;}
		*{color:lighten($primary-color, 70%);font-size:0.8rem;}

	}
}