// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group accordion
////

$accordion-background: $white !default;
$accordion-plusminus: true !default;
$accordion-item-color: foreground($accordion-background, $primary-color) !default;
$accordion-item-background-hover: $light-gray !default;
$accordion-item-padding: 1.25rem 1rem !default;
$accordion-content-background: $white !default;
$accordion-content-border: 1px solid $global-border-color !default;
$accordion-content-color: foreground($accordion-content-background, $body-font-color) !default;
$accordion-content-padding: 1rem !default;

/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container {
	list-style-type: none;
	background: $accordion-background;
	margin-left: 0;
}

/// Adds styles for the accordion item. Apply this to the list item within an accordion ul.
@mixin accordion-item {
	&:first-child > :first-child {
		border-radius: $global-radius $global-radius 0 0;
	}
	&:last-child > :last-child {
		border-radius: 0 0 $global-radius $global-radius;
	}
}

/// Adds styles for the title of an accordion item. Apply this to the link within an accordion item.
@mixin accordion-title {
	display: block;
	padding: $accordion-item-padding;
	line-height: 1;
	font-size: rem-calc(12);
	color: $accordion-item-color;
	position: relative;
	border: $accordion-content-border;
	border-bottom: 0;
	:last-child:not(.is-active) > & {
		border-radius: 0 0 $global-radius $global-radius;
		border-bottom: $accordion-content-border;
	}

	&:hover,
	&:focus {
		background-color: $accordion-item-background-hover;
	}

	@if $accordion-plusminus {
		&::before {
			content: '+';
			position: absolute;
			right: 1rem;
			top: 50%;
			margin-top: -0.5rem;
		}

		.is-active > &::before {
			content: '–';
		}
	}
}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content {
	padding: $accordion-content-padding;
	display: none;
	border: $accordion-content-border;
	border-bottom: 0;
	background-color: $accordion-content-background;
	color: $accordion-content-color;
	:last-child > &:last-child {
		border-bottom: $accordion-content-border;
	}
}

.accordion {
	@include accordion-container;
}

.accordion-item {
	@include accordion-item;
}

.accordion-title {
	@include accordion-title;
}

.accordion-content {
	@include accordion-content;
}

