// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group button
////


$button-padding: 0.5em 2em !default;
$button-margin: 0 0 $global-margin 0 !default;
$button-fill: solid !default;
$button-background: $primary-color !default;
$button-background-hover: scale-color($button-background, $lightness: -15%) !default;
$button-color: $white !default;
$button-color-alt: $black !default;
$button-radius: $global-radius !default;

$button-sizes: (
	tiny: 0.6rem,
	small: 0.75rem,
	default: 0.9rem,
	large: 1.25rem,
) !default;


$button-opacity-disabled: 0.25 !default;


// TODO: Document button-base() mixin
@mixin button-base {
	@include disable-mouse-outline;
	display: inline-block;
	text-align: center;
	line-height: 1;
	cursor: pointer;
	-webkit-appearance: none;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	vertical-align: middle;
	border: 1px solid transparent;
	border-radius: $button-radius;
	padding: $button-padding;
	margin: $button-margin; 
	font-size: map-get($button-sizes, default);
}

/// Expands a button to make it full-width.
/// @param {Boolean} $expand [true] - Set to `true` to enable the expand behavior. Set to `false` to reverse this behavior.
@mixin button-expand($expand: true) {
	@if $expand {
		display: block;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	@else {
		display: inline-block;
		width: auto;
		margin: $button-margin;
	}
}

/// Sets the visual style of a button.
/// @param {Color} $background [$button-background] - Background color of the button.
/// @param {Color} $background-hover [$button-background-hover] - Background color of the button on hover. Set to `auto` to have the mixin automatically generate a hover color.
/// @param {Color} $color [$button-color] - Text color of the button. Set to `auto` to automatically generate a color based on the background color.
@mixin button-style(
	$background: $button-background,
	$background-hover: $button-background-hover,
	$color: $button-color
) {
	@if $color == auto {
		$color: foreground($background, $button-color-alt, $button-color);
	}

	@if $background-hover == auto {
		$background-hover: scale-color($background, $lightness: -20%);
	}

	background-color: $background;
	color: $color;

	&:hover, &:focus {
		background-color: $background-hover;
		color: $color;
	}
}

/// Removes background fill on hover and focus for hollow buttons.
@mixin button-hollow {
	&,
	&:hover, &:focus {
		background-color: transparent;
	}
}

@mixin button-hollow-style($color: $primary-color) {
	$color-hover: scale-color($color, $lightness: -50%);

	border: 1px solid $color;
	color: $color;

	&:hover, &:focus {
		border-color: $color-hover;
		color: $color-hover;
	}
}

/// Adds disabled styles to a button by fading the element, reseting the cursor, and disabling pointer events.
@mixin button-disabled {
	opacity: $button-opacity-disabled;
	cursor: not-allowed;
	&:hover, &:focus {
		background-color: $button-background;
		color: $button-color;
	}
}

/// Adds a dropdown arrow to a button.
/// @param {Number} $size [0.4em] - Size of the arrow. We recommend using an `em` value so the triangle scales when used inside different sizes of buttons.
/// @param {Color} $color [white] - Color of the arrow.
/// @param {Number} $offset [$button-padding] - Distance between the arrow and the text of the button. Defaults to whatever the right padding of a button is.
@mixin button-dropdown(
	$size: 0.4em,
	$color: $white,
	$offset: get-side($button-padding, right)
) {
	&::after {
		@include css-triangle($size, $color, down);
		position: relative;
		top: 0.4em; // Aligns the arrow with the text of the button
		float: right;
		margin-left: get-side($button-padding, right);
		display: inline-block;
	}
}

/// Adds all styles for a button. For more granular control over styles, use the individual button mixins.
/// @param {Boolean} $expand [false] - Set to `true` to make the button full-width.
/// @param {Color} $background [$button-background] - Background color of the button.
/// @param {Color} $background-hover [$button-background-hover] - Background color of the button on hover. Set to `auto` to have the mixin automatically generate a hover color.
/// @param {Color} $color [$button-color] - Text color of the button. Set to `auto` to automatically generate a color based on the background color.
/// @param {Keyword} $style [solid] - Set to `hollow` to create a hollow button. The color defined in `$background` will be used as the primary color of the button.
@mixin button(
	$expand: false,
	$background: $button-background,
	$background-hover: $button-background-hover,
	$color: $button-color,
	$style: $button-fill
) {
	@include button-base;

	@if $style == solid {
		@include button-style($background, $background-hover, $color);
	}
	@else if $style == hollow {
		@include button-hollow;
		@include button-hollow-style($background);
	}

	@if $expand {
		@include button-expand;
	}
}


.button {
		@include button;

		// Sizes
		@each $size, $value in map-remove($button-sizes, default) {
			&.#{$size} {
				font-size: $value;
			}
		}

		&.expanded { @include button-expand; }

		// Colors
		@each $name, $color in $foundation-palette {
			@if $button-fill != hollow {
				&.#{$name} {
					@include button-style($color, auto, auto);
				}
			}
			@else {
				&.#{$name} {
					@include button-hollow-style($color);
				}

				&.#{$name}.dropdown::after {
					border-top-color: $color;
				}
			}
		}

		// Hollow style
		@if $button-fill != hollow {
			&.hollow {
				@include button-hollow;
				@include button-hollow-style;

				@each $name, $color in $foundation-palette {
					&.#{$name} {
						@include button-hollow-style($color);
					}
				}
			}
		}

		// Disabled style
		&.disabled,
		&[disabled] {
			@include button-disabled;
		}

		// Dropdown arrow
		&.dropdown {
			@include button-dropdown;

			@if $button-fill == hollow {
				&::after {
					border-top-color: $button-background;
				}
			}
		}

		// Button with dropdown arrow only
		&.arrow-only::after {
			margin-left: 0;
			float: none;
			top: -0.1em;
		}
}

