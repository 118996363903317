// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group breadcrumbs
////

$breadcrumbs-margin: 0 0 $global-margin 0 !default;
$breadcrumbs-item-font-size: rem-calc(11) !default;
$breadcrumbs-item-color: $primary-color !default;
$breadcrumbs-item-color-current: $black !default;
$breadcrumbs-item-color-disabled: $medium-gray !default;
$breadcrumbs-item-margin: 0.75rem !default;
$breadcrumbs-item-uppercase: true !default;
$breadcrumbs-item-slash: true !default;
$breadcrumbs-item-separator-item: '/' !default;

/// Adds styles for a breadcrumbs container, along with the styles for the `<li>` and `<a>` elements inside of it.
@mixin breadcrumbs-container {
	@include clearfix;
	list-style: none;
	margin: $breadcrumbs-margin;

	// Item wrapper
	li {
		float: left;
		color: $breadcrumbs-item-color-current;
		font-size: $breadcrumbs-item-font-size;
		cursor: default;

		@if $breadcrumbs-item-uppercase {
			text-transform: uppercase;
		}

		@if $breadcrumbs-item-slash {
			// Need to escape the backslash
			$slash: $breadcrumbs-item-separator-item;

			&:not(:last-child)::after {
				position: relative;
				margin: 0 $breadcrumbs-item-margin;
				opacity: 1;
				color: $medium-gray;
				content: $slash;
			}
		}
		@else {
			margin-right: $breadcrumbs-item-margin;
		}
	}

	// Page links
	a {
		color: $breadcrumbs-item-color;

		&:hover {
			text-decoration: underline;
		}
	}
}


.breadcrumbs {
	@include breadcrumbs-container;

	.disabled {
		color: $breadcrumbs-item-color-disabled;
		cursor: not-allowed;
	}
}

