// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group button-group
////


$buttongroup-margin: 1rem !default;
$buttongroup-spacing: 1px !default;
$buttongroup-child-selector: '.button' !default;
$buttongroup-expand-max: 6 !default;

/// Add styles for a button group container.
/// @param {String} $child-selector [$buttongroup-child-selector] - Selector for the buttons inside a button group.
@mixin button-group(
  $child-selector: $buttongroup-child-selector
) {
  @include clearfix;
  margin-bottom: $buttongroup-margin;
  font-size: 0;

  #{$child-selector} {
    margin: 0;
    margin-right: $buttongroup-spacing;
    margin-bottom: $buttongroup-spacing;
    font-size: map-get($button-sizes, default);

    &:last-child {
      margin-right: 0;
    }
  }
}

/// Creates a full-width button group, making each button equal width.
/// @param {String} $selector [$buttongroup-child-selector] - Selector for the buttons inside a button group.
@mixin button-group-expand(
  $selector: $buttongroup-child-selector,
  $count: null
) {
  
  margin-right: -$buttongroup-spacing;

  &::before,
  &::after {
    display: none;
  }

  // scss-lint:disable ZeroUnit
  #{$selector} {

    @for $i from 2 through $buttongroup-expand-max {
      &:first-child:nth-last-child(#{$i}) {
        &, &:first-child:nth-last-child(#{$i}) ~ #{$selector} {
          display: inline-block;
          width: calc(#{percentage(1 / $i)} - #{$buttongroup-spacing});
          margin-right: $buttongroup-spacing;
          &:last-child {
            margin-right: $buttongroup-spacing * -$buttongroup-expand-max;
          }
        }
      }
    }
    
  }
}

/// Stacks the buttons in a button group.
/// @param {String} $selector [$buttongroup-child-selector] - Selector for the buttons inside the button group.
@mixin button-group-stack(
  $selector: $buttongroup-child-selector
) {

  #{$selector} {
    width: 100%;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/// Un-stacks the buttons in a button group.
/// @param {String} $selector [$buttongroup-child-selector] - Selector for the buttons inside the button group.
@mixin button-group-unstack(
  $selector: $buttongroup-child-selector
) {
  // scss-lint:disable ZeroUnit
  #{$selector} {
    width: auto;
    margin-bottom: 0;
  }
}


.button-group {
  @include button-group;

  // Sizes
  @each $size, $value in map-remove($button-sizes, default) {
    &.#{$size} #{$buttongroup-child-selector} {
      font-size: $value;
    }
  }

  // Even-width Group
  &.expanded { @include button-group-expand; }

  // Colors
  @each $name, $color in $foundation-palette {
    @if $button-fill != hollow {
      &.#{$name} #{$buttongroup-child-selector} {
        @include button-style($color, auto, auto);
      }
    }
    @else {
      &.#{$name} #{$buttongroup-child-selector} {
        @include button-hollow;
        @include button-hollow-style($color);
      }
    }
  }

  &.stacked,
  &.stacked-for-small,
  &.stacked-for-medium {
    @include button-group-stack;
  }

  &.stacked-for-small {
    @include breakpoint(medium) {
      @include button-group-unstack;
    }
  }

  &.stacked-for-medium {
    @include breakpoint(large) {
      @include button-group-unstack;
    }
  }

  // scss-lint:disable MergeableSelector
  &.stacked-for-small.expanded {
    @include breakpoint(small only) {
      display: block;

      #{$buttongroup-child-selector} {
        display: block;
        margin-right: 0;
      }
    }
  }
}
