// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group drilldown
////

$drilldown-transition: transform 0.15s linear !default;
$drilldown-arrows: true !default;
$drilldown-arrow-color: $primary-color !default;
$drilldown-background: $white !default;


// Applied to the Menu container
.is-drilldown {
  position: relative;
  overflow: hidden;

  li {
    display: block !important;
  }
}

// Applied to nested <ul>s
.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: $drilldown-background;
  transition: $drilldown-transition;

  &.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%);
  }

  &.is-closing {
    transform: translateX(100%);
  }
}

@if $drilldown-arrows {
  .is-drilldown-submenu-parent > a {
    position: relative;

    &::after {
      @include css-triangle(6px, $drilldown-arrow-color, right);
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 1rem;
    }
  }

  .js-drilldown-back > a::before {
    @include css-triangle(6px, $drilldown-arrow-color, left);
    border-left-width: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem; // Creates space between the arrow and the text
  }
}

