// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group tooltip
////

$has-tip-font-weight: $global-weight-bold !default;
$has-tip-border-bottom: dotted 1px $iron !default;
$tooltip-background-color: $black !default;
$tooltip-color: $white !default;
$tooltip-padding: 0.75rem !default;
$tooltip-font-size: $small-font-size !default;
$tooltip-pip-width: 0.75rem !default;
$tooltip-pip-height: $tooltip-pip-width * 0.866 !default;

$tooltip-radius: $global-radius !default;

@mixin has-tip {
  border-bottom: $has-tip-border-bottom;
  font-weight: $has-tip-font-weight;
  position: relative;
  display: inline-block;
  cursor: help;
}

@mixin tooltip {
  background-color: $tooltip-background-color;
  color: $tooltip-color;
  font-size: $tooltip-font-size;
  padding: $tooltip-padding;
  position: absolute;
  z-index: 10;
  top: calc(100% + #{$tooltip-pip-height});
  max-width: 10rem !important;
  border-radius: $tooltip-radius;

  &::before {
    @include css-triangle($tooltip-pip-width, $tooltip-background-color, up);
    bottom: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.top::before {
    @include css-triangle($tooltip-pip-width, $tooltip-background-color, down);
    top: 100%;
    bottom: auto;
  }

  &.left::before {
    @include css-triangle($tooltip-pip-width, $tooltip-background-color, right);
    bottom: auto;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }

  &.right::before {
    @include css-triangle($tooltip-pip-width, $tooltip-background-color, left);
    bottom: auto;
    left: auto;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}




.has-tip {
  @include has-tip;
}

.tooltip {
  @include tooltip;
}

