/* ==========================================================================
#
# ///////////////////////////// TOGGLER ///////////////////////////////////
#
========================================================================== */

/* ==========================================================================
	!  BASE 
	========================================================================== */

.toggle_wrap {margin-bottom: 20px;
	ul {margin: 0;
		li {list-style: none;border-bottom: 1px solid $gainsboro;margin: 0;padding: 10px 0 10px 40px;@include clearfix;position: relative;}
	}
	.toggler {font-size: 1em;margin: 0;font-weight: normal;
		a {color: $tuatara;}
		&:before { font-family: $icon;content:"\f056";font-weight: normal;display: block;height: 22px;width: 22px;margin-right: 20px;margin-top: 0px;position: absolute;left: 0;color:$white;text-align: center;background:$primary-color;font-size: 0.7em;line-height: 25px;margin-left: 5px;cursor:pointer;}
		&.active {
			a {color: lighten($black, 5%);font-weight:bold;}
			&:before {content:"\f057";color:$white;background: $secondary-color;}
		}
	}
	.togglee {padding: 20px 10px 20px 0px;color:$tuatara;}
}